import React from 'react'
import { Tag } from 'antd';

import splash from '../../assets/images/splash.jpg'
import wabco from '../../assets/images/wabco.png'
const { REACT_APP_VERSION } = process.env;

const wabcoLogoHeight = 120

const homeStyle = {
  backgroundImage: `url(${splash})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const versionStyle = {
  position: 'absolute',
  bottom: '5px',
  right: '0px',
  cursor: 'default'
};

const Home = () => (
  <div style={homeStyle}>
    <div
      style={{
        textAlign: 'center',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.2)',
      }}
    >
      <img
        src={wabco}
        alt="wabco"
        style={{
          height: wabcoLogoHeight,
          position: 'relative',
          top: `calc(50% - ${wabcoLogoHeight}px)`,
        }}
      />
      <Tag style={versionStyle}>v{REACT_APP_VERSION}</Tag>
    </div>
  </div>
)

export default Home
