import React from 'react'
import { injectIntl, intlShape } from 'react-intl';
import { Row, Col } from 'antd'

import PieElement, {styles} from './PieElement';
import * as strings from '../../helpers/defaultStrings';

import './EbpmsTableSummary.css'

const EbpmsTableSummary = ({
  statusFilter, trailersCount, unknownCount, passCount, onSelect, failCount, intl,
}) => {
  const {formatMessage} = intl;

  return (
    <Row style={{ padding: 16 }}>
      <Col span={6} style={{ textAlign: 'center' }}>
        <p style={styles.totalLabel}>{formatMessage(strings.short.activeTrailers)}</p>
        <h2 style={styles.totalTrailerCount}>{trailersCount}</h2>
      </Col>
      <Col span={18}>
        <Row type="flex" justify="space-around">
          <div style={{cursor: 'pointer'}} onClick={() => onSelect('fail')}>
            <PieElement
              status="Fail"
              totalCount={trailersCount}
              count={failCount}
              className="failEbpms"
              selected={statusFilter.length === 0 || statusFilter.includes('fail')}
            />
          </div>
          <div style={{cursor: 'pointer'}} onClick={() => onSelect('pass')}>
            <PieElement
              status="Pass"
              totalCount={trailersCount}
              count={passCount}
              className="passEbpms"
              selected={statusFilter.length === 0 || statusFilter.includes('pass')}
            />
          </div>
          <div style={{cursor: 'pointer'}} onClick={() => onSelect('unknown')}>
            <PieElement
              status="Unknown"
              totalCount={trailersCount}
              count={unknownCount}
              className="unknownEbpms"
              selected={statusFilter.length === 0 || statusFilter.includes('unknown')}
            />
          </div>
        </Row>
      </Col>
    </Row>
  )
}

EbpmsTableSummary.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(EbpmsTableSummary)
