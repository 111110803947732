import React from 'react';
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl';
import { Spin, Row, Icon } from 'antd';
import numeral from 'numeral';

import * as strings from '../../helpers/defaultStrings';
import TrailerHistoryChart from '../charts/TrailerHistoryChart/TrailerHistoryChartComponent'
import actions from '../../actions'


class BrakeEventDetailsOverlay extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pos: null,
      resizeChart: null,
    }
    window.addEventListener('ebpmsLeftPanelResize', this.onLeftPanelResize)
  }

  componentWillUnmount() {
    window.removeEventListener('ebpmsLeftPanelResize', this.onLeftPanelResize)
  }

  onPosChange = (pos) => {
    const { onHistoryPositionChange } = this.props;
    this.setState({ pos });
    onHistoryPositionChange(pos);
  }

  onLeftPanelResize = () => {
    this.setState({ resizeChart: Math.random() })
  }

  render() {
    const {
      selectedEvent,
      telemetryItems,
      telemetryError,
      telemetryProcessing,
      onClose,
      intl: { formatMessage },
    } = this.props
    const {
      pos,
      resizeChart,
    } = this.state

    if (!selectedEvent) {
      return null
    }

    const heightDiff = telemetryItems && Array.isArray(telemetryItems) && telemetryItems.length > 1
      ? Math.round(telemetryItems[telemetryItems.length - 1].elevation - telemetryItems[0].elevation)
      : null;

    return (
      <div
        style={{
          fontSize: '12px',
          position: 'absolute',
          background: 'rgba(255, 255, 255, 0.8)',
          color: '#000',
          width: 'calc(100% - 20px)',
          zIndex: 9,
          //pointerEvents: 'none',
          borderRadius: '10px',
          bottom: 10,
          left: 10,
        }}
      >
        <div style={{ padding: 8 }}>
          <Row type="flex" justify="space-between">
            <Row type="flex">
              <h3>{selectedEvent.eventId}</h3>
              { telemetryProcessing && <Icon type="loading" style={{ marginLeft: 16 }}/> }
              { telemetryError && <div><Icon type="exclamation-circle" theme="twoTone" twoToneColor="tomato" style={{ marginLeft: 16 }}/> {formatMessage(strings.short.error)}</div> }
              { (!telemetryProcessing && (!telemetryItems || telemetryItems.length === 0)) && <div><Icon type="warning" theme="twoTone" twoToneColor="orange" style={{ marginLeft: 16 }}/> {formatMessage(strings.short.noData)}</div> }
            </Row>
            <Icon type="close" onClick={onClose} />
          </Row>
          {
            pos && (
              <Row type="flex">
                <div style={{ marginRight: 16 }}><strong>{formatMessage(strings.short.time)}</strong>: {pos.datetime ? pos.datetime.format('LTS') : formatMessage(strings.short.unknown)}</div>
                <div style={{ marginRight: 16 }}><strong>{formatMessage(strings.short.speed)}</strong>: {!isNaN(pos.wheel_speed) ? Math.round(pos.wheel_speed) + ' ' + formatMessage(strings.abbrev.kilometerPerHour) : formatMessage(strings.short.unknown)}</div>
                <div style={{ marginRight: 16 }}><strong>Pressure</strong>: {!isNaN(pos.pcan) ? numeral(pos.pcan).format('0,0.') + ' kPa' : formatMessage(strings.short.unknown)}</div>
                <div style={{ marginRight: 16 }}><strong>Height Δ</strong>: {numeral(heightDiff).format('0,0')+ ' m'}</div>
              </Row>
            )
          }
        </div>
        <Spin spinning={telemetryProcessing} style={{ height: 150 }}>
          {telemetryItems && (
            <TrailerHistoryChart
              height={150}
              data={telemetryItems}
              dataHash={selectedEvent.eventId}
              selectedTrailer={'?'}
              onPosChange={this.onPosChange}
              resizeChart={resizeChart}
              axleLoadLabel="Pressure"
            />
          )}
        </Spin>
      </div>
    )
  }
}

function mapStateToProps(store) {
  return {
    selectedEvent: store.brakingEvents.selected.event,
    telemetryItems: store.brakingEvents.selected.telemetry,
    telemetryError: store.brakingEvents.selected.error,
    telemetryProcessing: store.brakingEvents.selected.processing,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    onClose: () => dispatch(actions.brakingEvents.selectEvent(null)),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(BrakeEventDetailsOverlay))
