import { defineMessages } from 'react-intl';

// Any string that takes values as arguments (in curly brackets within the string)
// should have Val appended to the end of it's name and id
// For example: phrase.capacityVal
// The id should follow the pattern of {stringType}.{stringName}

export const pageTitle = defineMessages({
  healthPassport: {
    id: 'pageTitle.healthPassport',
    defaultMessage: 'Health passport - Fleet',
  },
  home: {
    id: 'pageTitle.home',
    defaultMessage: 'Home',
  },
  liveMap: {
    id: 'pageTitle.liveMap',
    defaultMessage: 'Trailers - Live map',
  },
  notifications: {
    id: 'pageTitle.notifications',
    defaultMessage: 'Notifications',
  },
  safety: {
    id: 'pageTitle.safety',
    defaultMessage: 'Safety - Fleet',
  },
  deviceIdCard: {
    id: 'pageTitle.deviceIdCard',
    defaultMessage: 'Trailer - ID card',
  }
});

export const alert = defineMessages({
  altitudeVal: {
    id: 'alert.altitudeVal',
    defaultMessage: 'Altitude: {value} m',
  },
  dateVal: {
    id: 'alert.dateVal',
    defaultMessage: 'Date: {value}',
  },
  ebsAmberTitle: {
    id: 'alert.ebsAmberTitle',
    defaultMessage: 'EBS amber light',
  },
  ebsAmberCardText: {
    id: 'alert.ebsAmberCardText',
    defaultMessage: 'Amber light on EBS',
  },
  ebsRedTitle: {
    id: 'alert.ebsRedTitle',
    defaultMessage: 'EBS red light',
  },
  ebsRedCardText: {
    id: 'alert.ebsRedCardText',
    defaultMessage: 'Red light on EBS',
  },
  gpsSatellitesVal: {
    id: 'alert.gpsSatellitesVal',
    defaultMessage: 'GPS satellites: {value}',
  },
  harshBrakeCardText: {
    id: 'alert.harshBrakeCardText',
    defaultMessage: 'Harsh braking detected',
  },
  harshBrakeTitle: {
    id: 'alert.harshBrakeTitle',
    defaultMessage: 'Harsh Braking',
  },
  noGeoLocationAvailable: {
    id: 'alert.noGeoLocationAvailable',
    defaultMessage: 'No geolocation available',
  },
  noPositionInfoVal: {
    id: 'alert.noPositionInfoVal',
    defaultMessage: '{value} has no position information',
  },
  notificationConfirmDelete: {
    id: 'alert.notificationConfirmDelete',
    defaultMessage: 'Are you sure you want to delete this notification?',
  },
  notificationDeleted: {
    id: 'alert.notificationDeleted',
    defaultMessage: 'Notification deleted',
  },
  notificationsDeleted: {
    id: 'alert.notificationsDeleted',
    defaultMessage: 'Notifications deleted',
  },
  notificationsPrefUpdateError: {
    id: 'alert.notificationsPrefUpdateError',
    defaultMessage: 'There was an error setting up your notification preferences. Please try again later.',
  },
  numNotificationsVal: {
    id: 'alert.numNotificationsVal',
    defaultMessage: 'You have {value} new notifications',
  },
  powerOnTitle: {
    id: 'alert.powerOnTitle',
    defaultMessage: 'Power on',
  },
  powerOnCardText: {
    id: 'alert.powerOnCardText',
    defaultMessage: 'The device has powered on',
  },
  sessionExpiringTitle: {
    id: 'alert.sessionExpiringTitle',
    defaultMessage: 'Logout notice',
  },
  sessionExpiringDescriptionVal: {
    id: 'alert.sessionExpiringDescriptionVal',
    defaultMessage: 'You will be logged out in {value} seconds.',
  },
  sessionExpiringActionDetails: {
    id: 'alert.sessionExpiringActionDetails',
    defaultMessage: 'If you would like to stay logged in, please renew your session by clicking the button below',
  },
  sessionExpiringLoggingOut: {
    id: 'alert.sessionExpiringLoggingOut',
    defaultMessage: 'Logging out...',
  },
  speedVal: {
    id: 'alert.speedVal',
    defaultMessage: 'Speed: {value} km/h',
  },
  startedMovingCardText: {
    id: 'alert.startedMovingCardText',
    defaultMessage: 'Started moving',
  },
  stoppedMovingCardText: {
    id: 'alert.stoppedMovingCardText',
    defaultMessage: 'Stopped moving',
  },
  startStopTitle: {
    id: 'alert.startStopTitle',
    defaultMessage: 'Start/Stop',
  },
  tpmsCardText: {
    id: 'alert.tpmsCardText',
    defaultMessage: "TPMS: Tire pressure event detected",
  },
  tpmsInfoCentreVal: {
    id: 'alert.tpmsInfoCentreVal',
    defaultMessage: "Centre wheel of axle {axleNum} has pressure {pressure} bar",
  },
  tpmsInfoLeftVal: {
    id: 'alert.tpmsInfoLeftVal',
    defaultMessage: "Wheel {wheelNum} from centre on the left side of axle {axleNum} has pressure {pressure} bar",
  },
  tpmsInfoRightVal: {
    id: 'alert.tpmsInfoRightVal',
    defaultMessage: "Wheel {wheelNum} from centre on the right side of axle {axleNum} has pressure {pressure} bar",
  },
  tpmsTitle: {
    id: 'alert.tpmsTitle',
    defaultMessage: 'TPMS',
  },
  unknownVehicleId: {
    id: 'alert.unknownVehicleId',
    defaultMessage: 'Unknown vehicle ID',
  },
});

export const eventLabel = defineMessages({
  diagnosticModulesEvents: {
    id: 'eventLabel.diagnosticModulesEvents',
    defaultMessage: 'Diagnostic (Modules - Events)',
  },
  abs: {
    id: 'eventLabel.abs',
    defaultMessage: 'ABS',
  },
  antiSnaking: {
    id: 'eventLabel.antiSnaking',
    defaultMessage: 'Anti-snaking',
  },
  driveDetectionMemoryBit: {
    id: 'eventLabel.driveDetectionMemoryBit',
    defaultMessage: 'Driving detection memory bit',
  },
  ecasBadRoadDetection: {
    id: 'eventLabel.ecasBadRoadDetection',
    defaultMessage: 'ECAS - Bad road detection',
  },
  ecasOutsideRequestedLevel: {
    id: 'eventLabel.ecasOutsideRequestedLevel',
    defaultMessage: 'ECAS - Outside requested level',
  },
  ecasPlausibilityErrorFrontAxle: {
    id: 'eventLabel.ecasPlausibilityErrorFrontAxle',
    defaultMessage: 'ECAS plausibility error front axle',
  },
  ecasPlausibilityErrorRearAxle: {
    id: 'eventLabel.ecasPlausibilityErrorRearAxle',
    defaultMessage: 'ECAS plausibility error rear axle',
  },
  epbDeactivatedByUser: {
    id: 'eventLabel.epbDeactivatedByUser',
    defaultMessage: 'EPB - Deactivated by user',
  },
  error: {
    id: 'eventLabel.error',
    defaultMessage: 'Error',
  },
  gio1: {
    id: 'eventLabel.gio1',
    defaultMessage: 'GIO 1 (Jedermann analog)',
  },
  gio2: {
    id: 'eventLabel.gio2',
    defaultMessage: 'GIO 2 (Jedermann digital)',
  },
  gioFcf1: {
    id: 'eventLabel.gioFcf1',
    defaultMessage: 'GIO FCF 1',
  },
  gioFcf2: {
    id: 'eventLabel.gioFcf2',
    defaultMessage: 'GIO FCF 2',
  },
  gioFcf3: {
    id: 'eventLabel.gioFcf3',
    defaultMessage: 'GIO FCF 3',
  },
  gioFcf4: {
    id: 'eventLabel.gioFcf4',
    defaultMessage: 'GIO FCF 4',
  },
  gioFcf5: {
    id: 'eventLabel.gioFcf5',
    defaultMessage: 'GIO FCF 5',
  },
  gioFcf6: {
    id: 'eventLabel.gioFcf6',
    defaultMessage: 'GIO FCF 6',
  },
  gioFcf7: {
    id: 'eventLabel.gioFcf7',
    defaultMessage: 'GIO FCF 7',
  },
  gioFcf8: {
    id: 'eventLabel.gioFcf8',
    defaultMessage: 'GIO FCF 8',
  },
  immobiliserLockTrailer: {
    id: 'eventLabel.immobiliserLockTrailer',
    defaultMessage: 'Immobilizer lock trailer',
  },
  immobiliserUnlockTrailer: {
    id: 'eventLabel.immobiliserUnlockTrailer',
    defaultMessage: 'Immobilizer unlock trailer',
  },
  immobiliserUnauthorisedUse: {
    id: 'eventLabel.immobiliserUnauthorisedUse',
    defaultMessage: 'Immobilizer unauthorised use',
  },
  immobiliserWrongPin: {
    id: 'eventLabel.immobiliserWrongPin',
    defaultMessage: 'Immobiliser wrong PIN',
  },
  liftAxlePlausibility: {
    id: 'eventLabel.liftAxlePlausibility',
    defaultMessage: 'Lift axle plausibility',
  },
  liningWear: {
    id: 'eventLabel.liningWear',
    defaultMessage: 'Lining wear',
  },
  lowTyrePressure: {
    id: 'eventLabel.lowTyrePressure',
    defaultMessage: 'Low tire pressure',
  },
  nd: {
    id: 'eventLabel.nd',
    defaultMessage: 'nd',
  },
  operation24N: {
    id: 'eventLabel.operation24N',
    defaultMessage: '24N operation',
  },
  optiLoad: {
    id: 'eventLabel.optiLoad',
    defaultMessage: 'OptiLoad',
  },
  optiTurn: {
    id: 'eventLabel.optiTurn',
    defaultMessage: 'OptiTurn',
  },
  plausibilityResPressureControl: {
    id: 'eventLabel.plausibilityResPressureControl',
    defaultMessage: 'Plausibility residual pressure control',
  },
  recordedEvents: {
    id: 'eventLabel.recordedEvents',
    defaultMessage: 'Recorded events',
  },
  relativeBrakeDemand: {
    id: 'eventLabel.relativeBrakeDemand',
    defaultMessage: 'Relative brake demand',
  },
  ropSystemDisabled: {
    id: 'eventLabel.ropSystemDisabled',
    defaultMessage: 'ROP system disabled',
  },
  rssLevel1: {
    id: 'eventLabel.rssLevel1',
    defaultMessage: 'RSS level 1',
  },
  rssLevel2: {
    id: 'eventLabel.rssLevel2',
    defaultMessage: 'RSS level 2',
  },
  scbEvent: {
    id: 'eventLabel.scbEvent',
    defaultMessage: 'SCB event',
  },
  standByOperation: {
    id: 'eventLabel.standByOperation',
    defaultMessage: 'Standby operation',
  },
  supplyPressureWarning: {
    id: 'eventLabel.supplyPressureWarning',
    defaultMessage: 'Supply pressure warning',
  },
  tailGuardDeactivated: {
    id: 'eventLabel.tailGuardDeactivated',
    defaultMessage: 'TailGuard deactivated (ELEX)',
  },
  tbsBrakeIntervention: {
    id: 'eventLabel.tbsBrakeIntervention',
    defaultMessage: 'TSB - Brake intervention',
  },
  tbsDeactivationByUser: {
    id: 'eventLabel.tbsDeactivationByUser',
    defaultMessage: 'TSB - Deactivation by user',
  },
  tiltAlert: {
    id: 'eventLabel.tiltAlert',
    defaultMessage: 'Tilt alert',
  },
  tpmCalibrationRequest: {
    id: 'eventLabel.tpmCalibrationRequest',
    defaultMessage: 'TPM calibration request',
  },
  trailerDocked: {
    id: 'eventLabel.trailerDocked',
    defaultMessage: 'Trailer docked (ELEX)',
  },
  trailerOverload: {
    id: 'eventLabel.trailerOverload',
    defaultMessage: 'Trailer overload',
  },
  tyrePressureInsufficient: {
    id: 'eventLabel.tyrePressureInsufficient',
    defaultMessage: 'Insufficient tire pressure',
  },
  warningLamp: {
    id: 'eventLabel.warningLamp',
    defaultMessage: 'Warning lamp',
  },
  wheelEndOverTemp: {
    id: 'eventLabel.wheelEndOverTemp',
    defaultMessage: 'Wheel end over temperature',
  },
  ycSystemDisabled: {
    id: 'eventLabel.ycSystemDisabled',
    defaultMessage: 'YC system disabled',
  },
});

export const navMenu = defineMessages({
  mapView: {
    id: 'navMenu.mapView',
    defaultMessage: 'Map view',
  },
  health: {
    id: 'navMenu.health',
    defaultMessage: 'Health',
  },
  deviceIdCard: {
    id: 'navMenu.deviceIdCard',
    defaultMessage: 'Trailer ID card',
  },
  simulator: {
    id: 'navMenu.simulator',
    defaultMessage: 'Simulator',
  }
});

export const button = defineMessages({
  cancel: {
    id: 'button.cancel',
    defaultMessage: 'Cancel',
  },
  confirmDelete: {
    id: 'button.confirmDelete',
    defaultMessage: 'Confirm delete',
  },
  delete: {
    id: 'button.delete',
    defaultMessage: 'Delete',
  },
  excludeZeroMileage: {
    id: 'button.excludeZeroMileage',
    defaultMessage: 'Exclude zero mileage',
  },
  health: {
    id: 'button.health',
    defaultMessage: 'Health',
  },
  hideWabcoPartners: {
    id: 'button.hideWabcoPartners',
    defaultMessage: 'Hide WABCO Partners',
  },
  ignoreNotifications: {
    id: 'button.ignoreNotifications',
    defaultMessage: 'Ignore these notifications',
  },
  liveMap: {
    id: 'button.liveMap',
    defaultMessage: 'Live map',
  },
  logOut: {
    id: 'button.logOut',
    defaultMessage: 'Log out',
  },
  markAsRead: {
    id: 'button.markAsRead',
    defaultMessage: 'Mark as read',
  },
  markAsUnread: {
    id: 'button.markAsUnread',
    defaultMessage: 'Mark as unread',
  },
  no: {
    id: 'button.no',
    defaultMessage: 'No',
  },
  notificationCentre: {
    id: 'button.notificationCentre',
    defaultMessage: 'Notification centre',
  },
  ok: {
    id: 'button.ok',
    defaultMessage: 'OK',
  },
  pickATrailer: {
    id: 'button.pickATrailer',
    defaultMessage: 'Pick a trailer',
  },
  refreshODR: {
    id: 'button.refreshODR',
    defaultMessage: 'Refresh ODR',
  },
  sessionRenew: {
    id: 'button.sessionRenew',
    defaultMessage: 'Renew session',
  },
  settings: {
    id: 'button.settings',
    defaultMessage: 'Settings',
  },
  share: {
    id: 'button.share',
    defaultMessage: 'Share',
  },
  showOnHealthPage: {
    id: 'button.showOnHealthPage',
    defaultMessage: "Show on 'Health' page",
  },
  showOnLiveMapPage: {
    id: 'button.showOnLiveMapPage',
    defaultMessage: "Show on 'Live Map' page",
  },
  showOndeviceIdCardPage: {
    id: 'button.showOndeviceIdCardPage',
    defaultMessage: "Show on 'Trailer ID card' page",
  },
  showWabcoPartners: {
    id: 'button.showWabcoPartners',
    defaultMessage: 'Show WABCO partners',
  },
  timeMileage: {
    id: 'button.timeMileage',
    defaultMessage: 'Time / Mileage',
  },
  viewTrailerDetails: {
    id: 'button.viewTrailerDetails',
    defaultMessage: 'View trailer details',
  },
  yes: {
    id: 'button.yes',
    defaultMessage: 'Yes',
  },
});

// Trailer Status
export const status = defineMessages({
  moving: {
    id: 'status.moving',
    defaultMessage: 'Moving',
  },
  poweredDown: {
    id: 'status.poweredDown',
    defaultMessage: 'Powered down',
  },
  standstill: {
    id: 'status.standstill',
    defaultMessage: 'Standstill',
  },
});

// Trailer Types
export const type = defineMessages({
  centralAxle: {
    id: 'type.centralAxle',
    defaultMessage: 'Central axle trailer',
  },
  dolly: {
    id: 'type.dolly',
    defaultMessage: 'Dolly',
  },
  drawbar: {
    id: 'type.drawbar',
    defaultMessage: 'Drawbar trailer',
  },
  semi: {
    id: 'type.semi',
    defaultMessage: 'Semi-trailer',
  },
});

// Short phrase or word likely to be used more than once in the application
export const short = defineMessages({
  absCount: {
    id: 'short.absCount',
    defaultMessage: 'ABS count',
  },
  absSystem: {
    id: 'short.absSystem',
    defaultMessage: 'ABS system',
  },
  activeTrailers: {
    id: 'short.activeTrailers',
    defaultMessage: 'EBPMS Active trailers',
  },
  airSuspension: {
    id: 'short.airSuspension',
    defaultMessage: 'Air suspension',
  },
  allDtcEvents: {
    id: 'short.allDtcEvents',
    defaultMessage: 'All DTC events',
  },
  allRecordedEvents: {
    id: 'short.allRecordedEvents',
    defaultMessage: 'All recorded events',
  },
  avgAxleLoad: {
    id: 'short.avgAxleLoad',
    defaultMessage: 'Avg axle load',
  },
  axleDetails: {
    id: 'short.axleDetails',
    defaultMessage: 'Axle details',
  },
  axleLoad: {
    id: 'short.axleLoad',
    defaultMessage: 'Axle load',
  },
  axleNum: {
    id: 'short.axleNum',
    defaultMessage: 'Axle no. {value}',
  },
  axles: {
    id: 'short.axles',
    defaultMessage: 'Axles',
  },
  beta: {
    id: 'short.beta',
    defaultMessage: 'BETA',
  },
  brakeCount: {
    id: 'short.brakeCount',
    defaultMessage: 'Brake count',
  },
  brakePressure: {
    id: 'short.brakePressure',
    defaultMessage: 'Brake pressure',
  },
  brakePressureH2: {
    id: 'short.brakePressureH2',
    defaultMessage: 'Brake pressure H2',
  },
  brand: {
    id: 'short.brand',
    defaultMessage: 'Brand',
  },
  browser: {
    id: 'short.browser',
    defaultMessage: 'Browser',
  },
  checksum: {
    id: 'short.checksum',
    defaultMessage: 'Checksum',
  },
  circumference: {
    id: 'short.circumference',
    defaultMessage: 'Circumference',
  },
  code: {
    id: 'short.code',
    defaultMessage: 'Code',
  },
  columnName: {
    id: 'short.columnName',
    defaultMessage: 'Column name',
  },
  columnSettings: {
    id: 'short.columnSettings',
    defaultMessage: 'Column settings',
  },
  configure: {
    id: 'short.configure',
    defaultMessage: 'Configure',
  },
  copyToClipboard: {
    id: 'short.copyToClipboard',
    defaultMessage: 'Copy to clipboard',
  },
  critical: {
    id: 'short.critical',
    defaultMessage: 'Critical',
  },
  date: {
    id: 'short.date',
    defaultMessage: 'Date',
  },
  restoreDefaultWidths: {
    id: 'short.restoreDefaultWidths',
    defaultMessage: 'Restore default widths',
  },
  description: {
    id: 'short.description',
    defaultMessage: 'Description',
  },
  details: {
    id: 'short.details',
    defaultMessage: 'Details',
  },
  diagnosticEventCounter: {
    id: 'short.diagnosticEventCounter',
    defaultMessage: 'Diagnostic event counter',
  },
  distance: {
    id: 'short.distance',
    defaultMessage: 'Distance',
  },
  duration: {
    id: 'short.duration',
    defaultMessage: 'Duration',
  },
  durationScore: {
    id: 'short.durationScore',
    defaultMessage: 'Duration score',
  },
  ebsBrakeCalcNum: {
    id: 'short.ebsBrakeCalcNum',
    defaultMessage: 'EBS braking calc. num.',
  },
  ebsPartNumber: {
    id: 'short.ebsPartNumber',
    defaultMessage: 'EBS part number',
  },
  ebsTimeVal: {
    id: 'short.ebsTimeVal',
    defaultMessage: '{value} at {time}',
  },
  ebsTireTimeVal: {
    id: 'short.ebsTireTimeVal',
    defaultMessage: 'Taken at {time}',
  },
  ebsType: {
    id: 'short.ebsType',
    defaultMessage: 'EBS type',
  },
  email: {
    id: 'short.email',
    defaultMessage: 'Email',
  },
  error: {
    id: 'short.error',
    defaultMessage: 'Error',
  },
  errorVal: {
    id: 'short.errorVal',
    defaultMessage: 'Error {value}',
  },
  errorCode: {
    id: 'short.errorCode',
    defaultMessage: 'Error code',
  },
  eventList: {
    id: 'short.eventList',
    defaultMessage: 'Event list',
  },
  events: {
    id: 'short.events',
    defaultMessage: 'Events',
  },
  eventType: {
    id: 'short.eventType',
    defaultMessage: 'Event Type',
  },
  evolution: {
    id: 'short.evolution',
    defaultMessage: 'Evolution',
  },
  fax: {
    id: 'short.fax',
    defaultMessage: 'Fax',
  },
  filterMenu: {
    id: 'short.filterMenu',
    defaultMessage: 'Filter menu',
  },
  frequency: {
    id: 'short.frequency',
    defaultMessage: 'Frequency',
  },
  frequencyScore: {
    id: 'short.frequencyScore',
    defaultMessage: 'Frequency score',
  },
  fullDescription: {
    id: 'short.fullDescription',
    defaultMessage: 'Full description',
  },
  generalInfo: {
    id: 'short.generalInfo',
    defaultMessage: 'General info',
  },
  generalInformation: {
    id: 'short.generalInformation',
    defaultMessage: 'General information',
  },
  history: {
    id: 'short.history',
    defaultMessage: 'History',
  },
  hours: {
    id: 'short.hours',
    defaultMessage: 'hours',
  },
  important: {
    id: 'short.important',
    defaultMessage: 'Important',
  },
  independentSuspension: {
    id: 'short.independentSuspension',
    defaultMessage: 'Independent suspension',
  },
  independentWheelSuspension: {
    id: 'short.independentWheelSuspension',
    defaultMessage: 'Independent wheel suspension',
  },
  info: {
    id: 'short.info',
    defaultMessage: 'Info',
  },
  invalidGPS: {
    id: 'short.invalidGPS',
    defaultMessage: 'Invalid GPS',
  },
  issue: {
    id: 'short.issue',
    defaultMessage: 'Issue',
  },
  lastPosition: {
    id: 'short.lastPosition',
    defaultMessage: 'Last position',
  },
  lastReport: {
    id: 'short.lastReport',
    defaultMessage: 'Last report',
  },
  latAcceleration: {
    id: 'short.latAcceleration',
    defaultMessage: 'Lat Acceleration',
  },
  lift: {
    id: 'short.lift',
    defaultMessage: 'Lift',
  },
  light: {
    id: 'short.light',
    defaultMessage: 'Light',
  },
  list: {
    id: 'short.list',
    defaultMessage: 'List',
  },
  load: {
    id: 'short.load',
    defaultMessage: 'Load',
  },
  loaded: {
    id: 'short.loaded',
    defaultMessage: 'Loaded',
  },
  maxAxleLoad: {
    id: 'short.maxAxleLoad',
    defaultMessage: 'Max. axle load',
  },
  maxSpeed: {
    id: 'short.maxSpeed',
    defaultMessage: 'Max. speed',
  },
  mechanicalSuspension: {
    id: 'short.mechanicalSuspension',
    defaultMessage: 'Mechanical suspension',
  },
  mileage: {
    id: 'short.mileage',
    defaultMessage: 'Mileage',
  },
  minAxleLoad: {
    id: 'short.minAxleLoad',
    defaultMessage: 'Min. axle load',
  },
  model: {
    id: 'short.model',
    defaultMessage: 'Model',
  },
  module: {
    id: 'short.module',
    defaultMessage: 'Module',
  },
  move: {
    id: 'short.move',
    defaultMessage: 'Move',
  },
  name: {
    id: 'short.name',
    defaultMessage: 'Name',
  },
  no: {
    id: 'short.no',
    defaultMessage: 'No',
  },
  noData: {
    id: 'short.noData',
    defaultMessage: 'No data',
  },
  noIssue: {
    id: 'short.noIssue',
    defaultMessage: 'No issue',
  },
  none: {
    id: 'short.none',
    defaultMessage: 'None',
  },
  notifications: {
    id: 'short.notifications',
    defaultMessage: 'Notifications',
  },
  noNotifications: {
    id: 'short.noNotifications',
    defaultMessage: 'No notifications',
  },
  noSteeringAxle: {
    id: 'short.noSteeringAxle',
    defaultMessage: 'No steering axle',
  },
  notSupported: {
    id: 'short.notSupported',
    defaultMessage: 'Not supported',
  },
  notSupported32KbODR: {
    id: 'short.notSupported32KbODR',
    defaultMessage: 'Not supported (32 KB ODR)',
  },
  noTrailer: {
    id: 'short.noTrailer',
    defaultMessage: 'No trailer',
  },
  notVisible: {
    id: 'short.notVisible',
    defaultMessage: 'Not visible',
  },
  numDaysVal: {
    id: 'short.numDaysVal',
    defaultMessage: '{value} days',
  },
  odrEepromSize: {
    id: 'short.odrEepromSize',
    defaultMessage: 'ODR EEPROM Size',
  },
  ok: {
    id: 'short.ok',
    defaultMessage: 'OK',
  },
  oneDay: {
    id: 'short.oneDay',
    defaultMessage: '1 day',
  },
  oneHour: {
    id: 'short.oneHour',
    defaultMessage: '1 hour',
  },
  oneWeek: {
    id: 'short.oneWeek',
    defaultMessage: '1 week',
  },
  oneYearAgo: {
    id: 'short.oneYearAgo',
    defaultMessage: '1 year ago',
  },
  or: {
    id: 'short.or',
    defaultMessage: 'Or',
  },
  phone: {
    id: 'short.phone',
    defaultMessage: 'Phone',
  },
  pneumaticPressure: {
    id: 'short.pneumaticPressure',
    defaultMessage: 'Pneumatic pressure',
  },
  priority: {
    id: 'short.priority',
    defaultMessage: 'Priority',
  },
  premium: {
    id: 'short.premium',
    defaultMessage: 'Premium',
  },
  pressureCan: {
    id: 'short.pressureCan',
    defaultMessage: 'Pressure CAN',
  },
  productionDate: {
    id: 'short.productionDate',
    defaultMessage: 'Production date',
  },
  recency: {
    id: 'short.recency',
    defaultMessage: 'Recency',
  },
  recencyScore: {
    id: 'short.recencyScore',
    defaultMessage: 'Recency score',
  },
  reset: {
    id: 'short.reset',
    defaultMessage: 'Reset',
  },
  rigidAxle: {
    id: 'short.rigidAxle',
    defaultMessage: 'Rigid axle',
  },
  safe: {
    id: 'short.safe',
    defaultMessage: 'Safe',
  },
  score: {
    id: 'short.score',
    defaultMessage: 'Score',
  },
  search: {
    id: 'short.search',
    defaultMessage: 'Search',
  },
  searchValue: {
    id: 'short.searchValue',
    defaultMessage: 'Search value',
  },
  sensor: {
    id: 'short.sensor',
    defaultMessage: 'Sensor',
  },
  show: {
    id: 'short.show',
    defaultMessage: 'Show',
  },
  showHideColumns: {
    id: 'short.showHideColumns',
    defaultMessage: 'Show / Hide columns',
  },
  since: {
    id: 'short.since',
    defaultMessage: 'Since',
  },
  singleTyre: {
    id: 'short.singleTyre',
    defaultMessage: 'Single tire',
  },
  singleTyres: {
    id: 'short.singleTyres',
    defaultMessage: 'Single tires',
  },
  speed: {
    id: 'short.speed',
    defaultMessage: 'Speed',
  },
  standard: {
    id: 'short.standard',
    defaultMessage: 'Standard',
  },
  status: {
    id: 'short.status',
    defaultMessage: 'Status',
  },
  steeringAxle: {
    id: 'short.steeringAxle',
    defaultMessage: 'Steering axle',
  },
  supplyPressure: {
    id: 'short.supplyPressure',
    defaultMessage: 'Supply pressure',
  },
  supplyVoltage: {
    id: 'short.supplyVoltage',
    defaultMessage: 'Supply voltage',
  },
  suspensionType: {
    id: 'short.suspensionType',
    defaultMessage: 'Suspension type',
  },
  teeth: {
    id: 'short.teeth',
    defaultMessage: 'Teeth',
  },
  tenHours: {
    id: 'short.tenHours',
    defaultMessage: '10 hours',
  },
  time: {
    id: 'short.time',
    defaultMessage: 'Time',
  },
  today: {
    id: 'short.today',
    defaultMessage: 'Today',
  },
  yesterday: {
    id: 'short.yesterday',
    defaultMessage: 'Yesterday',
  },
  tyreChoice: {
    id: 'short.tyreChoice',
    defaultMessage: 'Tire choice',
  },
  tyres: {
    id: 'short.tyres',
    defaultMessage: 'Tires',
  },
  trailer: {
    id: 'short.trailer',
    defaultMessage: 'Trailer',
  },
  trailerList: {
    id: 'short.trailerList',
    defaultMessage: 'Trailer list',
  },
  trailers: {
    id: 'short.trailers',
    defaultMessage: 'Trailers',
  },
  trip: {
    id: 'short.trip',
    defaultMessage: 'Trip',
  },
  trips: {
    id: 'short.trips',
    defaultMessage: 'Trips',
  },
  twinTyres: {
    id: 'short.twinTyres',
    defaultMessage: 'Twin tires',
  },
  twoDays: {
    id: 'short.twoDays',
    defaultMessage: '2 days',
  },
  type: {
    id: 'short.type',
    defaultMessage: 'Type',
  },
  unknown: {
    id: 'short.unknown',
    defaultMessage: 'Unknown',
  },
  unknownDescription: {
    id: 'short.unknownDescription',
    defaultMessage: 'Unknown description',
  },
  unknownStatus: {
    id: 'short.unknownStatus',
    defaultMessage: 'Unknown status',
  },
  unknownVal: {
    id: 'short.unknownVal',
    defaultMessage: 'Unknown ({value})',
  },
  vehicleType: {
    id: 'short.vehicleType',
    defaultMessage: 'Vehicle type',
  },
  visible: {
    id: 'short.visible',
    defaultMessage: 'Visible',
  },
  warning: {
    id: 'short.warning',
    defaultMessage: 'Warning',
  },
  website: {
    id: 'short.website',
    defaultMessage: 'Website',
  },
  width: {
    id: 'short.width',
    defaultMessage: 'Width',
  },
  yes: {
    id: 'short.yes',
    defaultMessage: 'Yes',
  },
  last2h: {
    id: 'short.last2h',
    defaultMessage: 'Last 2h',
  },
  emailPreview: {
    id: 'short.emailPreview',
    defaultMessage: 'Email preview',
  },
  noPreview: {
    id: 'short.noPreview',
    defaultMessage: 'No preview available',
  },
  context: {
    id: 'short.context',
    defaultMessage: 'Context',
  },
  odrParsingStatus: {
    id: 'short.odrParsingStatus',
    defaultMessage: 'ODR status',
  },
  odometer: {
    id: 'short.odometer',
    defaultMessage: 'Odometer',
  },
  activity: {
    id: 'short.activity',
    defaultMessage: 'Activity',
  },
  driving: {
    id: 'short.driving',
    defaultMessage: 'Driving',
  },
  paused: {
    id: 'short.paused',
    defaultMessage: 'Paused',
  },
  parked: {
    id: 'short.parked',
    defaultMessage: 'Parked',
  },
});

// Short to medium length phrase, or title, possibly used more than once but rarely
export const phrase = defineMessages({
  accessRevoked: {
    id: 'phrase.accessRevoked',
    defaultMessage: 'Access revoked!',
  },
  avgNumDaysBetween2Events: {
    id: 'phrase.avgNumDaysBetween2Events',
    defaultMessage: 'Average number of days/100km between two events',
  },
  avgNumDaysWithStatus: {
    id: 'phrase.avgNumDaysWithStatus',
    defaultMessage: 'Average number of days/100km  with status',
  },
  deleteNotificationsVal: {
    id: 'phrase.deleteNotificationsVal',
    defaultMessage: 'Delete {value} notifications',
  },
  detailsForTrailerVal: {
    id: 'phrase.detailsForTrailerVal',
    defaultMessage: 'Details for trailer {value}',
  },
  dayOfInactivity: {
    id: 'phrase.dayOfInactivity',
    defaultMessage: 'day of inactivity'
  },
  dayOfInactivityCriticalDetected: {
    id: 'phrase.dayOfInactivityCriticalDetected',
    defaultMessage: 'day where a critical issue has been detected'
  },
  dayOfInactivityInfoProvided: {
    id: 'phrase.dayOfInactivityInfoProvided',
    defaultMessage: 'day where an info has been provided'
  },
  dayOfInactivityWarningDetected: {
    id: 'phrase.dayOfInactivityWarningDetected',
    defaultMessage: 'day where a warning has been detected'
  },
  dayOfInactivityWithoutIssues: {
    id: 'phrase.dayOfInactivityWithoutIssues',
    defaultMessage: 'day of activity without any issues'
  },
  eventsDiscardedDTCNoTimestampVal: {
    id: 'phrase.eventsDiscardedDTCNoTimestampVal',
    defaultMessage: 'DTC without timestamp: {value}'
  },
  eventsDiscardedDTCInFutureVal: {
    id: 'phrase.eventsDiscardedDTCInFutureVal',
    defaultMessage: 'DTC in the future: {value}'
  },
  eventsDiscardedInFutureVal: {
    id: 'phrase.eventsDiscardedInFutureVal',
    defaultMessage: 'Recorded events in the future: {value}'
  },
  eventsDiscardedNoTimestampVal: {
    id: 'phrase.eventsDiscardedNoTimestampVal',
    defaultMessage: 'Recorded events without timestamp: {value}'
  },
  eventsDiscardedTotalVal: {
    id: 'phrase.eventsDiscardedTotalVal',
    defaultMessage: 'Discarded events ({value})'
  },
  eventsDiscardedTripsInFutureVal: {
    id: 'phrase.eventsDiscardedTripsInFutureVal',
    defaultMessage: 'Trip events in the future: {value}'
  },
  eventsDiscardedTripsNoTimestampVal: {
    id: 'phrase.eventsDiscardedTripsNoTimestampVal',
    defaultMessage: 'Trip events without timestamp: {value}'
  },
  eventsDiscardedDtcWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedDtcWithInaccurateMileageVal',
    defaultMessage: 'DTC events with inaccurate mileage: {value}'
  },
  eventsDiscardedRecordedEventsWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedRecordedEventsWithInaccurateMileageVal',
    defaultMessage: 'Recorded events with inaccurate mileage: {value}'
  },
  eventsDiscardedTripsWithInaccurateMileageVal: {
    id: 'phrase.eventsDiscardedTripsWithInaccurateMileageVal',
    defaultMessage: 'Trip events with inaccurate mileage: {value}'
  },
  eventTimeline: {
    id: 'phrase.eventTimeline',
    defaultMessage: 'Events timeline'
  },
  fleetHealthEvolution: {
    id: 'phrase.fleetHealthEvolution',
    defaultMessage: 'Fleet health evolution'
  },
  howIsScoreComputed: {
    id: 'phrase.howIsScoreComputed',
    defaultMessage: 'How is the health score calculated?'
  },
  last10kKmSummary: {
    id: 'phrase.last10kKmSummary',
    defaultMessage: 'Last 10,000 km summary',
  },
  lastYearSummary: {
    id: 'phrase.lastYearSummary',
    defaultMessage: 'Last year summary',
  },
  nearestWabcoPartner: {
    id: 'phrase.nearestWabcoPartner',
    defaultMessage: 'Nearest WABCO partner',
  },
  noTrailerSelected: {
    id: 'phrase.noTrailerSelected',
    defaultMessage: 'No trailer selected. Please pick one from the list',
  },
  numDaysSinceLastEvent: {
    id: 'phrase.numDaysSinceLastEvent',
    defaultMessage: 'Number of days/100km since last event',
  },
  odrUploadHint: {
    id: 'phrase.odrUploadHint',
    defaultMessage: 'Support for a single or bulk upload.',
  },
  odrUploadText: {
    id: 'phrase.odrUploadText',
    defaultMessage: 'Click or drag an ODR file to this area to upload',
  },
  recentSummary: {
    id: 'phrase.recentSummary',
    defaultMessage: 'Recent summary',
  },
  sectionOf100kmCriticalDetected: {
    id: 'phrase.sectionOf100kmCriticalDetected',
    defaultMessage: 'sequence of 100 km where a critical issue has been detected',
  },
  sectionOf100kmInfoProvided: {
    id: 'phrase.sectionOf100kmInfoProvided',
    defaultMessage: 'sequence of 100 km where an info has been provided',
  },
  sectionOf100kmNoIssues: {
    id: 'phrase.sectionOf100kmNoIssues',
    defaultMessage: 'sequence of 100 km without any issues',
  },
  sectionOf100kmUnknownStatus: {
    id: 'phrase.sectionOf100kmUnknownStatus',
    defaultMessage: 'sequence of 100 km with an unknown status',
  },
  sectionOf100kmWarningDetected: {
    id: 'phrase.sectionOf100kmWarningDetected',
    defaultMessage: 'sequence of 100 km where a warning has been detected',
  },
  shareODRFile: {
    id: 'phrase.shareODRFile',
    defaultMessage: 'Share ODR file',
  },
  shareTrailerForDurationVal: {
    id: 'phrase.shareTrailerForDurationVal',
    defaultMessage: 'Sharing trailer {value} for',
  },
  userGreetingVal: {
    id: 'phrase.userGreetingVal',
    defaultMessage: 'Hi {value}',
  }
});

// Section of text long, or specific, enough to not be reused elsewhere. Often the text describing a phrase above so will share same end id
export const description = defineMessages({
  accessRevoked: {
    id: 'description.accessRevoked',
    defaultMessage: 'Your link is invalid or has expired.',
  },
  deleteNotificationsVal: {
    id: 'description.deleteNotificationsVal',
    defaultMessage: 'Please confirm that you want to delete all your {value} notifications.',
  },
  durationVal: {
    id: 'description.durationVal',
    defaultMessage: 'Score calculated as a function of the average number of consecutive days within a {critical} and a {warning} state (sum of the two scores capped by 10).',
  },
  ebsAmberNotification: {
    id: 'description.ebsAmberNotification',
    defaultMessage: "Get notified when a device sends 'EBS Amber light' event",
  },
  ebsRedNotification: {
    id: 'description.ebsRedNotification',
    defaultMessage: "Get notified when a device sends 'EBS Red light' event",
  },
  fleetHealthEvolution: {
    id: 'description.fleetHealthEvolution',
    defaultMessage: 'A week-to-week evolution of the health of your fleet',
  },
  harshBrakeNotification: {
    id: 'description.harshBrakeNotification',
    defaultMessage: "Get notified when a device sends 'Harsh brake' event",
  },
  howIsScoreComputed: {
    id: 'description.howIsScoreComputed',
    defaultMessage: 'The health score is based on the combination of 3 subscores that encompasses 3 different concepts: Recency, Duration and Frequency. It is defined as the the average of these 3 scores. The higher the score, the worse the trailer health. In case where the amount of observed period of activity is less then a month, then the health score is computed in a similar way but substitute days by 100km segments of activity (a red badge is displayed on the score when this substitution is performed)'
  },
  lastYearSummary: {
    id: 'description.lastYearSummary',
    defaultMessage: 'Timeline showing the worst event on a daily basis over the course of a year. Each dot represents a day and its color corresponds to the severity of the worst event that occurred during the very same day:',
  },
  last10kKmSummary: {
    id: 'description.last10kKmSummary',
    defaultMessage: 'Timeline showing the worst event on a 100 km basis over the course of a 10,000 km. Each dot represent a period of 100 km and its color corresponds to the severity of the worst event that occurred during the very same day:',
  },
  powerOnNotification: {
    id: 'description.powerOnNotification',
    defaultMessage: "Get notified when a device sends a 'Power On' event",
  },
  recentSummary: {
    id: 'description.recentSummary',
    defaultMessage: 'Timeline showing the worst event on a time or mileage basis. Each dot represents a day / 100-km period and its color corresponds to the severity of the worst event that occurred during this period:',
  },
  startStopNotification: {
    id: 'description.startStopNotification',
    defaultMessage: "Get notified when a device sends a 'Start / Stop' event",
  },
  emailTemplateDescription: {
    id: 'emailTemplateDescription',
    defaultMessage: 'Here is a preview of the email that will be sent to the registered user email that you provided. The placeholders will be replaced by the proper values when the email is written.',
  },
  tpmsNotification: {
    id: 'description.tpmsNotification',
    defaultMessage: "Get notified when a device sends a tire pressure ('TPMS') event",
  }
});


export const abbrev = defineMessages({
  centimeter: {
    id: 'abbrev.centimeter',
    defaultMessage: 'cm',
  },
  identification: {
    id: 'abbrev.identification',
    defaultMessage: 'ID',
  },
  internationalMobileEquipmentIdentity: {
    id: 'abbrev.internationalMobileEquipmentIdentity',
    defaultMessage: 'IMEI',
  },
  kiloBits: {
    id: 'abbrev.kiloBits',
    defaultMessage: 'Kb',
  },
  kilometer: {
    id: 'abbrev.kilometer',
    defaultMessage: 'km',
  },
  kilometerPerHour: {
    id: 'abbrev.kilometerPerHour',
    defaultMessage: 'km/h',
  },
  kilogram: {
    id: 'abbrev.kilogram',
    defaultMessage: 'kg',
  },
  ton: {
    id: 'abbrev.ton',
    defaultMessage: 't',
  },
  meter: {
    id: 'abbrev.meter',
    defaultMessage: 'm',
  },
  second: {
    id: 'abbrev.second',
    defaultMessage: 's',
  },
  vehicleIdentificationNumber: {
    id: 'abbrev.vehicleIdentificationNumber',
    defaultMessage: 'VIN',
  },
  volt: {
    id: 'abbrev.volt',
    defaultMessage: 'V',
  },
});
