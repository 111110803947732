import React from "react";
import { connect } from "react-redux";
import { Button, Icon } from "antd";
import moment from "moment";

import actions from "../../actions";
import getReportTitle from "../../helpers/getReportTitle"

const GetReportButton = (props) => {
  const {
    trailer,
    date = moment(),
    processing,
    getReport,
    children,
    windowSize,
    settingsWindowSize,
    processingReport,
    ...rest
  } = props
  const filename = getReportTitle(trailer.tipNum, date, windowSize || settingsWindowSize)
  const content = children || (
    <span>
      <Icon type="download" />
      {' '}
      {filename}
    </span>
  );
  return (
    <Button
      type="link"
      loading={processingReport[filename]}
      onClick={(e) => {
        e.stopPropagation()
        getReport(trailer, { date, windowSize: windowSize || settingsWindowSize })
      }}
      {...rest}
    >
      {content}
    </Button>
  )
}

function mapStateToProps(store) {
  return {
    processingReport: store.trailers.processingReport,
    settingsWindowSize: store.settings.items && store.settings.items.windowSize,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getReport: (trailer, date) => dispatch(actions.trailers.getReport(trailer, date)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GetReportButton)
