import React from 'react'
import { Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { injectIntl, intlShape } from 'react-intl';

import paths from '../../paths'
//import * as strings from '../../helpers/defaultStrings';
const { Item } = Menu

const NavigationMenu = ({ currentPath, intl }) => {
  //const { formatMessage } = intl;
  return (
    <Menu
      theme="light"
      mode="inline"
      selectedKeys={[currentPath]}
    >
      <Item key={paths.ebpms}>
        <Link to={paths.ebpms}>
          <Icon type="monitor" />
          <span>Braking Performance</span>
        </Link>
      </Item>
      <Item key={paths.reports}>
        <Link to={paths.reports}>
          <Icon type="audit" />
          <span>Reports</span>
        </Link>
      </Item>
    </Menu>
  );
}

NavigationMenu.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NavigationMenu)
