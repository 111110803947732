import ReactGA from "react-ga";
import {history} from "../configureStore";

/* eslint-disable no-useless-concat */

// this is the generic pageView call when page reloads or user navigates to a screen
export function pageView(pathname) {
  const paths = pathname.split('/');
  let view = '';

  if (isHome(pathname)) {
    view = '/home';
  } else {
    const firstPath = paths[1].trim().toLowerCase();
    if ((paths.length === 2) || (paths[2].trim().toLowerCase() === "")) {
      switch (firstPath) {
        case 'health':
          // the user has landed/refreshed on the health page with list tab selected
          // note: if this default tab selection changes in the component it needs to be updated here
          view = '/health/list';
          break;
        case 'notifications':
          // the user has landed/refreshed on the notifications screen with history open
          // note: if this default tab selection changes in the component it needs to be updated here
          view = '/notifications/history';
          break;
        default:
          view = pathname;
          break;
      }
    } else {
      // we need to remove any ids due to GDPR
      switch (firstPath) {
        case 'health':
          // the user has landed/refreshed on the health passport with events tab selected
          // note: if this default tab selection changes in the component it needs to be updated here
          view = '/health/passport/events';
          break;
        case 'trailer':
          // the user has landed/refreshed on the trailer id card with general info tab selected
          // note: if this default tab selection changes in the component it needs to be updated here
          view = '/trailer/id-card/general-info';
          break;
        case 'notifications':
          // the user has landed/refreshed on the notifications screen with history open
          // note: if this default tab selection changes in the component it needs to be updated here
          view = '/notifications/history';
          break;
        default:
          // default is to show first path with ? at the end so we can manually fix when noticed in GA
          view = '/' + firstPath + '/' + '?';
          break;
      }
    }
  }

  ReactGA.set({ page: view });
  ReactGA.pageview(view);
}

export function tabOrModalView(tabOrModal) {
  const pathname = (history && history.location && history.location.pathname ? history.location.pathname : '');
  const paths = pathname.split('/');
  let view = '';

  if (isHome(pathname)) {
    view = '/home/' + tabOrModal;
  } else if ((paths.length === 2) || (paths[2].trim().toLowerCase() === "")) {
    view = pathname + (paths.length <= 2 ? '/' : '') + tabOrModal;
  } else {
    // we need to remove any ids due to GDPR
    const firstPath = paths[1].trim().toLowerCase();
    switch (firstPath) {
      case 'health':
        // the user has landed/refreshed on the health passport with events tabOrModal selected
        view = '/health/passport/' + tabOrModal;
        break;
      case 'trailer':
        // the user has landed/refreshed on the trailer id card with general info tabOrModal selected
        view = '/trailer/id-card/' + tabOrModal;
        break;
      case 'notifications':
        // the user has landed/refreshed on the notifications screen with history open
        view = '/notifications/' + tabOrModal;
        break;
      default:
        // default is to show first path with ? at the end so we can manually fix when noticed in GA
        view = '/' + firstPath + '/' + '?';
        break;
    }
  }

  // if last character is a slash we remove it
  view = (view.charAt(view.length - 1) === '/' ? view.slice(0, (view.length - 1)) : view);

  ReactGA.set({ page: view });
  ReactGA.pageview(view);
}

export function triggerEvent(category, action, label, value) {
  ReactGA.event({
    category,
    action,
    label,
    value
  })
}

// if we are triggering from a component used in multiple locations we auto generate the label
// from appendLabel: pathname
export function triggerGenericEvent(category, action, appendLabel, value) {
  const pathname = (history && history.location && history.location.pathname ? history.location.pathname : '');
  const paths = pathname.split('/');
  let label = '';
  if (isHome(pathname)) {
    label = 'home';
  } else if ((paths.length === 2) || (paths[2].trim().toLowerCase() === "")) {
    label = pathname.trim().toLowerCase();
  } else {
    // we need to remove any ids due to GDPR
    label = paths[1].trim().toLowerCase();
  }

  label = (label.charAt(0) === '/' ? label.slice(1) : label);

  triggerEvent(category, action, label + (appendLabel ? ': ' + appendLabel : ''), value);
}

function isHome(pathname) {
  const paths = pathname.split('/');
  return (paths.length === 0) || // shouldnt be the case
    ((paths.length === 1) && (paths[0].trim() === "")) || // should be the case for just domain name and no slash
    ((paths.length === 2) && (paths[0].trim() === "") && (paths[1].trim() === "")) || // should be the case for a slash at end of domain name '/'
    ((paths.length === 2) && (paths[0].trim() === "") && (paths[1].trim().toLowerCase() === "home")); // should be the case for domain name slash home '/home'
}

/* eslint-enable no-useless-concat */
