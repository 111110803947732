import produce from 'immer'

import { cleanHistoricalPerformances, cleanTrailers } from './datacleaners'
import actionTypes from '../actions/actionTypes'

export const initialTrailersState = {
  items: null,
  selected: null,
  error: null,
  processing: false,
  performanceKey: null,
  processingReport: {},
}

function getPerformanceKey(settings) {
  if (!settings) {
    return 'margin0190'
  }
  const { maxErrorMargin, windowSize } = settings
  let key = 'margin01' // default
  if (maxErrorMargin === Number.MAX_VALUE) key = 'nomargin'
  if (maxErrorMargin === 0.03) key = 'margin003'
  key += (windowSize || 90) // default
  return key
}


function trailerReducer(state = initialTrailersState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // get trailers list
      case actionTypes.GET_TRAILERS_REQUEST:
        draft.items = null
        draft.error = null
        draft.processing = true
        break
      case actionTypes.GET_TRAILERS_SUCCESS:
        const key = getPerformanceKey(action.settings)
        draft.items = (state.items || []).concat(
          cleanTrailers(action.trailers)
            .map(trailer => ({
              ...trailer,
              ...trailer[key], // default
            })),
        )
        draft.processing = !action.final
        draft.performanceKey = key
        break
      case actionTypes.GET_TRAILERS_FAILURE:
        draft.items = null
        draft.processing = false
        draft.error = action.error
        break
      // get trailer detail
      case actionTypes.GET_TRAILER_DETAIL_REQUEST:
        if (state.selected && action.deviceId === state.selected.deviceId) {
          draft.selected = {
            ...draft.selected,
            historicalPerformances: {
              processing: true,
              error: null,
              items: null,
            },
          }
        }
        break
      case actionTypes.GET_TRAILER_DETAIL_SUCCESS:
        if (state.selected && action.deviceId === state.selected.deviceId
          && action.trailer && action.trailer.performanceHistory) {
          draft.selected = {
            ...draft.selected,
            historicalPerformances: {
              processing: false,
              error: null,
              items: cleanHistoricalPerformances(action.trailer.performanceHistory),
            },
          }
        }
        break
      case actionTypes.GET_TRAILER_DETAIL_FAILURE:
        if (state.selected && action.deviceId === state.selected.deviceId) {
          draft.selected = {
            ...draft.selected,
            historicalPerformances: {
              processing: false,
              error: action.error,
              items: null,
            },
          }
        }
        break
      // select trailer
      case actionTypes.SELECT_TRAILER:
        if (state.items && action.deviceId) {
          const selected = state.items.find(trailer => trailer.deviceId === action.deviceId);
          if (selected) draft.selected = selected;
        } else {
          draft.selected = null;
        }
        break
      // update values
      case actionTypes.GET_SETTINGS_SUCCESS:
      case actionTypes.SAVE_SETTINGS_SUCCESS:
        if (state.items && action.settings) {
          const key = getPerformanceKey(action.settings)
          if (key !== state.performanceKey) {
            draft.items = state.items
              .map(trailer => ({
                ...trailer,
                ...trailer[key],
              }))
            draft.performanceKey = key
            if (state.selected) {
              draft.selected = {
                ...state.selected,
                ...state.selected[key],
              }
            }
          }
        }
        break
      // get report
      case actionTypes.GET_REPORT_REQUEST:
        draft.processingReport[action.filename] = true
        break
      case actionTypes.GET_REPORT_SUCCESS:
      case actionTypes.GET_REPORT_FAILURE:
        if (state.processingReport[action.filename]) {
          delete draft.processingReport[action.filename]
        }
        break
      default:
        return state
    }
  })
}

export default trailerReducer
