import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

import config from './config.json'

export const configureAuth = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: config.IdentityPool,
      region: 'eu-west-1',
      userPoolId: config.UserPoolId,
      userPoolWebClientId: config.UserPoolClientId,
      mandatorySignIn: false,
      authenticationFlowType: 'USER_PASSWORD_AUTH' ,//'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH',
    },
  });
}

export const configureAPI = () => {
  Amplify.configure({
    API: {
      endpoints: [
        {
          name: 'EBPMSBackend',
          endpoint: `${config.ApiGateway}/`,
          region: 'eu-west-1',
          custom_header: async () => {
            // jwtToken to restrict trailers access by fleet
            // (transics user have no currentSession thus we set jwtToken=undefined
            // so that they have access to all trailers)
            const jwtToken = await Auth.currentSession()
              .then(r => r.getIdToken().getJwtToken())
              .catch(_ => undefined);
            const jwtTokenHeader = jwtToken && { 'X-amz-cognito-token': jwtToken}
            // transics token
            const transicsToken = window.localStorage.getItem('transics-token')
            const transicsTokenHeader = transicsToken && { 'X-transics-token': transicsToken }
            return {
              ...jwtTokenHeader,
              ...transicsTokenHeader,
            }
          },
        }
      ],
    },
  })
};
