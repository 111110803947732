import React, { Component } from 'react'
import 'babel-polyfill';
import { IntlProvider, addLocaleData } from 'react-intl';
import moment from 'moment'
import 'moment/min/locales';
import numeral from 'numeral'
import 'numeral/min/locales.min';
import AppLayout from './Components/Layout/Layout'
import ar from 'react-intl/locale-data/ar';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import nl from 'react-intl/locale-data/nl';
import no from 'react-intl/locale-data/no';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import ru from 'react-intl/locale-data/ru';
import sk from 'react-intl/locale-data/sk';
import sl from 'react-intl/locale-data/sl';
import sv from 'react-intl/locale-data/sv';
import tr from 'react-intl/locale-data/tr';
import * as d3 from 'd3';

import {connect} from "react-redux";
import {Route} from "react-router";
import {ConnectedRouter} from "connected-react-router";
import ReactGA from 'react-ga';


import localeData from "./assets/i18n/locales/data";
import {history} from "./configureStore";
import { pageView } from './helpers/googleAnalytics';

const { REACT_APP_GOOGLE_ANALYTICS_CODE } = process.env

if (REACT_APP_GOOGLE_ANALYTICS_CODE) {
  ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_CODE);
}

if (REACT_APP_GOOGLE_ANALYTICS_CODE) {
  if (history && history.location && history.location.pathname) {
    pageView(history.location.pathname);
  }

  history.listen(location => {
    pageView(history.location.pathname);
  });
}

// If browser doesn't support Intl (i.e. Safari), then we manually import
// the intl polyfill and locale data.
if (!window.intl) {
  require('intl');
  require('intl/locale-data/jsonp/ar.js');
  require('intl/locale-data/jsonp/cs.js');
  require('intl/locale-data/jsonp/da.js');
  require('intl/locale-data/jsonp/de.js');
  require('intl/locale-data/jsonp/en.js');
  require('intl/locale-data/jsonp/es.js');
  require('intl/locale-data/jsonp/fr.js');
  require('intl/locale-data/jsonp/it.js');
  require('intl/locale-data/jsonp/nl.js');
  require('intl/locale-data/jsonp/nb.js'); // doesn't have std no for Norwegian, it has nb: Norwegian Bokmål
  require('intl/locale-data/jsonp/pl.js');
  require('intl/locale-data/jsonp/pt.js');
  require('intl/locale-data/jsonp/ro.js');
  require('intl/locale-data/jsonp/ru.js');
  require('intl/locale-data/jsonp/sk.js');
  require('intl/locale-data/jsonp/sl.js');
  require('intl/locale-data/jsonp/sv.js');
  require('intl/locale-data/jsonp/tr.js');
}

addLocaleData([
  ...ar,
  ...cs,
  ...da,
  ...de,
  ...en,
  ...es,
  ...fr,
  ...it,
  ...nl,
  ...no,
  ...pl,
  ...pt,
  ...ro,
  ...ru,
  ...sk,
  ...sl,
  ...sv,
  ...tr,
]);

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
let Language = (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;


// Split locales with a region code
const languageWithoutRegionCode = Language.toLowerCase().split(/[_-]+/)[0];
let twoLetterLangCode = (Language.length === 2 ? Language : languageWithoutRegionCode);

// If browser doesn't support Intl
// and lang is no or nn (Norwegian Nynorsk)
// we need to switch language to nb: Norwegian Bokmål and messages to no
if ((!window.intl) && ((twoLetterLangCode === 'no') || (twoLetterLangCode === 'nn'))) {
  Language = Language.replace(twoLetterLangCode, 'nb');
  twoLetterLangCode = 'no';
} else if ((window.intl) && ((twoLetterLangCode === 'nb') || (twoLetterLangCode === 'nn'))) {
  // If browser does support Intl
  // and lang is nb (Norwegian Bokmål) or nn (Norwegian Nynorsk)
  // we switch language and messages to std Norwegian (no)
  Language = Language.replace(twoLetterLangCode, 'no');
  twoLetterLangCode = 'no';
}

const defaultEnUk = code => (code === 'en' ? 'en-gb' : code)
moment.locale(defaultEnUk(Language));
// Try full locale, fallback to locale without region code, fallback to en
const Messages = localeData[twoLetterLangCode] || localeData.en;

// store them for use in modals and other components that are not children of this component
localStorage.setItem('locale', JSON.stringify({
  lang: Language,
  messages: Messages
}));

class LocaleContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: Language,
      messages: Messages,
      setDTC: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { user } = this.props;
    const { language } = this.state;

    if (user && (JSON.stringify(prevProps.user) !== JSON.stringify(user))) {
      const userLanguage = user.language;
      if ((language !== userLanguage) && (localeData[userLanguage])) {
        try {
          moment.locale(defaultEnUk(user.locale));
        } catch (e) {
          console.warn('No locale for moment.js for ' + defaultEnUk(userLanguage) + ' so setting it to English GB');
          moment.locale(defaultEnUk('en'));
        }

        try {
          numeral.locale(user.locale);
          // test so we can throw error if above didnt work (required for numeral locale)
          numeral('5000').format('0.0a');
        } catch (e) {
          console.warn('No locale for numeral.js for ' + userLanguage + ' so setting it to English');
          numeral.locale('en');
        }

        localStorage.setItem('locale', JSON.stringify({
          lang: userLanguage,
          messages: localeData[userLanguage]
        }));

        this.setState({
          language: userLanguage,
          messages: localeData[userLanguage]
        })
      }

      d3.timeFormatDefaultLocale({
        "dateTime": "%a %e %b %-H:%M:%S %p %Y",
        "date": "%d/%m/%Y",
        "time": "%H:%M:%S",
        "periods": ["", ""],
        "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      })
    }
  }

  render() {
    const { language, messages } = this.state;
    return (
      <IntlProvider locale={language} messages={messages}>
        <ConnectedRouter history={history}>
          <Route render={props => <AppLayout {...props} />} />
        </ConnectedRouter>
      </IntlProvider>
    )
  }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user,
  }
}


function mapDispatchToProps(dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LocaleContainer)
