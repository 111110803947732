export default {
  USER_LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
  
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',

  GET_TRAILERS_REQUEST: 'GET_TRAILERS_REQUEST',
  GET_TRAILERS_SUCCESS: 'GET_TRAILERS_SUCCESS',
  GET_TRAILERS_FAILURE: 'GET_TRAILERS_FAILURE',
  GET_TRAILER_DETAIL_REQUEST: 'GET_TRAILER_DETAIL_REQUEST',
  GET_TRAILER_DETAIL_SUCCESS: 'GET_TRAILER_DETAIL_SUCCESS',
  GET_TRAILER_DETAIL_FAILURE: 'GET_TRAILER_DETAIL_FAILURE',
  SELECT_TRAILER: 'SELECT_TRAILER',
  GET_REPORT_REQUEST: 'GET_REPORT_REQUEST',
  GET_REPORT_SUCCESS: 'GET_REPORT_SUCCESS',
  GET_REPORT_FAILURE: 'GET_REPORT_FAILURE',

  GET_BRAKE_EVENTS_REQUEST: 'GET_BRAKE_EVENTS_REQUEST',
  GET_BRAKE_EVENTS_SUCCESS: 'GET_BRAKE_EVENTS_SUCCESS',
  GET_BRAKE_EVENTS_FAILURE: 'GET_BRAKE_EVENTS_FAILURE',
  SELECT_BRAKE_EVENT: 'SELECT_BRAKE_EVENT',
  HOVER_BRAKE_EVENT: 'HOVER_BRAKE_EVENT',

  GET_TELEMETRY_REQUEST: 'GET_TELEMETRY_REQUEST',
  GET_TELEMETRY_SUCCESS: 'GET_TELEMETRY_SUCCESS',
  GET_TELEMETRY_FAILURE: 'GET_TELEMETRY_FAILURE',

  GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

  SAVE_SETTINGS_REQUEST: 'SAVE_SETTINGS_REQUEST',
  SAVE_SETTINGS_SUCCESS: 'SAVE_SETTINGS_SUCCESS',
  SAVE_SETTINGS_FAILURE: 'SAVE_SETTINGS_FAILURE',

  ADD_MESSAGE: 'ADD_MESSAGE',
}
