import * as d3 from 'd3';
import moment from 'moment';

import Chart from '../chart';

import './DayBarChart.css'


export default class DayBarChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main dayBarChart')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    // timewindow
    this.window = this.main.append('g')
      .attr('class', 'window')

    this.window
      .append('rect')
      .attr('x', -this.props.width)
      .attr('y', 0)
      .attr('width', this.props.width * 0.2)
      .attr('height', this.props.height)
      .style('fill', '#efefef')

    // bars:  selected in blue, rejected in grey + transparent overlay
    this.barsSelected = this.main.append('g')
      .attr('class', 'barsSelected')
      .style('fill', 'dodgerblue')
    this.barsRejected = this.main.append('g')
      .attr('class', 'barsRejected')
      .style('fill', '#ccc')
    this.barsOverlay = this.main.append('g')
      .attr('class', 'barsOverlay')
      .style('fill', 'transparent')

    // axis
    this.xAxis = this.main.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${this.props.height})`)
    this.yAxis = this.main.append('g')
      .attr('class', 'y axis')

    // tooltip
    if (d3.select('.dayBarChartTooltip').empty()) {
      this.tooltip = d3.select('body')
        .append('div')
        .attr('class', 'dayBarChartTooltip')
        .style('display', 'none');
    } else {
      this.tooltip = d3.select('.dayBarChartTooltip')
        .style('display', 'none');
    }
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.dataSelected && state.dataRejected && state.xRange && state.timeWindow) {
      this._drawChart(state)
    }
  }

  showTooltip(d) {
    if (d && (d.selected || d.rejected)) {
      this.tooltip
        .style('display', 'block')
        .html(`${moment(+d.date).format('L')}<br/>
        ${d.selected + d.rejected} brake events:<br/>
        <strong style="color:dodgerblue">${d.selected}</strong> selected<br/>
        <strong style="color:grey">${d.rejected}</strong> rejected<br/>`)
    } else {
      this.tooltip.style('display', 'none')
    }
  }

  moveTooltip() {
    let orientation
    let xPos
    let yPos
    let styleLeft
    let styleTop
    let styleRight
    let styleBottom
    if (d3.event.pageY < d3.event.view.innerHeight / 2) {
      if (d3.event.pagexPos < d3.event.view.innerWidth / 2) {
        orientation = 1;
        xPos = d3.event.pageX;
        yPos = d3.event.pageY;
      } else {
        orientation = 2;
        xPos = d3.event.view.innerWidth - d3.event.pageX;
        yPos = d3.event.pageY;
      }
    } else if (d3.event.pageX < d3.event.view.innerWidth / 2) {
      orientation = 3;
      xPos = d3.event.pageX;
      yPos = d3.event.view.innerHeight - d3.event.pageY;
    } else {
      orientation = 4;
      xPos = d3.event.view.innerWidth - d3.event.pageX;
      yPos = d3.event.view.innerHeight - d3.event.pageY;
    }
    switch (orientation) {
      case 1:
        styleTop = yPos + 10;
        styleLeft = xPos + 10;
        break;
      case 2:
        styleTop = yPos + 10;
        styleRight = xPos + 10;
        break;
      case 3:
        styleBottom = yPos + 10;
        styleLeft = xPos + 10;
        break;
      default:
        styleBottom = yPos + 10;
        styleRight = xPos + 10;
        break;
    }
    if (styleLeft) {
      this.tooltip
        .style('left', `${styleLeft}px`)
        .style('right', null)
    } else if (styleRight) {
      this.tooltip
        .style('left', null)
        .style('right', `${styleRight}px`)
    }
    if (styleTop) {
      this.tooltip
        .style('top', `${styleTop}px`)
        .style('bottom', null)
    } else if (styleBottom) {
      this.tooltip
        .style('top', null)
        .style('bottom', `${styleBottom}px`)
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  _drawChart(state) {
    const { dataSelected, dataRejected, xRange, timeWindow } = state

    const nDays = xRange[1].diff(xRange[0], 'days')
    const barWidth = (this.props.width / nDays)
    const barSpacing = 0.1 * barWidth;

    // compute data
    const selectedByDates = dataSelected
      .reduce((acc, event) => {
        const date = moment.utc(event.startDatetime).startOf('day')
        const prevCount = acc[date] || 0;
        return { ...acc, [date]: prevCount + 1 }
      }, {})
    const rejectedByDates = dataRejected
      .reduce((acc, event) => {
        const date = moment.utc(event.startDatetime).startOf('day')
        const prevCount = acc[date] || 0;
        return { ...acc, [date]: prevCount + 1 }
      }, {})
    const data = new Array(nDays)
      .fill(0)
      .map((_, i) => {
        const date = moment(xRange[0]).add(i, 'days')
        return {
          date,
          selected: selectedByDates[date] || 0,
          rejected: rejectedByDates[date] || 0,
        }
      })

    // x, y scale, axis & label
    this.x = d3.scaleTime()
      .range([0, this.props.width])
      .domain(xRange)
    const xAxis = d3.axisBottom(this.x)
      .tickSize(3)
      .tickFormat(d3.timeFormat('%b'))
    this.xAxis.transition().duration(750).call(xAxis)

    this.y = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([0, d3.max(data, d => d.selected + d.rejected)])
    const yAxis = d3.axisLeft(this.y)
      .tickSizeInner(-this.props.width)
      .tickSize(-this.props.width)
      .tickSizeOuter(0)
      .ticks(3, d3.format('d'))
    this.yAxis.transition().duration(750).call(yAxis)
    if (!this.yLabel) { // do once
      this.yLabel = this.yAxis
        .call(yAxis)
        .append('text')
        .attr('font-size', 12)
        .attr('text-anchor', 'end')
        .attr('transform', 'rotate(-90) translate(0,-40)')
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('fill', 'rgba(0,0,0,0.65)')
        .text('# events');
    }

    // bars Selected
    const barsSelected = this.barsSelected
      .selectAll('.barSelected')
      .data(data)
    const barsSelectedEnter = barsSelected.enter()
      .append('rect')
      .attr('class', 'barSelected')
    barsSelectedEnter.merge(barsSelected)
      .transition()
      .duration(750)
      .attr('x', d => this.x(d.date) + barSpacing)
      .attr('width', barWidth - (2 * barSpacing))
      .attr('height', d => this.props.height - this.y(d.selected || 0))
      .attr('y', d => this.y(d.selected));
    barsSelected.exit().remove()

    // bar rejected
    const barsRejected = this.barsRejected
      .selectAll('.barRejected')
      .data(data)
    const barsRejectedEnter = barsRejected.enter()
      .append('rect')
      .attr('class', 'barRejected')
    barsRejectedEnter.merge(barsRejected)
      .transition()
      .duration(750)
      .attr('x', d => this.x(d.date) + barSpacing)
      .attr('width', barWidth - (2 * barSpacing))
      .attr('height', d => this.props.height - this.y(d.rejected || 0))
      .attr('y', d => this.y(d.selected + d.rejected));
    barsRejected.exit().remove()

    // bar overlay
    const barsOverlay = this.barsOverlay
      .selectAll('.barOverlay')
      .data(data)
    const barsOverlayEnter = barsOverlay.enter()
      .append('rect')
      .attr('class', 'barOverlay')
    barsOverlayEnter.merge(barsOverlay)
      .attr('x', d => this.x(d.date) + barSpacing)
      .attr('width', barWidth - (2 * barSpacing))
      .attr('height', this.props.height)
      .attr('y', 0);
    barsOverlay.exit().remove()

    // time window
    if (timeWindow) {
      this.window.selectAll('rect')
        .transition()
        .attr('opacity', 1)
        .attr('width', this.x(timeWindow[1]) - this.x(timeWindow[0]))
        .attr('x', this.x(timeWindow[0]))
    } else {
      this.window.selectAll('rect')
        .transition()
        .attr('opacity', 0)
    }
    // tooltip
    const self = this
    barsOverlayEnter
      .on('mouseenter', function onMouseEnter(d) {
        d3.select(this).style('fill', 'rgba(0,0,0,0.05)')
        self.showTooltip(d)
      })
      .on('mousemove', () => { self.moveTooltip() })
      .on('mouseleave', function onMouseLeave(d) {
        d3.select(this).style('fill', 'transparent')
        self.showTooltip(null)
      })
  }
}
