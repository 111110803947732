import produce from 'immer'

import { cleanBrakeEvents, cleanTelemetry } from './datacleaners'
import actionTypes from '../actions/actionTypes'

export const initialBrakingEventsState = {
  deviceId: null,
  items: null,
  error: null,
  processing: false,
  selected: {
    event: null,
    eventId: null,
    telemetry: null,
    processing: false,
    error: null,
  },
}

function brakingEventsReducer(state = initialBrakingEventsState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // get brake events
      case actionTypes.GET_BRAKE_EVENTS_REQUEST:
        draft.deviceId = action.deviceId
        draft.items = null
        draft.error = null
        draft.processing = true
        draft.selected = {
          event: null,
          eventId: null,
          telemetry: null,
          processing: false,
          error: null,
        }
        break
      case actionTypes.GET_BRAKE_EVENTS_SUCCESS:
        if (action.events && action.deviceId === state.deviceId) {
          draft.items = (state.items || []).concat(cleanBrakeEvents(action.events))
          draft.processing = !action.final
        }
        break
      case actionTypes.GET_BRAKE_EVENTS_FAILURE:
        draft.items = null
        draft.processing = false
        draft.error = action.error
        break
      // get telemetry data
      case actionTypes.GET_TELEMETRY_REQUEST:
        draft.selected.event = action.event
        draft.selected.eventId = action.eventId
        draft.selected.telemetry = null
        draft.selected.error = null
        draft.selected.processing = true
        break
      case actionTypes.GET_TELEMETRY_SUCCESS:
        if (action.items && state.selected.event
          && state.selected.eventId === action.eventId) {
          draft.selected.telemetry = (state.selected.telemetry || []).concat(cleanTelemetry(action.items))
          draft.selected.processing = !action.final
        }
        break
      case actionTypes.GET_TELEMETRY_FAILURE:
        draft.selected.telemetry = null
        draft.selected.processing = false
        draft.selected.error = action.error
        break
      default:
        return state
    }
  })
}

export default brakingEventsReducer;
