import React from "react";
import { connect } from "react-redux";

import { Spin, Radio, Icon, Row, Col } from "antd";
import actions from "../../actions";
import { injectIntl, intlShape } from "react-intl";

const block = { display: 'block' };

class ApplicationSettings extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { settings, getSettings } = this.props
    if (!settings) {
      getSettings();
    }
  }

  goBack = () => {
    const { goBack } = this.props;
    // todo check that
    goBack();
  };

  render() {
    const { settings, loadingSettings, saveMaxErrorMargin, saveWindowSize } = this.props
    return (
      <div
        style={{ padding: "30px", backgroundColor: "white", height: "100%" }}
      >
        <div style={{ width: '100%', fontSize: 20, color: 'silvergrey', textAlign: 'right'}}>
          <Icon type="close" onClick={this.goBack} />
        </div>
        <Row type="flex" justify="center">
          <Col xxl={8} xl={10} lg={12} md={16} sm={22} span={24}>
            <Spin spinning={loadingSettings}>
              <h2 style={{ marginBottom: 8 }}>Application Settings</h2>
              <div style={{ color: 'silver' }} >
                <Icon type="info-circle" style={{ marginRight: 8 }} />
                Settings are saved locally: they will change if you use another browser/machine.
              </div>
              <h4 style={{ marginTop: 24 }}>Error Margin:</h4>
              <Radio.Group
                style={{ marginLeft: 8 }}
                onChange={e => saveMaxErrorMargin(e.target.value)}
                value={settings && settings.maxErrorMargin}
              >
                <Radio style={block} value={Number.MAX_VALUE}>
                  All
                </Radio>
                <Radio style={block} value={0.1}>
                  {'Recommended: < 10%'}
                </Radio>
                <Radio style={block} value={0.03}>
                  {'DVSA: < 3%'}
                </Radio>
              </Radio.Group>
              <h4 style={{ marginTop: 24 }}>Window size:</h4>
              <Radio.Group
                style={{ marginLeft: 8 }}
                onChange={e => saveWindowSize(e.target.value)}
                value={settings && settings.windowSize}
              >
                <Radio style={block} value={45}>45 days</Radio>
                <Radio style={block} value={90}>90 days</Radio>
              </Radio.Group>
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    settings: store.settings.items,
    loadingSettings: store.settings.processing,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goBack: () => window.history.back(), // TODO make it smarter when there is > 1 page
    getSettings: () => dispatch(actions.settings.getSettings()),
    saveMaxErrorMargin: value => dispatch(actions.settings.saveSetting('maxErrorMargin', value)),
    saveWindowSize: value => dispatch(actions.settings.saveSetting('windowSize', value)),
  };
}
ApplicationSettings.propTypes = {
  intl: intlShape.isRequired
};
export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationSettings)
);
