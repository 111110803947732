import React, { Component } from 'react'
import { connect } from 'react-redux'
import { PageHeader, Divider, Tag, Spin, Empty, Col, Row, Tooltip } from 'antd'
import numeral from 'numeral';
import { injectIntl } from 'react-intl';
import moment from 'moment'

import formatScore from '../../helpers/formatScore'
import LineChart from '../charts/LineChart/LineChartComponent'
import DayBarChart from '../charts/DayBarChart/DayBarChartComponent'
import ScatterPlotChart from '../charts/ScatterPlotChart/ScatterPlotComponent';
import actions from '../../actions'
import WindowSizeSwitch from './WindowSizeSwitch'
import ErrorMarginSwitch from './ErrorMarginSwitch'

const calendarOptions = {
  sameDay: '[today]',
  lastDay: '[yesterday]',
  nextDay:  '[on] L',
  nextWeek: '[on] L',
  lastWeek: '[on] L',
  sameElse: '[on] L',
}
class TrailerDetails extends Component {
  constructor() {
    super()
    this.state = {
      lastHistoricalPerformances: { 45: {}, 90: {} },
      selectedHistoricalPerformance: null,
    }
  }

  componentDidUpdate(prevProps) {
    const { error, selectedTrailer, windowSize, maxErrorMargin } = this.props
    const { historicalPerformances } = selectedTrailer || {}
    if (error && historicalPerformances && historicalPerformances.error) {
      this.goBack()
    }
    if (historicalPerformances && historicalPerformances.items && (
      !prevProps.selectedTrailer
      || !prevProps.selectedTrailer.historicalPerformances
      || !prevProps.selectedTrailer.historicalPerformances.items
    )) {
      // historicalPerformances received
      const lastHistoricalPerformances = this.computeLastHistoricalPerformances()
      this.selectHistoricalPerformance(lastHistoricalPerformances[windowSize][maxErrorMargin])
    }
    if (windowSize !== prevProps.windowSize || maxErrorMargin !== prevProps.maxErrorMargin) {
      // select correct last historical performance
      const { lastHistoricalPerformances } = this.state
      this.selectHistoricalPerformance(lastHistoricalPerformances[windowSize][maxErrorMargin])
    }
  }

  selectHistoricalPerformance(selectedHistoricalPerformance) {
    const comparisonList = selectedHistoricalPerformance
      && Object.keys(selectedHistoricalPerformance).filter(k => k !== 'isValid')
    const isNew = selectedHistoricalPerformance
      && JSON.stringify(selectedHistoricalPerformance, comparisonList)
        !== JSON.stringify(this.state.selectedHistoricalPerformance, comparisonList)
    if (selectedHistoricalPerformance && isNew) {
      // fetch braking events
      const { getBrakingEvents, selectedTrailer: { deviceId } } = this.props
      const { startTime, endTime } = selectedHistoricalPerformance
      getBrakingEvents(deviceId, startTime, endTime)
    }
    this.setState({ selectedHistoricalPerformance })
  }

  computeLastHistoricalPerformances() {
    const { selectedTrailer } = this.props
    const { historicalPerformances } = selectedTrailer || {}
    if (historicalPerformances.items) {
      const lastHistoricalPerformances = historicalPerformances.items
        .reduce((acc, elem) => {
          const { windowSize, errorMargin, endTime } = elem
          const limits = [0.03, 0.1, Number.MAX_VALUE]
          limits.forEach((maxErrorMargin) => {
            const previous = acc[windowSize][maxErrorMargin]
            if (errorMargin < maxErrorMargin && (!previous || +previous.endTime < +endTime)) {
              acc[windowSize][maxErrorMargin] = elem
            }
          })
          return acc
        }, {
          45: { 0.03: null, 0.1: null, [Number.MAX_VALUE]: null },
          90: { 0.03: null, 0.1: null, [Number.MAX_VALUE]: null },
        })
      this.setState({ lastHistoricalPerformances })
      return lastHistoricalPerformances
    }
    return { 45: {}, 90: {} }
  }

  goBack() {
    const { deselectTrailer } = this.props
    this.setState({ // reset state
      selectedHistoricalPerformance: null,
      lastHistoricalPerformances: { 45: {}, 90: {} },
    })
    deselectTrailer()
  }

  render = () => {
    const {
      selectedTrailer,
      selectEvent,
      selectedEvent,
      windowSize,
      brakingEvents,
      maxErrorMargin,
    } = this.props
    const { selectedHistoricalPerformance } = this.state;
    const { status, performance, datetime, historicalPerformances = {} } = selectedTrailer || {}
    const isTooOld = moment(datetime) < moment().subtract(1, 'days').startOf('day')
    const hasHistoricalPerformances = (historicalPerformances.items && historicalPerformances.items.length > 0)
    const selectedBrakingEvents = brakingEvents.items && brakingEvents.items.filter(el => el.isSelected)
    const rejectedBrakingEvents = brakingEvents.items && brakingEvents.items.filter(el => !el.isSelected)
    if (brakingEvents.items && selectedHistoricalPerformance && selectedBrakingEvents.length !== selectedHistoricalPerformance.numBrakeEvents) {
      console.log(`expected ${selectedHistoricalPerformance.numBrakeEvents}, got ${selectedBrakingEvents.length}`)
    }
    const filteredHistoric = historicalPerformances.items
      && historicalPerformances.items
        .filter(e => e.windowSize === windowSize)
        .sort((a, b) => +a.endTime - +b.endTime)
    const timeRange = filteredHistoric && [
      moment.utc(filteredHistoric[0].startTime).startOf('day'),
      moment.utc(filteredHistoric[filteredHistoric.length - 1].endTime).endOf('day'),
    ]
    return (
      <div>
        {selectedTrailer && (
          <PageHeader
            onBack={() => this.goBack()}
            title={selectedTrailer.tipNum}
            subTitle={`Customer: ${selectedTrailer.customerFleetNum} (${selectedTrailer.customerName})`}
          />
        )}
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: 16, height: 'calc(100vh - 122px)', overflowY: 'scroll' }}>
          <Row type="flex" justify="start">
            <h3>Trailer Performance</h3>
            {
              status && (
              <Tooltip title={isTooOld && 'Was computed more than 2 days ago'}>
                <div style={{ opacity: isTooOld ? 0.5 : 1 }}>
                  <Tag style={{ marginLeft: 16 }} color={status.color}>{status.name}</Tag>
                  {performance !== null && performance !== undefined && <span>{formatScore(performance)}</span>}
                  {!!datetime && ` (${moment(datetime).calendar(null, calendarOptions)})`}
                  {status.name === 'UNKNOWN' && 'Not enough valid brake events'}
                </div>
              </Tooltip>)
            }
          </Row>
          <Spin spinning={historicalPerformances.processing} style={{ width: '100%' }}>
            {!hasHistoricalPerformances && (
              historicalPerformances.processing
                ? <div style={{ height: 220 + 32, margin: 0 }} />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Historical Data" />
            )}
            {hasHistoricalPerformances && (
              <LineChart
                height={220}
                data={filteredHistoric}
                yLabel="Score [%g]"
                xLabel=" "
                scaleTime
                xRange={timeRange}
                timeWindow={selectedHistoricalPerformance && [selectedHistoricalPerformance.startTime, selectedHistoricalPerformance.endTime]}
                xValueAccessor={d => d.endTime}
                yValueAccessor={d => d.score * 100}
                thresholdLines={[{ y: 45, color: 'tomato' }]}
                onClick={e => this.selectHistoricalPerformance(e)}
                maxErrorMargin={maxErrorMargin}
              />
            )}
            {hasHistoricalPerformances && (
              <Row type="flex" justify="start" gutter={[16, 16]}>
                <Col>
                  <span style={{ paddingRight: 8 }}>Window size</span>
                  <WindowSizeSwitch size="small" />
                </Col>
                <Col>
                  <span style={{ paddingRight: 8 }}>Error margin</span>
                  <ErrorMarginSwitch size="small" />
                </Col>
              </Row>
            )}
          </Spin>
          <h3 style={{ marginTop: 32 }}>Braking Events</h3>
          {!selectedHistoricalPerformance && (
            <div style={{ color: 'silver' }}> no selection </div>
          )}
          {/* {!selectedHistoricalPerformance && (
            <Spin spinning={historicalPerformances.processing} style={{ width: '100%' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Selection" />
            </Spin>
          )} */}
          {selectedHistoricalPerformance && (
            <Spin spinning={brakingEvents.processing} style={{ width: '100%' }}>
              {`from
              ${selectedHistoricalPerformance.startTime.format('L')}
              to ${selectedHistoricalPerformance.endTime.format('L')}
              (performance: ${formatScore(selectedHistoricalPerformance.score)})`}
              {brakingEvents.items ? (
                <Row type="flex" gutter={16} style={{ marginBottom: 16 }}>
                  <Col>
                    {`${brakingEvents.items.length} brake events :`}
                  </Col>
                  <Col>
                    <strong style={{ color: 'dodgerblue' }}>{selectedBrakingEvents.length}</strong>
                    {` selected (${numeral(selectedBrakingEvents.length / brakingEvents.items.length).format('0%')}), `}
                  </Col>
                  <Col>
                    <strong style={{ color: 'grey' }}>{rejectedBrakingEvents.length}</strong>
                    {` rejected (${numeral(rejectedBrakingEvents.length / brakingEvents.items.length).format('0%')}) `}
                  </Col>
                </Row>
              ) : <div style={{ height: 21, marginBottom: 16 }} />}
              <DayBarChart
                height={120}
                dataSelected={selectedBrakingEvents}
                dataRejected={rejectedBrakingEvents}
                xRange={timeRange}
                timeWindow={[selectedHistoricalPerformance.startTime, selectedHistoricalPerformance.endTime]}
              />
              <ScatterPlotChart
                height={260}
                data={brakingEvents.items ? [...rejectedBrakingEvents, ...selectedBrakingEvents] : []} // top put selected ones on top
                idAccessor={d => d.eventId}
                xValueAccessor={d => d.avgPressure}
                yValueAccessor={d => d.avgDeceleration * 100}
                isSelectedAccessor={d => d.isSelected}
                yLabel="Average Deceleration [%g]"
                xLabel="Average Pressure [bar]"
                tooltipInnerHtml={d => (`
                  <div style="max-width: 240px;">
                    <h4>${numeral(d.avgDeceleration * 100).format('0.[00]')} % g  -  ${numeral(d.avgPressure).format('0.0[00]')} bars</h4>
                    <span><strong>Start Speed:</strong> ${numeral(d.startSpeed * 3.6).format('0.[00]')} km/h </span> <br />
                    <span><strong>Estimated Slope:</strong> ${numeral(d.estimatedSlope * 100).format('0.0[000]')} % </span> <br />
                    <span><strong>Distance:</strong> ${numeral(d.distanceGps).format('0.[00]')} m</span> <br />
                    <span><strong>Start Time:</strong> ${d.startDatetime.format('L')} ${d.startDatetime.format('LT')} </span> <br />
                    <span><strong>Duration:</strong> ${numeral(d.duration).format('0.[00]')} seconds </span> <br />
                    ${
                      d.isSelected
                        ? ''
                        : `<span style="white-space: normal;"><strong>REJECTED because</strong> ${d.reasons.join(', ')} </span> <br />`
                    }
                  </div>
                `)}
                thresholdLines={[{ y: 45 }, { x: 6.5 }]}
                dashedLines={[
                  /* eslint-disable object-curly-newline */
                  { x0: 0.35, y0: 0, p: ((45 - 0) / (6.5 - 0.35)), color: 'lightgrey' },
                  ...(selectedHistoricalPerformance.score) ? [
                    { x0: 0.35, y0: 0, p: ((selectedHistoricalPerformance.score * 100 - 0) / (6.5 - 0.35)), color: 'dodgerblue' },
                  ] : [],
                  /* eslint-enable object-curly-newline */
                ]}
                onClick={d => selectEvent(d)}
                isClickedAccessor={d => selectedEvent && d.eventId === selectedEvent.eventId}
              />
            </Spin>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    selectedTrailer: store.trailers.selected,
    brakingEvents: store.brakingEvents,
    selectedEvent: store.brakingEvents.selected.event,
    windowSize: store.settings.items ? store.settings.items.windowSize : 90,
    maxErrorMargin: store.settings.items ? store.settings.items.maxErrorMargin : 0.1,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    deselectTrailer: () => dispatch(actions.trailers.selectTrailer(null)),
    selectEvent: event => dispatch(actions.brakingEvents.selectEvent(event)),
    getBrakingEvents: (id, t1, t2) => dispatch(actions.brakingEvents.getBrakingEvents(id, t1, t2)),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(TrailerDetails))
