import * as d3 from 'd3';
import Chart from '../chart';

import './ScatterPlotChart.css';

export default class ScatterPlot extends Chart {

  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.zoomRect = this.main.append('rect')
      .attr('width', this.props.width)
      .attr('height', this.props.height)
      .style('fill', 'transparent') // just a transparent background to enable zoom everywhere

    this.dashedLines = this.main.append('g')
      .attr('class', 'dashedLines');

    this.thresholdLines = this.main.append('g')
      .attr('class', 'thresholdLines')

    this.points = this.main.append('g')
      .attr('class', 'points')
      .style('pointer-events', 'all')

    this.clipPath = this.points
      .append('defs')
      .append('clipPath')
      .attr('id', 'mask')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.props.width)
      .attr('height', this.props.height)

    this.xAxis = this.main.append('g')
      .attr('class', 'x axis');

    this.yAxis = this.main.append('g')
      .attr('class', 'y axis');

    if (d3.select('.scatterPlotTooltip').empty()) {
      this.tooltip = d3.select('body')
        .append('div')
        .attr('class', 'scatterPlotTooltip')
        .style('display', 'none');
    } else {
      this.tooltip = d3.select('.scatterPlotTooltip')
        .style('display', 'none');
    }
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.data) { // && state.data.length > 0) {
      this._drawChart(state);
    }
  }

  updateClicked(state) {
    if (state.data) {
      const { isClickedAccessor } = state
      const point = this.points.selectAll('.point')
      point.merge(point)
        .classed('clicked', d => isClickedAccessor(d))
    }
  }

  drawAxis(x, y, state, duration) {
    const xAxis = d3.axisBottom(x)
      .tickSizeOuter(0)
      .ticks(6);
    const yAxis = d3.axisLeft(y)
      .tickSizeInner(-this.props.width)
      .tickSize(-this.props.width)
      .tickSizeOuter(0)
      .ticks(5)
    this.xAxis
      .attr('transform', `translate(0,${this.props.height})`)
      .transition()
      .duration(duration)
      .call(xAxis);
    this.yAxis
      .transition()
      .duration(duration)
      .call(yAxis);

    // labels
    const { xLabel, yLabel } = state
    if (yLabel) {
      if (!this.yLabel) {
        this.yLabel = this.yAxis
          .call(yAxis)
          .append('text')
          .attr('font-size', 12)
          .attr('text-anchor', 'end')
          .attr('transform', 'rotate(-90) translate(0,-40)');
      }
      this.yLabel
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('fill', 'rgba(0,0,0,0.65)')
        .text(yLabel);
    }
    if (xLabel) {
      if (!this.xLabel) {
        this.xLabel = this.xAxis
          .call(xAxis)
          .append('text')
          .attr('font-size', 12)
          .attr('text-anchor', 'end')
          .attr('transform', 'translate(60, 45)');
      }
      this.xLabel
        .attr('y', -20)
        .attr('x', this.props.width - 60)
        .attr('dy', '0.71em')
        .attr('fill', 'rgba(0,0,0,0.65)')
        .text(xLabel);
    }
  }

  drawDashedLines(x, y, state) {
    const { dashedLines } = state
    if (dashedLines) {
      const lines = this.dashedLines
        .selectAll('.dashedLines')
        .data(dashedLines)
      lines.exit().remove();
      lines.enter()
        .append('line')
        .attr('class', 'dashedLines')
        .style('stroke', d => d.color)
        .style('stroke-width', 1.5)
        .style('stroke-dasharray', '8,8')
        .attr('x1', x(x.domain()[0]))
        .attr('y1', line => y(line.y0 + (line.p * (x.domain()[0] - line.x0))))
        .attr('x2', x(x.domain()[1]))
        .attr('y2', line => y(line.y0 + (line.p * (x.domain()[1] - line.x0))))
        .attr('clip-path', 'url(#mask)')
    }
  }

  drawThresholdLines(x, y, state) {
    const { thresholdLines } = state
    if (thresholdLines) {
      const tLines = this.thresholdLines
        .selectAll('.thresholdLines')
        .data(thresholdLines.filter(l => l.x || l.y))

      const tLinesEnter = tLines.enter()
        .append('line')
        .attr('class', 'thresholdLines')

      tLines.exit()
        .remove()

      tLinesEnter
        .merge(tLines)
        .attr('d', thresholdLines)
        .style('stroke', d => (d.color || 'tomato'))
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4,4')
        .attr('x1', d => x(d.x || x.domain()[0]))
        .attr('x2', d => x(d.x || x.domain()[1]))
        .attr('y1', d => y(d.y || y.domain()[0]))
        .attr('y2', d => y(d.y || y.domain()[1]))
        .attr('clip-path', 'url(#mask)')
    }
  }

  drawPoints(x, y, size, state, data, duration) {
    const {
      xValueAccessor: xAccessor,
      yValueAccessor: yAccessor,
      //idAccessor,
      tooltipInnerHtml,
      // optional:
      sizeValueAccessor: sizeAccessor,
      isSelectedAccessor,
      onClick,
      isClickedAccessor,
    } = state

    const point = this.points.selectAll('.point')
      .data(data)//, d => d[idAccessor]);

    const self = this;
    point
      .enter()
      .append('circle')
      .attr('class', 'point')
      .attr('cx', d => x(xAccessor(d)))
      .attr('cy', d => y(yAccessor(d)))
      .classed('selected', d => isSelectedAccessor(d))
      .classed('not_selected', d => !isSelectedAccessor(d))
      .classed('clicked', d => isClickedAccessor(d))
      .style('fill', d => (d.color ? d.color : 'dodgerblue'))
      .on('mouseenter', function onMouseEnter(d) {
        const elem = d3.select(this);
        elem
          .style('stroke', '#3a3a48')
          .style('fill-opacity', isSelectedAccessor(d) && 1)
          .style('stroke-width', '2.5px');
        self.tooltip
          .style('display', 'block')
          .style('opacity', 1)
          .style('z-index', 101)
          .html(tooltipInnerHtml(d))
      })
      /* eslint-disable prefer-arrow-callback */
      .on('mousemove', function onMouseMove() {
        let orientation
        let xPos
        let yPos
        let styleLeft
        let styleTop
        let styleRight
        let styleBottom
        if (d3.event.pageY < d3.event.view.innerHeight / 2) {
          if (d3.event.pagexPos < d3.event.view.innerWidth / 2) {
            orientation = 1;
            xPos = d3.event.pageX;
            yPos = d3.event.pageY;
          } else {
            orientation = 2;
            xPos = d3.event.view.innerWidth - d3.event.pageX;
            yPos = d3.event.pageY;
          }
        } else if (d3.event.pageX < d3.event.view.innerWidth / 2) {
          orientation = 3;
          xPos = d3.event.pageX;
          yPos = d3.event.view.innerHeight - d3.event.pageY;
        } else {
          orientation = 4;
          xPos = d3.event.view.innerWidth - d3.event.pageX;
          yPos = d3.event.view.innerHeight - d3.event.pageY;
        }
        switch (orientation) {
          case 1:
            styleTop = yPos + 10;
            styleLeft = xPos + 10;
            break;
          case 2:
            styleTop = yPos + 10;
            styleRight = xPos + 10;
            break;
          case 3:
            styleBottom = yPos + 10;
            styleLeft = xPos + 10;
            break;
          default:
            styleBottom = yPos + 10;
            styleRight = xPos + 10;
            break;
        }
        if (styleLeft) {
          self.tooltip
            .style('left', `${styleLeft}px`)
            .style('right', null)
        } else if (styleRight) {
          self.tooltip
            .style('left', null)
            .style('right', `${styleRight}px`)
        }
        if (styleTop) {
          self.tooltip
            .style('top', `${styleTop}px`)
            .style('bottom', null)
        } else if (styleBottom) {
          self.tooltip
            .style('top', null)
            .style('bottom', `${styleBottom}px`)
        }
      })
      /* eslint-enable prefer-arrow-callback */
      .on('mouseleave', function onMouseLeave(d) {
        d3.select(this)
          .style('stroke', 'white')
          .style('fill-opacity', null)
          .style('stroke-width', 1)
        self.tooltip
          .style('display', 'none')
      })
      .on('click', function c(d) {
        const elem = d3.select(this);
        elem.raise();
        if (d.selectable && onClick) {
          onClick(d);
        }
      })
      .merge(point)
      .classed('selected', d => isSelectedAccessor(d))
      .classed('not_selected', d => !isSelectedAccessor(d))
      .classed('clicked', d => isClickedAccessor(d))
      .transition()
      .duration(duration)
      .attr('r', d => Math.max(0, size(sizeAccessor(d))))
      .attr('cx', d => x(xAccessor(d)))
      .attr('cy', d => y(yAccessor(d)))
      .style('stroke', 'white')
      .style('stroke-width', 1)
      .style('fill', d => (d.color ? d.color : 'dodgerblue'))
      .style('cursor', d => d.selectable ? 'pointer' : '')
      .style('opacity', d => d.selectable ? 1 : 0.2)
      .attr('clip-path', 'url(#mask)')

    point.exit()
      .transition()
      .duration(duration)
      .attr('r', 0)
      // .remove();
  }

  // eslint-disable-next-line no-underscore-dangle
  _drawChart(state) {
    const data = state.data.filter(d => d);
    const {
      xValueAccessor: xAccessor,
      yValueAccessor: yAccessor,
    } = state

    // const duration = 1300;
    const duration = 0;

    // create scales
    const x = d3.scaleLinear().range([0, this.props.width]);
    const y = d3.scaleLinear().range([this.props.height, 0]);
    const size = d3.scalePow().range([1, 20]);

    x.domain([0, 7])
    y.domain([0, 55])
    size.domain([0, 100])

    // axis
    // const zoomedX = d3.zoomTransform(this.svg.node()).rescaleX(x) // applying old zoom
    // const zoomedY = d3.zoomTransform(this.svg.node()).rescaleY(y)
    this.drawAxis(x, y, state, duration)
    this.drawDashedLines(x, y, state)
    this.drawThresholdLines(x, y, state)
    this.drawPoints(x, y, size, state, data, duration)

    // zoom
    // advised point to see the threshold: [6.7, 50]
    this.svg.call(d3.zoom()
      .extent([[0, 0], [this.props.width, this.props.height]])
      .scaleExtent([1, 100])
      .translateExtent([[0, 0], [this.props.width, this.props.height]])
      .on('zoom', () => {
        const newX = d3.event.transform.rescaleX(x)
        const newY = d3.event.transform.rescaleY(y)
        this.xAxis.call(d3.axisBottom(newX)
          .tickSizeOuter(0)
          .ticks(6))
        this.yAxis.call(d3.axisLeft(newY)
          .tickSizeInner(-this.props.width)
          .tickSize(-this.props.width)
          .tickSizeOuter(0)
          .ticks(5))
        this.dashedLines.selectAll('.dashedLines')
          .attr('x1', newX(newX.domain()[0]))
          .attr('y1', line => newY(line.y0 + (line.p * (newX.domain()[0] - line.x0))))
          .attr('x2', newX(newX.domain()[1]))
          .attr('y2', line => newY(line.y0 + (line.p * (newX.domain()[1] - line.x0))))
        this.thresholdLines.selectAll('.thresholdLines')
          .attr('x1', d => newX(d.x || newX.domain()[0]))
          .attr('x2', d => newX(d.x || newX.domain()[1]))
          .attr('y1', d => newY(d.y || newY.domain()[0]))
          .attr('y2', d => newY(d.y || newY.domain()[1]))
        this.points.selectAll('.point')
          .attr('cx', d => newX(xAccessor(d)))
          .attr('cy', d => newY(yAccessor(d)))
      }))
  }
}
