import * as d3 from 'd3';
import Chart from '../chart';

import './TrailerHistoryChart.css'
import {triggerEvent} from "../../../helpers/googleAnalytics";

export default class TrailerHistoryChart extends Chart {
  create(state) {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main');

    this.elevation = this.main.append('g')
      .attr('class', 'elevation')

    this.pin = this.svg.append('g')
      .attr('class', 'pin');

    this.areas = this.main.append('g')
      .attr('class', 'areas')

    this.xAxis = this.main.append('g')
      .attr('class', 'xAxis trailerHistoryAxis')
      .attr('transform', `translate(0,${this.props.height})`)

    this.yLeftAxis = this.main.append('g')
      .attr('class', 'yAxis trailerHistoryAxis trailerHistoryYAxisLeft')
      .append('text')
      .attr('class', 'yLeftAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '0.71em')
      .attr('text-anchor', 'end')
      .attr('fill', 'dodgerblue')
      .attr('font-weight', 'bold')
      .attr('font-size', 12)
      .text(this.props.strings.speed);

    this.yRightAxis = this.main.append('g')
      .attr('class', 'yAxis trailerHistoryAxis trailerHistoryYAxisRight')
      .attr('transform', `translate(${this.props.width}, 0)`)
      .append('text')
      .attr('class', 'yRightAxisLabel')
      .attr('transform', 'rotate(-90)')
      .attr('y', 6)
      .attr('dy', '-0.9em')
      .attr('text-anchor', 'end')
      .attr('fill', 'tomato')
      .attr('font-weight', 'bold')
      .attr('font-size', 12)
      .text(this.props.strings.axleLoad);

    this.assetId = this.props.assetId;
    this.lastTimePos = 0;
    this.drawPin();
    this.update(state, true);
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state, assetId) {
    if (state) {
      this.onPosChange = state.onPosChange;
      this.drawChart(state, assetId);
    }
  }

  drawChart(state, assetId) {
    this.data = (state.data || [])
      .map(d => ({ ...d, time: +d.datetime }))
      .sort((a, b) => a.time - b.time)

    this.x = d3.scaleUtc()
      .range([0, this.props.width])
      .domain(this.data ? d3.extent(this.data, d => d.time) : null)

    const ySpeed = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([0, 120])
      .nice()
    const yLoad = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([0, d3.max(this.data, d => d.pcan)])
      .nice()

    const area = (accessor, yScale) => d3.area()
      .curve(d3.curveStepAfter)
      .x(d => this.x(d.time))
      .y0(yScale(0))
      .y1(d => yScale(d[accessor]))

    const line = (accessor, yScale) => d3.line()
      .curve(d3.curveStepAfter)
      .x(d => this.x(d.time))
      .y(d => yScale(d[accessor]))

    const nXTicks = Math.round(Math.max(this.props.width / 100, 3))
    const xAxis = d3
      .axisBottom(this.x)
      .ticks(nXTicks, d3.utcFormat('%H:%M:%S'));

    const yLeftAxis = d3
      .axisLeft(ySpeed)
      .ticks(5)

    const yRightAxis = d3
      .axisRight(yLoad)
      .ticks(5)

    const dataSeries = [
      {
        color: 'dodgerblue',
        accessor: 'wheel_speed',
        scale: ySpeed,
        data: this.data.filter(d => d.wheel_speed !== null && d.wheel_speed !== undefined),
      },
      {
        color: 'tomato',
        accessor: 'pcan',
        scale: yLoad,
        data: this.data.filter(d => d.pcan !== null && d.pcan !== undefined),
      },
    ];

    const minElevation = d3.min(this.data, d => d.elevation)
    const elevationScale = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([minElevation, minElevation + 5])

    const elevationArea = d3.area()
      .curve(d3.curveMonotoneX)
      .x(d => this.x(d.time))
      .y0(this.props.height)
      .y1(d => elevationScale(d.elevation))

    const elevationAreas = this.elevation
      .selectAll('.path')
      .data([0])

    const elevationAreasEnter = elevationAreas.enter()
      .append('path')
      .attr('class', 'path')

    elevationAreasEnter.merge(elevationAreas)
      .transition()
      .duration(750)
      .attr('fill', '#efefef')
      .attr('d', elevationArea(this.data))

    const areaCurve = this.areas
      .selectAll('.areaCurve')
      .data(dataSeries.slice(0, 1))

    const areaCurveEnter = areaCurve.enter()
      .append('path')
      .attr('class', 'areaCurve')

    areaCurveEnter.merge(areaCurve)
      .transition()
      .duration(750)
      .attr('fill', d => d.color)
      .attr('opacity', 0.2)
      .attr('d', d => area(d.accessor, d.scale)(d.data))

    const lineCurve = this.areas
      .selectAll('.lineCurve')
      .data(dataSeries)

    const lineCurveEnter = lineCurve.enter()
      .append('path')
      .attr('class', 'lineCurve')

    lineCurveEnter.merge(lineCurve)
      .transition()
      .duration(750)
      .attr('fill', 'none')
      .attr('stroke', d => d.color)
      .attr('stroke-width', 2)
      .attr('d', d => line(d.accessor, d.scale)(d.data))

    this.svg.select('.xAxis')
      .call(xAxis)
    this.svg.select('.trailerHistoryYAxisLeft')
      .call(yLeftAxis)
    this.svg.select('.trailerHistoryYAxisRight')
      .call(yRightAxis)

    if (this.onPosChange && Array.isArray(this.data) && this.data.length > 0) {
      if (this.assetId !== assetId) {
        this.lastTimePos = 0;
        this.pin.select('circle')
        .attr('cx', 0);
        this.onPosChange({...this.data[0], time: 0, reset: true});
      } else {
        const currentIndex = this.data.findIndex(d => d.time > this.lastTimePos);
        const posX = Math.max(0, Math.min(this.x(this.lastTimePos), this.props.width));
        this.pin.select('circle')
        .attr('cx', posX);
        if (currentIndex > 0) {
          this.onPosChange({ ...this.data[currentIndex - 1], time: this.lastTimePos });
        }
      }
    }

    this.assetId = assetId;
  }

  drawPin() {
    var self = this;
    this.pin.append('circle')
      .attr('r', 8)
      .attr('fill', 'white')
      .attr('stroke', '#E91E63')
      .attr('stroke-width', 3)
      .attr('cx', 0)
      .attr('cy', this.props.height)
      .call(d3.drag()
        .on('start', function(d) { self.dragStarted(this) })
        .on('drag', function(d) { self.dragged(this, self) })
        .on('end', function(d) { self.dragEnded(this) }))
        .on('mouseover', function(d){ d3.select(this).style('cursor', 'ew-resize') });
  }

  dragStarted(element) {
    d3.select(element)
      .attr('fill', '#E91E63');
  }

  dragged(element, self) {
    const posX = Math.max(0, Math.min(d3.event.x, self.props.width));
    this.pin.select('circle')
      .attr('cx', posX);

    const time = self.x.invert(posX);
    this.lastTimePos = time;
    const currentIndex = self.data.findIndex(d => d.time > time)
    if (self.onPosChange && currentIndex > 0) {
      self.onPosChange({ ...self.data[currentIndex - 1], time });
    } else {
      if (d3.event.x > self.props.width) {
        // return the last position
        self.onPosChange({ ...self.data[self.data.length - 1], time, lastPos: true });
      } else {
        self.onPosChange({ time });
      }
    }
  }

  dragEnded(element) {
    triggerEvent('Map', 'Dragged History Chart pointer', 'Live Map screen');
    d3.select(element)
      .attr('fill', 'white');
  }
}
