import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import trailers from './trailers'
import auth from './auth'
import settings from './settings'
import brakingEvents from './brakingEvents'

export default history => combineReducers({
  router: connectRouter(history),
  trailers,
  auth,
  settings,
  brakingEvents,
})
