import API from '@aws-amplify/api'

import actionTypes from './actionTypes'
import { addErrorMessage } from './messages'

function getBrakingEventsRequest(deviceId) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_REQUEST,
    deviceId,
  }
}

function getBrakingEventsSuccess(events, deviceId, final) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_SUCCESS,
    events,
    deviceId,
    final,
  }
}

function getBrakingEventsFailure(deviceId, error) {
  return {
    type: actionTypes.GET_BRAKE_EVENTS_FAILURE,
    deviceId,
    error,
  }
}

function getBrakingEvents(deviceId, startTime, endTime) {
  return (dispatch) => {
    // TODO if (getState().brakingEvents.deviceId === deviceId), part of the data is already loaded: find a smarter way to fetch it
    dispatch(getBrakingEventsRequest(deviceId))
    const queryStringParameters = {}
    if (startTime) queryStringParameters.startTime = +startTime
    if (endTime) queryStringParameters.endTime = +endTime
    API.get('EBPMSBackend', `brakeEvents/${deviceId}`, { queryStringParameters })
      // TODO check if we really want to filter them out ?
      .then(res => res && Array.isArray(res)
        && res.filter(e => e.avg_pressure !== null && e.avg_deceleration !== null))
      .then((res) => {
        if (!res || !Array.isArray(res)) {
          throw new Error('wrong behaviour from API')
        }
        if (res.length === 0) {
          dispatch(addErrorMessage(`Sorry, trailer ${deviceId} has no brake events.`))
          throw new Error(`${deviceId} has no brake events.`)
        }
        // endTime is not implemented yet !
        const resWithEndtime = res.filter(be => +be.start_time < +endTime)
        return dispatch(getBrakingEventsSuccess(resWithEndtime, deviceId, true))
      })
      .catch(err => dispatch(getBrakingEventsFailure(deviceId, err)))
  }
}

// telemetry
function getTelemetryRequest(event, eventId) {
  return {
    type: actionTypes.GET_TELEMETRY_REQUEST,
    event: event,
    eventId,
  }
}

function getTelemetrySuccess(items, eventId, final) {
  return {
    type: actionTypes.GET_TELEMETRY_SUCCESS,
    items,
    eventId,
    final,
  }
}

function getTelemetryFailure(eventId, error) {
  return {
    type: actionTypes.GET_TELEMETRY_FAILURE,
    eventId,
    error,
  }
}

function selectEvent(event) {
  return (dispatch, getState) => {
    const { brakingEvents: { selected: { event: prevEvent } } } = getState()
    if (event && (!prevEvent || prevEvent.eventId !== event.eventId)) {
      dispatch(getTelemetryRequest(event, event.eventId))
      API.get('EBPMSBackend', `trail/${event.eventId}`)
        .then((res) => {
          if (!res || !Array.isArray(res)) {
            throw new Error('wrong behaviour from API')
          }
          if (res.length === 0) {
            throw new Error(`${event.eventId} has no telemetry data.`)
          }
          return dispatch(getTelemetrySuccess(res, event.eventId, true))
        })
        .catch(err => dispatch(getTelemetryFailure(event.eventId, err)))
    } else {
      dispatch(getTelemetryRequest(null))
    }
  }
}

export default {
  getBrakingEvents,
  selectEvent,
}
