import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Spin } from 'antd'

import ScatterPlot from './ScatterPlotChart';

const LoadingOverlay = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Spin
      size="large"
    />
  </div>
)

class ScatterPlotChart extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null,
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
    window.addEventListener('ebpmsLeftPanelResize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const currentState = this.getChartState(nextProps);
    if (nextProps.data && this.state.chart) {
      if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
        this.createChart()
      }
      if (nextProps.isClickedAccessor !== this.props.isClickedAccessor) {
        this.state.chart.updateClicked(currentState)
      }
    }
  }

  /* eslint-disable class-methods-use-this */
  getChartState(props) {
    return {
      data: props.data,
      idAccessor: props.idAccessor,
      xValueAccessor: props.xValueAccessor,
      xLabel: props.xLabel,
      yValueAccessor: props.yValueAccessor,
      yLabel: props.yLabel,
      sizeValueAccessor: props.sizeValueAccessor || (() => 32),
      dashedLines: props.dashedLines,
      tooltipInnerHtml: props.tooltipInnerHtml,
      isSelectedAccessor: props.isSelectedAccessor,
      thresholdLines: props.thresholdLines,
      onClick: props.onClick,
      isClickedAccessor: props.isClickedAccessor || (() => false),
    };
  }
  /* eslint-enable class-methods-use-this */

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.scatterplotChart);
    if (this.state.chart) {
      this.state.chart.destroy();
    }

    const margin = {
      top: 10,
      right: 30,
      bottom: 50,
      left: 20,
    };

    const height = this.props.height || el.offsetHeight;
    const width = this.props.width || el.offsetWidth;

    // const dim = Math.min(elWidth, height)

    const props = {
      margin,
      width: width - margin.left - margin.right,
      height: height - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new ScatterPlot(el, props),
    }, function() {
      this.state.chart.create();
      this.state.chart.update(this.getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    //TODO understand why this isn't needed
    //this.state.chart.destroy();
    window.removeEventListener('resize', this.createChart);
    window.removeEventListener('ebpmsLeftPanelResize', this.createChart);
  }

  render() {
    const { loading } = this.props
    return (
      <div
        ref="scatterplotChart"
        style={{
          position: 'relative',
          // height: 'calc(100vh - 200px)',
        }}
      >
        {loading &&
          <LoadingOverlay />
        }
      </div>
    );
  }
}

export default ScatterPlotChart
