import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Tabs } from 'antd'
import _ from 'lodash'
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex'
import { injectIntl } from 'react-intl';

import EbpmsTable from './EbpmsTable'
import EbpmsMap from './EbpmsMap'
import TrailerDetails from './TrailerDetails'
import * as strings from '../../helpers/defaultStrings';
import BrakeEventDetailsOverlay from './BrakeEventDetailsOverlay'

import actions from '../../actions'

import './Ebpms.css'
import 'react-reflex/styles.css'
import {tabOrModalView, triggerEvent} from "../../helpers/googleAnalytics";

const { TabPane } = Tabs;

class Ebpms extends Component {
  constructor() {
    super()
    this.state = {
      updateChart: -1,
      statusFilter: [],
      tabNumber: '1',
      historyPosition: null,
    }
  }

  componentDidMount() {
    const { getSettings, getAllTrailers } = this.props;
    getSettings();
    getAllTrailers();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedTrailer } = this.props;
    let tabNumber = '1';
    if (selectedTrailer) {
      tabNumber = '2';
    }
    if (prevState.tabNumber !== tabNumber) {
      this.setState({ tabNumber })
    }
  }

  onStopResize = (e) => {
    const { setWidth } = this.props;
    window.dispatchEvent(new Event('ebpmsLeftPanelResize')) // eslint-disable-line no-undef
    triggerEvent('AppState', 'Resized Ebpms screen', 'Ebpms Map screen', Math.round(e.component.props.flex));
    setWidth(e.component.props.flex)
    this.setState({ updateChart: Math.random() })
  }

  onStatusFilter = (statusFilter) => {
    this.setState({ statusFilter })
  }

  onResetSelection = () => {
    const { deselectTrailer } = this.props
    deselectTrailer()
  }

  onChangeTab = (activeTab) => {
    switch (parseInt(activeTab, 10)) {
      case 1:
        tabOrModalView('list');
        break;
      case 2:
      default:
        tabOrModalView('details');
        break;
    }
  }

  onHistoryPositionChange = (historyPosition) => {
    this.setState({ historyPosition })
  }

  render = () => {
    const { leftWidthSettings, selectedTrailer } = this.props
    const { statusFilter, tabNumber, historyPosition } = this.state
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <ReflexContainer orientation="vertical" style={{ height: 'calc(100vh - 64px)' }}>
          <ReflexElement flex={1 - leftWidthSettings} minSize={150} style={{ position: 'relative' }}>
            <EbpmsMap
              statusFilter={statusFilter}
              historyPosition={historyPosition}
            />
            {selectedTrailer && <BrakeEventDetailsOverlay onHistoryPositionChange={this.onHistoryPositionChange} />}
          </ReflexElement>
          <ReflexSplitter />
          <ReflexElement flex={leftWidthSettings} minSize={500} onStopResize={this.onStopResize}>
            <div style={{ backgroundColor: 'white', height: '100%' }}>
              <Tabs tabBarStyle={{ display: 'none' }} activeKey={tabNumber} style={{ textAlign: 'center', height: '100%' }} onChange={this.onChangeTab}>
                <TabPane tab={formatMessage(strings.short.list)} key="1" style={{ textAlign: 'left' }}>
                  <EbpmsTable
                    onStatusFilter={this.onStatusFilter}
                  />
                </TabPane>
                <TabPane tab={formatMessage(strings.short.details)} key="2" style={{ textAlign: 'left', padding: 0 }}>
                  <TrailerDetails />
                </TabPane>
              </Tabs>
            </div>
          </ReflexElement>
        </ReflexContainer>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    leftWidthSettings: _.get(store.settings, 'items.ebpmsLeftPanelWidth', 0.5),
    selectedTrailer: store.trailers.selected,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getSettings: () => dispatch(actions.settings.getSettings()),
    getAllTrailers: () => dispatch(actions.trailers.getAllTrailers()),
    setWidth: (width) => dispatch(actions.settings.saveSetting('ebpmsLeftPanelWidth', width)),
    deselectTrailer: () => dispatch(actions.trailers.selectTrailer(null)),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Ebpms))
