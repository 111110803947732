  
import produce from 'immer'

import actionTypes from '../actions/actionTypes'

export const initialSettingsState = {
  items: null,
  error: null,
  processing: false,
}

function settingsReducer(state = initialSettingsState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actionTypes.GET_SETTINGS_REQUEST:
        draft.items = null
        draft.error = null
        draft.processing = true
        break
      case actionTypes.GET_SETTINGS_SUCCESS:
        draft.items = action.settings
        draft.processing = false
        break
      case actionTypes.GET_SETTINGS_FAILURE:
        draft.items = null
        draft.processing = false
        draft.error = action.error
        break
      case actionTypes.SAVE_SETTINGS_REQUEST:
        break
      case actionTypes.SAVE_SETTINGS_SUCCESS:
        draft.items = action.settings
        draft.processing = false
        draft.error = null
        break
      case actionTypes.SAVE_SETTINGS_FAILURE:
        draft.items = null
        draft.processing = false
        draft.error = action.error
        break
      default:
        return state
    }
  })
}

export default settingsReducer