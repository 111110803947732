import React from "react";
import { connect } from "react-redux";
import { Radio } from "antd";

import actions from "../../actions";

const WindowSizeSwitch = ({ windowSize, setWindowSize, ...rest }) => (
  <Radio.Group
    {...rest}
    value={windowSize}
    onChange={e => setWindowSize(e.target.value)}
  >
    <Radio.Button value={45}>45 days</Radio.Button>
    <Radio.Button value={90}>90 days</Radio.Button>
  </Radio.Group>
);

function mapStateToProps(store) {
  return {
    windowSize: store.settings.items ? store.settings.items.windowSize : 90,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setWindowSize: value => dispatch(actions.settings.saveSetting('windowSize', value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowSizeSwitch)
