import { injectIntl, intlShape } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';

const LocaleString = ({ type, id, val, caseStyle, intl }) => {
  const { formatMessage } = intl;
  let translatedString = '';
  if (val === null || val === undefined) {
    if (strings[type] && strings[type][id]) {
      translatedString = formatMessage(strings[type][id]);
    } else {
      translatedString = formatMessage(strings.short.unknownVal, {
        value: id
      });
    }
  }
  switch (id) {
    // if we have more complex elements with many values to be entered they will be dealt with individually and val will be an object/array
    default:
      if (strings[type] && strings[type][id]) {
        translatedString = formatMessage(strings[type][id], {
          value: val
        });
      } else {
        translatedString = formatMessage(strings.short.unknownVal, {
          value: id
        });
      }
  }

  if (caseStyle) {
    if (caseStyle.toLowerCase() === 'uc') {
      translatedString = translatedString.toUpperCase();
    } else if (caseStyle.toLowerCase() === 'lc') {
      translatedString = translatedString.toLowerCase();
    } else {
      translatedString = translatedString.charAt(0).toUpperCase() + translatedString.slice(1).toLowerCase();
    }
  }

  return translatedString;
};

LocaleString.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LocaleString)
