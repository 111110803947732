import {Progress} from "antd";
import numeral from "numeral";
import React from "react";
import { injectIntl, intlShape } from 'react-intl';
import * as strings from '../../helpers/defaultStrings';

const pieElementStyle = {
  textAlign: 'center',
}

export const styles = {
  pies: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: 24,
  },
  total: {
    textAlign: 'center',
  },
  totalLabel: {
    textTransform: 'uppercase',
    color: 'lightgray',
    letterSpacing: 1,
    marginBottom: 0,
  },
  totalTrailerCount: {
    fontWeight: 'bold',
    fontSize: 48,
  },
  progress: {
    marginBottom: 12,
  },
  lowerCase: {
    textTransform: 'lowercase'
  }
}

const PieElement = ({ count, totalCount, status, className, selected, intl }) => {
  const percent = Math.round(100 * count / totalCount);
  const {formatMessage} = intl;
  return (
    <div style={{...pieElementStyle, opacity: selected ? 1 : 0.2 }}>
      <div>{status}</div>
      <Progress
        type="circle"
        percent={percent}
        className={className}
        style={styles.progress}
        width={50}
        status="normal"
      />
      <div>{numeral(count).format('0,0')} <small style={styles.lowerCase}>{formatMessage(strings.short.trailers)}</small></div>
    </div>
  )
}

PieElement.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PieElement)
