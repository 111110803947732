import React from 'react';
import { Button, Input, Icon } from 'antd';
import _ from 'lodash';

const styles = {
  dropdown: {
    padding: 8,
    borderRadius: 6,
    background: '#fff',
    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)',
  },
  input: {
    width: 200,
    marginRight: 8,
  },
  resetbtn: {
    marginLeft: 8,
  },
}

// returns the column options handling search for one column; supports strings & numbers
// aka { filterDropdown, filterIcon, onFilter, onFilterDropdownVisibleChange, render }
// EXAMPLE: columns = [{
//   title,
//   dataIndex,
//   ...getColumnSearchProps(dataIndex, { placeholder: `look for ${title}`, confirmText: 'ok' }),
// }]
const getColumnSearchProps = (dataIndex, options = {}) => {
  const {
    placeholder = `Search for ${dataIndex}`,
    confirmText = 'Search',
    resetText = 'Reset',
  } = options
  let searchInputRef = null
  let searchText = null
  const handleSearch = (selectedKeys, confirm) => {
    confirm()
    searchText = selectedKeys[0]  // eslint-disable-line prefer-destructuring
  }
  const handleReset = (clearFilters) => {
    clearFilters();
    searchText = null
  }
  return ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={styles.dropdown}>
        <Input
          style={styles.input}
          ref={(node) => { searchInputRef = node }}
          placeholder={placeholder}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm)}>
          {confirmText}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} style={styles.resetbtn}>
          {resetText}
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => _.get(record, dataIndex)
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.select());
      }
    },
    render: ((value) => {
      if (!searchText) {
        return value
      }
      return (
        <span>
          {value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')).map((text, i) => (
            text.toLowerCase() === searchText.toLowerCase() ?
              <span key={i} className="highlight">{text !== null ? text : 'Unknown'}</span> : text // eslint-disable-line
          ))}
        </span>
      )
    }),
  })
}


export default getColumnSearchProps;
