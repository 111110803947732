import React from 'react'
import { Tag, Tooltip } from 'antd'
import moment from 'moment'

import formatScore from '../../helpers/formatScore';
import GetReportButton from './GetReportButton';
import InfoTooltip from './InfoTooltip';

const calendarOptions = {
  sameDay: '[today]',
  lastDay: '[yesterday]',
  nextDay: 'L',
  nextWeek: 'L',
  lastWeek: 'L',
  sameElse: 'L',
}
const customerColumn = {
  title: 'Customer',
  dataIndex: 'customerName',
  key: 'customerName',
  filterType: 'categorical',
  width: 170,
}

const DateTooltip = ({ record, children, ...props }) => {
  const date = moment(record.datetime)
  const isTooOld = date < moment().subtract(1, 'days').startOf('day')
  return (
    <Tooltip placement="topLeft" title={!!record.datetime && date.calendar(null, calendarOptions)} {...props}>
      <div style={{ opacity: isTooOld ? 0.5 : 1 }}>
        {children}
      </div>
    </Tooltip>
  )
}

const columns = ({ statusFilter, hasAccesToAllCustomers }) => ([
  {
    title: 'TIP Ref',
    dataIndex: 'tipNum',
    key: 'tipNum',
    filterType: 'search',
    width: 170,
  },
  // {
  //   title: 'Device ID',
  //   dataIndex: 'deviceId',
  //   key: 'deviceId',
  //   filterType: 'search',
  //   width: 170,
  // },
  ...(hasAccesToAllCustomers ? [customerColumn] : []),
  {
    title: 'Fleet No.',
    dataIndex: 'customerFleetNum',
    key: 'customerFleetNum',
    filterType: 'search',
    width: 170,
  },
  {
    title: <span>Brake Performance<InfoTooltip subject="performance"/></span>,
    dataIndex: 'performance',
    key: 'performance',
    render: (performance, record) => (
      <DateTooltip record={record}>
        {performance ? formatScore(performance) : <span style={{ color: 'lightgrey' }}>-</span>}
      </DateTooltip>
    ),
    sorter: (a, b) => (a.performance === null || a.performance === undefined ||  +a.performance < +b.performance ? -1 : 1),
    width: 170,
  },
  {
    title: <span>Status<InfoTooltip subject="status"/></span>,
    dataIndex: 'status.name',
    key: 'status',
    render: (name, record) => {
      return (
        <DateTooltip record={record}>
          <Tag color={record.status.color}>{name}</Tag>
        </DateTooltip>
      )
    },
    sorter: (a, b) => (a.status.id === null || a.status.id === undefined || a.status.id < b.status.id ? -1 : 1),
    filters: [
      { text: 'Fail', value: 'fail' },
      { text: 'Pass', value: 'pass' },
      { text: 'Unknown', value: 'unknown' },
    ],
    ...(statusFilter ? { filteredValue: statusFilter } : {}),
    onFilter: (value, record) => {
      return record.status.id === value.toString()
    },
    width: 150,
  },
  {
    title: 'Report',
    key: 'report',
    dataIndex: 'deviceId',
    render: (_, record) => <GetReportButton trailer={record} />,
    width: 170,
  },
  {
    title: 'Last Test',
    dataIndex: 'brakeTestDate',
    key: 'brakeTestDate',
    render: (brakeTestDate, record) => <div>{record.brakeTestDate ? brakeTestDate.format('L') : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
    sorter: (a, b) => (a.brakeTestDate === null || b.brakeTestDate === undefined || a.brakeTestDate.diff(b.brakeTestDate) < 0 ? -1 : 1),
    width: 170,
  },
  // {
  //   title: '# Events',
  //   dataIndex: 'eventCount',
  //   key: 'eventCount',
  //   render: eventCount => <div>{eventCount ? eventCount : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
  //   sorter: (a, b) => (a.eventCount === null || b.eventCount === null || +a.eventCount < +b.eventCount ? -1 : 1),
  //   width: 170,
  // },
  {
    title: 'Ebs Make',
    dataIndex: 'ebsMake',
    key: 'ebsMake',
    render: ebsMake => <div>{ebsMake ? ebsMake : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
    sorter: (a, b) => (a.ebsMake === null || b.ebsMake === null || a.ebsMake < b.ebsMake ? -1 : 1),
    width: 170,
  },
  {
    title: 'Ebs Version',
    dataIndex: 'ebsVersion',
    key: 'ebsVersion',
    render: ebsVersion => <div>{ebsVersion ? ebsVersion : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
    sorter: (a, b) => (a.ebsVersion === null || b.ebsVersion === null || a.ebsVersion < b.ebsVersion ? -1 : 1),
    width: 170,
  },
  {
    title: 'YOM',
    dataIndex: 'yom',
    key: 'yom',
    render: yom => <div>{yom ? yom : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
    sorter: (a, b) => (a.yom === null || b.yom === null || a.yom < b.yom ? -1 : 1),
    width: 170,
  },
  {
    title: 'Chassis No.',
    dataIndex: 'VIN',
    key: 'VIN',
    render: VIN => <div>{VIN ? VIN : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
    sorter: (a, b) => (a.VIN === null || b.VIN === null || a.VIN < b.VIN ? -1 : 1),
    width: 170,
  },
  // {
  //   title: 'Brake Test Result',
  //   dataIndex: 'brakeTestResult',
  //   key: 'brakeTestResult',
  //   render: brakeTestResult => <div>{brakeTestResult ? brakeTestResult : <span style={{ color: 'lightgrey' }}>-</span>}</div>,
  //   width: 170,
  // },
]);

export default columns