import moment from 'moment'

function getStatus(performance) {
  if (performance === null || performance === undefined) {
    return { name: 'UNKNOWN', id: 'unknown', color: 'gray' };
  }
  if (+performance < 0.45) {
    return { name: 'FAIL', id: 'fail', color: 'tomato' };
  }
  return { name: 'PASS', id: 'pass', color: '#87d068' };
}

function getReasonsArray(reasons) {
  const res = []
  // if (!reasons.hasestimatedslope) res.push('missing estimated slope')
  // if (!reasons.hassufficientload) res.push('load not sufficient')
  // if (reasons.selectionwithloadfilter) res.push('event is')
  if (!reasons.haslowlateralacceleration) res.push('high lateral acceleration')
  if (!reasons.haspositivedeceleration) res.push('deceleration is too small')
  if (!reasons.hasregularsampling) res.push('irregular sampling')
  if (!reasons.hassufficientduration) res.push('event duration too short')
  if (!reasons.isonflatroad) res.push('road not flat enough')
  if (!reasons.isusable) res.push('missing some data')
  if (!reasons.validabsstatus) res.push('invalid ABS status')
  if (!reasons.validretarderstatus) res.push('invalid retarder status')
  if (!reasons.validrssstatus) res.push('invalid RSS status')
  return res
}

export function cleanBrakeEvents(events) {
  return events.map((event) => {
    const { // fields used
      event_id: eventId,
      start_time: startTime,
      selection: isSelected,
      start_lat: startLatitude, // for map
      start_lon: startLongitude,
      avg_pressure: avgPressure, // for scratterplot
      avg_deceleration: avgDeceleration,
      start_speed: startSpeed, // for scratterplot tooltip
      estimated_slope: estimatedSlope,
      distance_gps: distanceGps,
      duration,
      // reasons
      // has_estimated_slope: hasestimatedslope,
      // has_sufficient_load: hassufficientload,
      // selection_with_load_filter: selectionwithloadfilter,
      is_usable: isusable,
      has_sufficient_duration: hassufficientduration,
      has_positive_deceleration: haspositivedeceleration,
      has_low_lateral_acceleration: haslowlateralacceleration,
      has_regular_sampling: hasregularsampling,
      is_on_flat_road: isonflatroad,
      valid_abs_status: validabsstatus,
      valid_retarder_status: validretarderstatus,
      valid_rss_status: validrssstatus,
    } = event
    const reasons = getReasonsArray({
      // hasestimatedslope,
      // hassufficientload,
      // selectionwithloadfilter,
      haslowlateralacceleration,
      haspositivedeceleration,
      hasregularsampling,
      hassufficientduration,
      isonflatroad,
      isusable,
      validabsstatus,
      validretarderstatus,
      validrssstatus,
    })
    return { // mapping
      eventId,
      startDatetime: moment.utc(+startTime),
      isSelected,
      selectable: true,
      startLatitude,
      startLongitude,
      avgPressure,
      avgDeceleration,
      startSpeed,
      estimatedSlope,
      distanceGps,
      duration,
      reasons,
    }
  })
}

export function cleanTelemetry(telemetry) {
  return telemetry.map((tel) => {
    const { // fields used
      lat,
      lon,
      pcan,
      wheel_speed: wheelSpeed,
      datetime,
      nasa_elevation: nasaElevation,
    } = tel
    return { // mapping
      lat: lat && +lat,
      lon: lon && +lon,
      wheel_speed: wheelSpeed && +wheelSpeed,
      pcan: pcan && +pcan,
      datetime: datetime && moment.utc(+datetime),
      elevation: nasaElevation && +nasaElevation,
    }
  })
}

export function cleanTrailers(trailers) {
  return trailers.map((trailer) => {
    // used for TrailerColumns:
    // customerName - customerFleetNum - yom - VIN - ebsMake - ebsVersion (from trailerInfo)
    // tipNum - deviceId - performance - status.name - brakeTestDate
    const { // fields used
      trailer_info: trailerInfo,
      device_id: deviceId,
      internal_fleet_id: tipNum,
      performance_45_standard_nomargin,
      performance_45_standard_margin01,
      performance_45_standard_margin003,
      performance_90_standard_nomargin,
      performance_90_standard_margin01,
      performance_90_standard_margin003,
      datetime_45_standard_nomargin,
      datetime_45_standard_margin01,
      datetime_45_standard_margin003,
      datetime_90_standard_nomargin,
      datetime_90_standard_margin01,
      datetime_90_standard_margin003,
    } = trailer
    const { brakeTestDate } = trailerInfo
    return { // mapping
      deviceId,
      tipNum,
      ...trailerInfo,
      nomargin45: {
        performance: performance_45_standard_nomargin,
        status: getStatus(performance_45_standard_nomargin),
        datetime: +datetime_45_standard_nomargin,
      },
      nomargin90: {
        performance: performance_90_standard_nomargin,
        status: getStatus(performance_90_standard_nomargin),
        datetime: +datetime_90_standard_nomargin,
      },
      margin0145: {
        performance: performance_45_standard_margin01,
        status: getStatus(performance_45_standard_margin01),
        datetime: +datetime_45_standard_margin01,
      },
      margin0190: {
        performance: performance_90_standard_margin01,
        status: getStatus(performance_90_standard_margin01),
        datetime: +datetime_90_standard_margin01,
      },
      margin00345: {
        performance: performance_45_standard_margin003,
        status: getStatus(performance_45_standard_margin003),
        datetime: +datetime_45_standard_margin003,
      },
      margin00390: {
        performance: performance_90_standard_margin003,
        status: getStatus(performance_90_standard_margin003),
        datetime: +datetime_90_standard_margin003,
      },
      brakeTestDate: brakeTestDate && moment.utc(brakeTestDate, 'DD/MM/YYYY'),
      brakeTestDateString: brakeTestDate,
    }
  })
}

export function cleanHistoricalPerformances(performances) {
  return performances
    .filter(perf => perf.window_duration === 45 || perf.window_duration === 90)
    .map((perf) => {
      const { // fields used
        start_datetime: startTime,
        end_datetime: endTime,
        performance: score,
        performance_lower_bound: scoreLowerBound,
        num_events: numBrakeEvents,
        window_duration: windowSize,
        error_margin: errorMargin,
      } = perf
      return { // mapping
        startTime: startTime && moment.utc(+startTime),
        endTime: endTime && moment(+endTime),
        score,
        scoreLowerBound,
        numBrakeEvents,
        windowSize,
        errorMargin,
      }
    })
}
