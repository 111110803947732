/* eslint-disable no-underscore-dangle */
import geoViewport from '@mapbox/geo-viewport'
import { WebMercatorViewport } from '@deck.gl/core';
import { FlyToInterpolator } from 'react-map-gl'
import { lineString, point, polygon } from '@turf/helpers'
import * as d3 from 'd3'
import bbox from '@turf/bbox'

const turf = { lineString, bbox, point };

const flyToBounds = (bounds, viewState, mapRef, fitBoundsOptions = { padding: 50 }) => {
  if (!viewState || !mapRef) {
    return viewState;
  }
  const viewStateWithWidthHeight = {
    ...viewState,
    width: mapRef._width,
    height: mapRef._height,
  };
  const { longitude, latitude, zoom } = new WebMercatorViewport(viewStateWithWidthHeight)
    .fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], fitBoundsOptions);

  // if we have a bounds that was generated by a vehicle that has all its lat/lngs on same spot,
  // or within a very high precision close to each other, the zoom we generate can be set to 'Infinity' by the above call.
  const safeZoom = (isFinite(zoom) ? zoom : 13);
  const newViewState = {
    ...viewState,
    longitude,
    latitude,
    zoom: safeZoom,
    transitionDuration: 3000,
    transitionInterpolator: new FlyToInterpolator(),
    transitionEasing: d3.easeSin,
  };

  return newViewState;
};

const flyToPoint = (coordinate, viewState, zoom = 13) => {
  // default zoom of 13 is when we switch to satellite view

  if (coordinate[0] && coordinate[1]) {
    return {
      ...viewState,
      longitude: coordinate[0],
      latitude: coordinate[1],
      zoom,
      transitionDuration: 3000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: d3.easeSin,
    };
  }

  return viewState;
};

const getViewportPoly = (viewState, mapRef) => {
  if (viewState && mapRef) {
    const bounds = geoViewport.bounds([viewState.longitude, viewState.latitude], viewState.zoom, [mapRef._width, mapRef._height], 512);
    const bottomLeft = [bounds[0], bounds[1]];
    const topRight = [bounds[2], bounds[3]];
    const topLeft = [bounds[0], bounds[3]];
    const bottomRight = [bounds[2], bounds[1]];

    return polygon([[
      bottomLeft,
      topLeft,
      topRight,
      bottomRight,
      bottomLeft,
    ]]);
  }

  return null;
}

const flyToBoundedCoordinates = (coordinates, viewState, mapRef, fitBoundsOptions) => {
  if (Array.isArray(coordinates)) {
    const cleanedCoordinates = coordinates
      .filter(c => c[0] && c[1]);
    let line = null;
    try {
      line = (cleanedCoordinates.length >= 2 ? turf.lineString(cleanedCoordinates) : null);
    } catch (e) {
      // Use try catch here in case all the points are the same which could cause an exception when determining the line
      console.warn(`Problem generating line from device points: ${JSON.stringify(e)}`);
      line = null;
    }
    const bounds = (line !== null ? turf.bbox(line) : null);
    if (bounds !== null) {
      return flyToBounds(bounds, viewState, mapRef, fitBoundsOptions);
    }
    if (cleanedCoordinates.length === 1) {
      return flyToPoint(cleanedCoordinates[0], viewState);
    }
  }
  return viewState;
}

const flyToBoundedItems = (items, getLong, getLat, viewState, mapRef, fitBoundsOptions) => {
  if (Array.isArray(items)) {
    const coordinates = items
      .map(i => [getLong(i), getLat(i)])
      .filter(c => c[0] && c[1]);

    return flyToBoundedCoordinates(coordinates, viewState, mapRef, fitBoundsOptions)
  }
  return viewState;
}

const getTooltipPosStyle = (x, y, width, height) => {
  const style = {};
  if (y < height / 2) {
    if (x < width / 2) {
      style.top = y;
      style.left = x;
    } else {
      style.top = y;
      style.right = width - x;
    }
  } else if (x < width / 2) {
    style.bottom = height - y;
    style.left = x;
  } else {
    style.bottom = height - y;
    style.right = width - x;
  }
  return style;
}

export default {
  flyToBounds,
  flyToPoint,
  getViewportPoly,
  flyToBoundedItems,
  flyToBoundedCoordinates,
  getTooltipPosStyle,
}
