import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, intlShape } from 'react-intl';
import * as strings from '../../../helpers/defaultStrings';
import TrailerHistoryChart from './TrailerHistoryChart';

class TrailerHistoryChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.createChart = this.createChart.bind(this);
  }

  getInitialState() {
    return {
      chart: null
    };
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const { chart } = this.state;
    const { dataHash, resizeChart, selectedTrailer } = nextProps;
    if (chart && resizeChart !== this.props.resizeChart) {
      this.createChart()
    }

    if (chart && dataHash && dataHash !== this.props.dataHash) {
      chart.update(nextProps, (selectedTrailer ? selectedTrailer.assetId : null));
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state;
    const { selectedTrailer, axleLoadLabel } = this.props;
    const { formatMessage } = this.props.intl;
    if (chart) {
      chart.destroy()
    }

    const margin = {
      top: 5,
      right: 40,
      bottom: 20,
      left: 40,
    };
    const { height, width } = this.props
    const elWidth = width || el.offsetWidth
    const elHeight = height || 150

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
      strings: {
        axleLoad: axleLoadLabel || formatMessage(strings.short.axleLoad),
        speed: formatMessage(strings.short.speed)
      },
      assetId: (selectedTrailer ? selectedTrailer.assetId : null)
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new TrailerHistoryChart(el, props),
    }, function callback() {
      this.state.chart.create(this.props);
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref='chart'
      />
    );
  }
}
TrailerHistoryChartComponent.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(TrailerHistoryChartComponent)
