import React from "react";
import { connect } from "react-redux";
import { Radio, Tooltip } from "antd";

import actions from "../../actions";

const ErrorMarginSwitch = ({ maxErrorMargin, saveMaxErrorMargin, ...rest }) => (
  <Radio.Group
    {...rest}
    value={maxErrorMargin}
    onChange={e => saveMaxErrorMargin(e.target.value)}
  >
    <Radio.Button value={Number.MAX_VALUE}><Tooltip title="">All</Tooltip></Radio.Button>
    <Radio.Button value={0.1}><Tooltip title="Recommended">{'< 10%'}</Tooltip></Radio.Button>
    <Radio.Button value={0.03}><Tooltip title="DVSA">{'< 3%'}</Tooltip></Radio.Button>
  </Radio.Group>
);

function mapStateToProps(store) {
  return {
    maxErrorMargin: store.settings.items ? store.settings.items.maxErrorMargin : 10,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveMaxErrorMargin: value => dispatch(actions.settings.saveSetting('maxErrorMargin', value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMarginSwitch)
