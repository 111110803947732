import React, {Component} from 'react'
import { Icon, Divider } from 'antd'
import * as strings from '../../helpers/defaultStrings';
import './UserMenu.css'

class UserDropdown extends Component {
  render() {
    const { userName, logOut, formatMessage, settingsClick } = this.props;
    return (
      <div>
        <p
          className="user-menu-display-name"
        >
          {formatMessage(strings.phrase.userGreetingVal, {
            value: userName
          })}
        </p>
        <Divider/>
        <div>
          <div
            role="link"
            className="user-menu-menu-item"
            onClick={settingsClick}
          >
            <Icon
              type="setting"
              style={{marginRight: 8}}
            />
            {formatMessage(strings.button.settings)}
          </div>
          <div
            role="link"
            className="user-menu-menu-item"
            onClick={logOut}
          >
            <Icon
              type="logout"
              style={{marginRight: 8}}
            />
            {formatMessage(strings.button.logOut)}
          </div>
        </div>
      </div>
    )
  }
}

export default UserDropdown
