import * as d3 from 'd3';
import Chart from '../chart';

import './LineChart.css'
import formatScore from '../../../helpers/formatScore';

export default class LineChart extends Chart {
  // First step of the D3 rendering.
  create() {
    this.svg = super.createRoot();
    this.main = this.svg.append('g')
      .attr('class', 'main lineChart')
      .attr('transform', `translate(${this.props.margin.left},${this.props.margin.top})`);

    this.window = this.main.append('g')
      .attr('class', 'window')
    
    this.window
      .append('rect')
      .attr('x', - this.props.width)
      .attr('y', 0)
      .attr('width', this.props.width * 0.2)
      .attr('height', this.props.height)
      .style('fill', '#efefef')
      

    this.areas = this.main.append('g')
      .attr('class', 'areas')

    this.lines = this.main.append('g')
      .attr('class', 'lines')

    this.crosses = this.main.append('g')
      .attr('class', 'crosses')

    this.points = this.main.append('g')
      .attr('class', 'points')

    this.thresholdLines = this.main.append('g')
      .attr('class', 'thresholdLines')

    this.xAxis = this.main.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${this.props.height})`)

    this.yAxis = this.main.append('g')
      .attr('class', 'y axis')

    if (d3.select('.lineChartTooltip').empty()) {
      this.tooltip = d3.select('body')
        .append('div')
        .attr('class', 'lineChartTooltip')
        .style('display', 'none');
    } else {
      this.tooltip = d3.select('.lineChartTooltip')
        .style('display', 'none');
    }
  }

  // Main D3 rendering, that should be redone when the data updates.
  update(state) {
    if (state.data && state.data.length > 0) {
      this._drawChart(state)
    }
  }

  showTooltip(d) {
    if (d) {
      this.tooltip
        .style('display', 'block')
        .html(`${d.endTime.format('L')}<br/>${formatScore(d.score)}`)
    }
  }

  hideTooltip() {
    this.tooltip.style('display', 'none')
  }

  moveTooltip() {
    let orientation
    let xPos
    let yPos
    let styleLeft
    let styleTop
    let styleRight
    let styleBottom
    if (d3.event.pageY < d3.event.view.innerHeight / 2) {
      if (d3.event.pagexPos < d3.event.view.innerWidth / 2) {
        orientation = 1;
        xPos = d3.event.pageX;
        yPos = d3.event.pageY;
      } else {
        orientation = 2;
        xPos = d3.event.view.innerWidth - d3.event.pageX;
        yPos = d3.event.pageY;
      }
    } else if (d3.event.pageX < d3.event.view.innerWidth / 2) {
      orientation = 3;
      xPos = d3.event.pageX;
      yPos = d3.event.view.innerHeight - d3.event.pageY;
    } else {
      orientation = 4;
      xPos = d3.event.view.innerWidth - d3.event.pageX;
      yPos = d3.event.view.innerHeight - d3.event.pageY;
    }
    switch (orientation) {
      case 1:
        styleTop = yPos + 10;
        styleLeft = xPos + 10;
        break;
      case 2:
        styleTop = yPos + 10;
        styleRight = xPos + 10;
        break;
      case 3:
        styleBottom = yPos + 10;
        styleLeft = xPos + 10;
        break;
      default:
        styleBottom = yPos + 10;
        styleRight = xPos + 10;
        break;
    }
    if (styleLeft) {
      this.tooltip
        .style('left', `${styleLeft}px`)
        .style('right', null)
    } else if (styleRight) {
      this.tooltip
        .style('left', null)
        .style('right', `${styleRight}px`)
    }
    if (styleTop) {
      this.tooltip
        .style('top', `${styleTop}px`)
        .style('bottom', null)
    } else if (styleBottom) {
      this.tooltip
        .style('top', null)
        .style('bottom', `${styleBottom}px`)
    }
  }

  // eslint-disable-next-line no-underscore-dangle
  _drawChart(state) {
    const {
      areaColor = 'rgba(30,144,255, 0.1)',
      lineColor = 'dodgerblue',
      data,
      xValueAccessor: xAccessor,
      yValueAccessor: yAccessor,
      yBottomValueAccessor: yBottomAccessor,
      yTopValueAccessor: yTopAccessor,
      xLabel,
      yLabel,
      thresholdLines,
      scaleTime,
      onClick,
      xRange,
      maxErrorMargin,
      timeWindow,
    } = state
    const sortedData = data
      .sort((a, b) => +xAccessor(a) - +xAccessor(b))
      .map(e => ({ ...e, isValid: e.errorMargin < maxErrorMargin }))
    const filteredData = sortedData.filter(e => e.isValid)
    // x, y scale
    const maxYvalue = d3.max(data, d => (yTopAccessor ? yTopAccessor(d) : yAccessor(d))) * 1.1
    const minYvalue = d3.min(data, d => (yTopAccessor ? yTopAccessor(d) : yAccessor(d))) * 0.9

    this.x = scaleTime ? d3.scaleTime() : d3.scaleLinear()
    this.x.range([0, this.props.width])
      .domain(xRange)
    const xAxis = d3.axisBottom(this.x)
      .tickFormat(d3.timeFormat('%b'))
    this.xAxis.transition().duration(750).call(xAxis)

    this.y = d3.scaleLinear()
      .range([this.props.height, 0])
      .domain([Math.min(minYvalue, 35), Math.max(maxYvalue, 55)])
    const yAxis = d3.axisLeft(this.y)
      .tickSizeInner(-this.props.width)
      .tickSize(-this.props.width)
      .tickSizeOuter(0)
      .ticks(3)
    this.yAxis.transition().duration(750).call(yAxis)

    if (yLabel) {
      if (!this.yLabel) {
        this.yLabel = this.yAxis
          .call(yAxis)
          .append('text')
          .attr('font-size', 12)
          .attr('text-anchor', 'end')
          .attr('transform', 'rotate(-90) translate(0,-40)');
      }
      this.yLabel
        .attr('y', 6)
        .attr('dy', '0.71em')
        .attr('fill', 'rgba(0,0,0,0.65)')
        .text(yLabel);
    }
    if (xLabel) {
      if (!this.xLabel) {
        this.xLabel = this.xAxis
          .call(xAxis)
          .append('text')
          .attr('font-size', 12)
          .attr('text-anchor', 'end')
          .attr('transform', 'translate(60, 45)');
      }
      this.xLabel
        .attr('y', -20)
        .attr('x', this.props.width - 60)
        .attr('dy', '0.71em')
        .attr('fill', 'rgba(0,0,0,0.65)')
        .text(xLabel);
    }

    if (thresholdLines) {
      const tLines = this.thresholdLines
        .selectAll('.thresholdLines')
        .data(thresholdLines)

      const tLinesEnter = tLines.enter()
        .append('line')
        .attr('class', 'thresholdLines')

      tLines.exit()
        .remove()

      tLinesEnter
        .merge(tLines)
        .attr('d', thresholdLines)
        .style('stroke', d => d.color)
        .style('stroke-width', 1)
        .style('stroke-dasharray', '4,4')
        .attr('x1', this.x(this.x.domain()[0]))
        .attr('y1', d => this.y(d.y))
        .attr('x2', this.x(this.x.domain()[1]))
        .attr('y2', d => this.y(d.y))
    }

    const line = d3.line()
      .curve(d3.curveMonotoneX)
      .x(d => this.x(xAccessor(d)))
      .y(d => this.y(yAccessor(d)))

    if (yTopAccessor && yBottomAccessor) {
      const area = d3.area()
        .curve(d3.curveMonotoneX)
        .x(d => this.x(xAccessor(d)))
        .y0(d => this.y(yBottomAccessor(d)))
        .y1(d => this.y(yTopAccessor(d)))

      const areas = this.areas
        .selectAll('.path')
        .data([0])

      const areasEnter = areas.enter()
        .append('path')
        .attr('class', 'path')

      areasEnter.merge(areas)
        .transition()
        .duration(750)
        .attr('fill', areaColor)
        .attr('d', area(sortedData))
    }

    const lines = this.lines
      .selectAll('.path')
      .data([0])

    const linesEnter = lines.enter()
      .append('path')
      .attr('class', 'path')

    linesEnter.merge(lines)
      .transition()
      .duration(750)
      .attr('stroke', lineColor)
      .attr('stroke-width', 1.5)
      .attr('fill', 'none')
      .attr('d', line(filteredData))

    // points & crosses
    const points = this.points
      .selectAll('.point')
      .data(sortedData)
    const pointEnter = points.enter()
      .append('circle')
      .attr('class', 'point')
      .attr('r', 3)
      .style('fill', 'dodgerblue')
      .style('stroke', 'white')
      .style('stroke-width', 1)
    pointEnter.merge(points)
      .transition()
      .duration(750)
      .attr('cx', d => this.x(xAccessor(d)))
      .attr('cy', d => this.y(yAccessor(d)))
      .style('cursor', d => (d.isValid ? 'pointer' : 'initial'))
      .style('opacity', d => (d.isValid ? 1 : 0))
    points.exit().remove()

    const crosses = this.crosses
      .selectAll('.cross')
      .data(sortedData)
    const crossesEnter = crosses.enter()
      .append('text')
      .text('x')
      .attr('class', 'cross')
      .style('font-size', '8px')
      .style('fill', 'silver')
    crossesEnter.merge(crosses)
      .transition()
      .duration(750)
      .attr('x', d => this.x(xAccessor(d)) - 2)
      .attr('y', d => this.y(yAccessor(d)) + 2)
      .style('opacity', d => (d.isValid ? 0 : 1))
    crosses.exit().remove()

    // tooltip
    const self = this;
    pointEnter
      .on('mouseenter', function onMouseEnter(d) {
        if (d.isValid) {
          d3.select(this).attr('r', 5);
          self.showTooltip(d)
        }
      })
      .on('mousemove', () => { self.moveTooltip() })
      .on('mouseleave', function onMouseLeave() {
        d3.select(this).attr('r', 3);
        self.hideTooltip()
      })
      .on('click', (d) => {
        if (d.isValid) {
          onClick(d)
        }
      })

    // time window
    if (timeWindow) {
      this.window.selectAll('rect')
        .transition()
        .attr('opacity', 1)
        .attr('width', this.x(timeWindow[1]) - this.x(timeWindow[0]))
        .attr('x', this.x(timeWindow[0]))
    } else {
      this.window.selectAll('rect')
        .transition()
        .attr('opacity', 0)
    }

    this.svg.select('.xAxis')
      .call(xAxis)

    this.svg.select('.yAxis')
      .call(yAxis)
  }
}
