import React from 'react'
import {Button, Modal, Tooltip, Checkbox, Row, Icon, InputNumber} from 'antd';
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
  arrayMove,
} from 'react-sortable-hoc';
import {tabOrModalView, triggerGenericEvent} from "../../helpers/googleAnalytics";
import LocaleString from '../Utils/LocaleString';

const DragHandle = sortableHandle(() => <span><Icon type="ordered-list" /></span>);
let colWidths = {};

const SortableItem = SortableElement(({ value, dynamicWidths }) => {
  if (!dynamicWidths && !colWidths[value.key]) {
    colWidths[value.key] = value.width;
  }

  const setColWidth = (val, key) => {
    colWidths[key] = isNaN(val) ? colWidths[key] : val;
  };

  return (
    <Row type="flex" justify="space-between" style={{ padding: 8 }}>
    <span style={{ width: '15%', textAlign: 'center', marginTop: 5}}>
      <Checkbox value={value.key} />
    </span>
      {!dynamicWidths && <InputNumber style={{ width: '15%' }} defaultValue={colWidths[value.key]} onChange={(val) => setColWidth(val, value.key)}/>}
      <span style={{ width: (dynamicWidths ? '70%' : '55%'), textAlign: 'left', paddingLeft: 25, marginTop: 4 }}>
      {value.shortTitle ? value.shortTitle : value.title}
    </span>
      <span style={{ width: '15%', textAlign: 'center', marginTop: 3 }}>
      <DragHandle />
    </span>
    </Row>
  )
});

const SortableList = SortableContainer(({ items, dynamicWidths }) => {
  return (
    <ul style={{paddingInlineStart: 0}}>
      <Row type="flex" justify="space-between" style={{ padding: 8 }}>
        <span style={{ width: '15%', textAlign: 'center' }}><LocaleString type="short" id="show" /></span>
        {!dynamicWidths && <span style={{ width: '15%', textAlign: 'center' }}><LocaleString type="short" id="width" /></span>}
        <span style={{ width: (dynamicWidths ? '70%' : '55%'), textAlign: 'left', paddingLeft: 25 }}><LocaleString type="short" id="columnName" /></span>
        <span style={{ width: '15%', textAlign: 'center' }}><LocaleString type="short" id="move" /></span>
      </Row>
      {items.map((value, index) => (
        <SortableItem key={value.key} index={index} value={value} dynamicWidths={dynamicWidths} />
      ))}
    </ul>
  );
});

export default class ColumnSelection extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedKeys: [],
      visible: false,
      columns: [],
    };
  }

  componentDidMount() {
    const { selectedColumnKeys, columns } = this.props;
    colWidths = {};
    if (selectedColumnKeys) {
      const sortedColumns = [
        ...(selectedColumnKeys.map(key => columns.find(c => c.key === key)).filter(c => c)),
        ...(columns.filter(c => !selectedColumnKeys.includes(c.key))),
      ];
      this.setState({ selectedKeys: selectedColumnKeys, columns: sortedColumns })
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedColumnKeys, columns } = this.props;
    if (JSON.stringify(selectedColumnKeys) !== JSON.stringify(prevProps.selectedColumnKeys)) {
      colWidths = {};
      const sortedColumns = [
        ...(selectedColumnKeys.map(key => columns.find(c => c.key === key)).filter(c => c)),
        ...(columns.filter(c => !selectedColumnKeys.includes(c.key))),
      ];
      this.setState({ selectedKeys: selectedColumnKeys, columns: sortedColumns })
    }
  }

  showModal = () => {
    tabOrModalView('show-hide-columns');
    this.setState({
      visible: true,
    });
  }

  handleOk = () => {
    const { selectColumns, selectedColumnKeys, saveColumnsWidths } = this.props;
    const { columns, selectedKeys } = this.state;
    tabOrModalView('');
    const orderedColumnKeys = columns
      .map(c => c.key)
      .filter(key => selectedKeys.includes(key));
    // loop through selectedColumnKeys to see if any have been removed
    if (Array.isArray(selectedColumnKeys)) {
      selectedColumnKeys.forEach(column => {
        if (orderedColumnKeys.indexOf(column) === -1) {
          triggerGenericEvent('AppState', 'Removed Column', column);
        }
      });
    }
    // loop through orderedColumnKeys to see if any were added
    orderedColumnKeys.forEach(column => {
      if (selectedColumnKeys.indexOf(column) === -1) {
        triggerGenericEvent('AppState', 'Added Column', column);
      }
    });

    selectColumns(orderedColumnKeys);
    saveColumnsWidths(colWidths);
    colWidths = {};
    this.setState({ visible: false });
  }

  handleCancel = () => {
    const { columns, selectedColumnKeys } = this.props;
    tabOrModalView('');
    const sortedColumns = [
      ...(selectedColumnKeys.map(key => columns.find(c => c.key === key)).filter(c => c)),
      ...(columns.filter(c => !selectedColumnKeys.includes(c.key))),
    ];
    colWidths = {};
    this.setState({ visible: false, columns: sortedColumns, selectedKeys: selectedColumnKeys });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { columns } = this.state;
    this.setState({ columns: arrayMove(columns, oldIndex, newIndex) });
  };

  onSelectionChange = (selectedKeys) => {
    this.setState({ selectedKeys })
  };

  render() {
    const { selectedKeys, visible, columns } = this.state;
    return (
      <div style={{ textAlign: 'center' }}>
        <Tooltip title={<LocaleString type="short" id="columnSettings" />} placement="left">
          <Button shape="circle" size="small" icon="ellipsis" onClick={this.showModal} />
        </Tooltip>
        <Modal
          title={<LocaleString type="short" id="columnSettings" />}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          okText={<LocaleString type="button" id="ok" />}
          cancelText={<LocaleString type="button" id="cancel" />}
        >
          <Checkbox.Group style={{ width: '100%' }} onChange={this.onSelectionChange} value={selectedKeys}>
            <SortableList
              helperClass="sortableHelper"
              items={columns}
              onSortEnd={this.onSortEnd}
              lockAxis="y"
              dynamicWidths={this.props.dynamicWidths}
            />
          </Checkbox.Group>
        </Modal>
      </div>
    );
  }
}
