import React from 'react'
import { Spin, Row, Col, Button } from 'antd'
import { connect } from 'react-redux'
import moment from 'moment'
import { CSVLink } from "react-csv";

import JetpackTable from '../Utils/JetpackTable'
import EbpmsTableSummary from './EbpmsTableSummary'
import './EbpmsTable.css'
import trailerColumns from './TrailerColumns';
import {triggerGenericEvent} from "../../helpers/googleAnalytics";
import actions from '../../actions'
import WindowSizeSwitch from './WindowSizeSwitch'
import ErrorMarginSwitch from './ErrorMarginSwitch'

class EbpmsTable extends React.Component {
  constructor() {
    super();
    this.state = {
      trailersCount: 0,
      passCount: 0,
      infoCount: 0,
      unknownCount: 0,
      failCount: 0,
      startDate: null,
      endDate: null,
      statusFilter: [],
      columnsSelection: ['tipNum', 'customerName', 'customerFleetNum', 'performance', 'status', 'report', 'brakeTestDate', 'eventCount', 'ebsMake', 'ebsVersion', 'yom', 'VIN', 'brakeTestResult', 'trend', 'deviceId'],
    };
  }

  componentDidMount() {
    const { trailers } = this.props;
    this.updateStateFromTrailers(trailers);
    this.setState({})
  }

  componentDidUpdate(prevProps) {
    const { trailers, processing } = this.props;
    if (!processing && trailers && JSON.stringify(trailers) !== JSON.stringify(prevProps.trailers)) {
      this.updateStateFromTrailers(trailers);
    }
  }

  updateStateFromTrailers = (trailers) => {
    if (trailers) {
      /*const allDates = [].concat(...trailers
        .filter(trailer => trailer.events)
        .map(trailer => trailer.events.map(e => e.date)))
      const startDate = _.min(allDates);
      const endDate = _.max(allDates);*/
      const today = moment.utc();
      const startDate = today.clone().subtract(1, 'year').toDate();
      const endDate = today.toDate();
      this.setState({
        trailersCount: trailers.length,
        passCount: trailers.filter(t => t.status.id === 'pass').length,
        unknownCount: trailers.filter(t => t.status.id === 'unknown').length,
        failCount: trailers.filter(t => t.status.id === 'fail').length,
        startDate,
        endDate,
      })
    }
  }

  onTrailerExpand = (trailer) => {
    const { selectTrailer } = this.props;
    selectTrailer(trailer.deviceId);
  }

  onTableChange = (pagination, filters, sorter) => {
    const { statusFilter } = this.state;
    const { onStatusFilter } = this.props
    if (filters.status && JSON.stringify(filters.status) !== JSON.stringify(statusFilter)) {
      this.setState({ statusFilter: filters.status });
      onStatusFilter(filters.status)
    }
  }

  getColumns = () => {
    const { statusFilter, columnsSelection } = this.state;
    const { hasAccesToAllCustomers } = this.props
    return trailerColumns({ statusFilter, hasAccesToAllCustomers })
      .filter(c => columnsSelection.includes(c.key))
  }

  onSelectStatus = (status) => {
    const { onStatusFilter } = this.props
    const { statusFilter } = this.state
    triggerGenericEvent('Filter', 'StatusCharts', status);
    let newStatusFilter;
    if (statusFilter.includes(status)) {
      newStatusFilter = statusFilter.filter(s => s !== status)
    } else {
      newStatusFilter = [...statusFilter, status]
    }
    this.setState({ statusFilter: newStatusFilter })
    onStatusFilter(newStatusFilter)
  }

  onHover = (trailerOnHover) => {
    this.setState({ trailerOnHover })
  }

  render() {
    const { trailers, processing, selectedTrailer, windowSize } = this.props;
    const { trailersCount, passCount, unknownCount, failCount, statusFilter } = this.state;
    const columns = this.getColumns();
    const headers = columns
      .map(({ title, dataIndex }) => ({ label: title, key: dataIndex }))
    const csvReadyData = (trailers || [])
      .map(e => ({ ...e, brakeTestDate: e.brakeTestDate && e.brakeTestDate.format('L')}))
    const pageSize = 100;
    return (
      <div style={{ backgroundColor: 'white', padding: 8, height: '100%' }}>
        <Spin spinning={processing}>
          <EbpmsTableSummary
            trailersCount={trailersCount}
            passCount={passCount}
            unknownCount={unknownCount}
            failCount={failCount}
            statusFilter={statusFilter}
            onSelect={this.onSelectStatus}
            trailers={trailers}
          />
        </Spin>
        <Row type="flex" style={{ marginBottom: 16 }} gutter={16}>
          <Col offset={1}>
            <CSVLink data={csvReadyData} headers={headers} filename={`ebpms_export_${windowSize}_days_window_${(new Date()).toISOString()}.csv`}>
              <Button icon="download">Export CSV</Button>
            </CSVLink>
          </Col>
          <Col>
            <WindowSizeSwitch />
          </Col>
          <Col>
            <ErrorMarginSwitch />
          </Col>
        </Row>
        {
        trailers && (
        <JetpackTable
          id="trailerEbpms"
          //jetpackTitle="Trailer list"
          size="middle"
          loading={processing}
          jetpackColumns={columns}
          pagination={trailers.length > pageSize && {
            pageSize,
            size: 'small'
          }}
          topAndBottomPadding={trailers.length > pageSize ? 370 : 320}
          data={trailers}
          stickyTable={true}
          rowKey="deviceId"
          rowClassName={(record) => {
            return (selectedTrailer && (record.assetId === selectedTrailer.assetId) ? 'trailerRowSelected' : '');
          }}
          onRow={record => ({
            onClick: () => this.onTrailerExpand(record),
            //onMouseEnter: () => this.onHover(record.vinNumber),
            //onMouseLeave: () => this.onHover(null),
          })}
          defaultSelectedColumnKeys={['tipNum', 'customerName', 'customerFleetNum', 'performance', 'status', 'brakeTestDate', 'eventCount']}
          onChange={this.onTableChange}
        />)
        }
      </div>
    )
  }
}

function mapStateToProps(store) {
  return {
    processing: store.trailers.processing,
    error: store.trailers.error,
    trailers: store.trailers.items,
    windowSize: store.settings.items ? store.settings.items.windowSize : 90,
    hasAccesToAllCustomers: !store.auth.user
      || !store.auth.user.attributes // transics has access to all for now
      || !store.auth.user.attributes['custom:userGroup'],
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectTrailer: (deviceId) => dispatch(actions.trailers.selectTrailer(deviceId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EbpmsTable)
