import React from 'react'
import { connect } from 'react-redux'
import {
  Table, Button, Input, Icon,
} from 'antd'
import _ from 'lodash'
import { injectIntl, intlShape } from 'react-intl';

import actions from '../../actions'
import ColumnSelection from './ColumnSelection'
import './JetpackTable.css'
import * as strings from '../../helpers/defaultStrings';
import {triggerGenericEvent} from "../../helpers/googleAnalytics";

class JetpackTable extends React.Component {
  searchInput = {}
  lastFilterTime = 0;

  constructor(props) {
    super();
    this.state = {
      searchText: null,
      filteredInfo: null,
      sortedInfo: null,
      selectedColumnKeys: null,
      columnWidths: {},
      height: window.innerHeight - (!isNaN(props.topAndBottomPadding) ? props.topAndBottomPadding : 175)
    };
  }

  componentDidMount() {
    const { jetpackColumns, selectedColumnKeys, columnWidths } = this.props
    if (jetpackColumns) {
      this.setState({ selectedColumnKeys: selectedColumnKeys || jetpackColumns.map(c => c.key), columnWidths })
    }
    this.setState({ columnWidths });
    window.addEventListener("resize", this.updateHeight);
  }

  componentDidUpdate(prevProps) {
    const { selectedColumnKeys, columnWidths } = this.props;
    if (selectedColumnKeys && JSON.stringify(selectedColumnKeys) !== JSON.stringify(prevProps.selectedColumnKeys)) {
      this.setState({ selectedColumnKeys });
    }

    if (columnWidths && JSON.stringify(columnWidths) !== JSON.stringify(prevProps.columnWidths)) {
      this.setState({ columnWidths });
    }
  }

  handleSearch = (selectedKeys, confirm, field) => {
    confirm();
    triggerGenericEvent('Filter', 'Search', field);
    this.setState({ searchText: { [field]: selectedKeys[0] } });
  }

  handleReset = (clearFilters, field) => {
    clearFilters();
    this.setState({ searchText: { [field]: '' } });
  }

  handleChange = (pagination, filters, sorter) => {
    const { onChange } = this.props;
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
    if (onChange) {
      onChange(pagination, filters, sorter);
    }
  }

  getColumns = (jetpackColumns, data) => {
    let { filteredInfo, searchText, columnWidths } = this.state;
    filteredInfo = filteredInfo || {};
    searchText = searchText || {};
    const { formatMessage } = this.props.intl;

    const columns = jetpackColumns.map((col) => {
      let colSpec = col;
      if (col.filterType === 'search') {
        colSpec = {
          ...colSpec,
          filterDropdown: ({
            setSelectedKeys, selectedKeys, confirm, clearFilters,
          }) => (
            <div className="custom-filter-dropdown">
              <Input
                ref={elem => this.searchInput[col.key] = elem }
                placeholder={formatMessage(strings.short.searchValue)}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => this.handleSearch(selectedKeys, confirm, col.key)}
              />
              <Button type="primary" onClick={() => this.handleSearch(selectedKeys, confirm, col.key)}>{formatMessage(strings.short.search)}</Button>
              <Button onClick={() => this.handleReset(clearFilters, col.key)}>{formatMessage(strings.short.reset)}</Button>
            </div>
          ),
          filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#108ee9' : '#aaa' }} />,
          onFilter: (value, record) => record[col.key] && (record[col.key] + '').toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput[col.key].focus();
              });
            }
          },
        };
        if (searchText[col.key]) {
          colSpec.render = (text) => {
            text += '';
            return (
              <span>
                {text.split(new RegExp(`(${searchText[col.key]})`, 'gi')).map((fragment, i) => (
                  fragment.toLowerCase() === (searchText[col.key] + '').toLowerCase()
                    ? <span key={i} className="highlight">{fragment}</span> : fragment // eslint-disable-line
                ))}
              </span>
            );
          };
        }
      } else if (col.filterType === 'categorical') {
        colSpec = {
          ...colSpec,
          filters: [...(new Set(data.map(d => (d[col.key] || formatMessage(strings.short.unknown)).toString())))]
            .map(d => ({ text: d, value: d }))
            .sort((a, b) => (a.value > b.value ? 1 : -1))
            .filter(d => d),
          filteredValue: filteredInfo[col.key] || null,
          onFilter: (value, record) => {
            if (Date.now() - this.lastFilterTime > 1500) {
              triggerGenericEvent('Filter', 'Filter', col.key);
              this.lastFilterTime = Date.now();
            }
            return _.get(record, col.key, formatMessage(strings.short.unknown)).toString() === value.toString()
          },
        }
      }

      if (this.props.stickyTable) {
        colSpec.width = (columnWidths[colSpec.key] !== undefined && columnWidths[colSpec.key] !== null ? columnWidths[colSpec.key] : colSpec.width);
      }

      return colSpec;
    });

    return columns;
  }

  selectColumns = (selectedColumnKeys) => {
    const { saveColumnsVisibilities } = this.props
    saveColumnsVisibilities(selectedColumnKeys);
  }

  saveColumnsWidths = (columnsWidths) => {
    const { saveColumnsWidths } = this.props;
    saveColumnsWidths(columnsWidths);
  }

  getSelectedColumns = (columns, selectedColumnKeys) => {
    const columnsKeys = columns.map(c => c.key)
    const filterCol = {
      title: (<ColumnSelection
        columns={columns}
        selectedColumnKeys={selectedColumnKeys}
        selectColumns={this.selectColumns}
        saveColumnsWidths={this.saveColumnsWidths}
        dynamicWidths={!this.props.stickyTable}
      />),
      dataIndex: 'options',
      key: 'options',
      width: 60,
    }
    if (selectedColumnKeys) {
      const selectedValidColumnKeys = selectedColumnKeys.filter(c => columnsKeys.includes(c))
      return selectedValidColumnKeys
        .map(key => columns.find(c => c.key === key)).concat([filterCol]);
    }
    return columns.concat([filterCol]);
  }


  updateHeight = () => {
    const {height} = this.state;
    const {topAndBottomPadding} = this.props;

    const newHeight = window.innerHeight - (!isNaN(topAndBottomPadding) ? topAndBottomPadding : 175) ;
    if (height !== newHeight) {
      this.setState({height: newHeight});
    }
  };

  reduceColumnsWidth = (columns) => {
    return (columns || []).reduce((prev, col) => {
      return (
        prev +
        (Array.isArray(col.children) && col.children.length > 0
          ? this.reduceColumnsWidth(col.children)
          : col.width > 0
            ? col.width
            : 0)
      );
    }, 0);
  };

  render() {
    const { data, jetpackColumns, jetpackTitle, stickyTable } = this.props;
    const { selectedColumnKeys, height } = this.state;
    const { formatMessage } = this.props.intl;

    const columns = this.getColumns(jetpackColumns, data);
    const selectedColumns = this.getSelectedColumns(columns, selectedColumnKeys);
    let scroll = {
      x: true
    };
    let style = {};
    if (stickyTable) {
      scroll = { x: this.reduceColumnsWidth(selectedColumns), y: height };
      style = {height: (height + 70)};
    }

    return (
      <div>
        {jetpackTitle && <h3>{jetpackTitle}</h3>}
        <Table
          {...this.props}
          className={stickyTable ? "stickyTable" : "stdTable"}
          columns={selectedColumns}
          dataSource={data}
          onChange={this.handleChange}
          scroll={scroll}
          style={style}
          locale={
            {
              filterTitle: formatMessage(strings.short.filterMenu),
              filterConfirm: formatMessage(strings.short.ok),
              filterReset: formatMessage(strings.short.reset),
              emptyText: formatMessage(strings.short.noData)
            }
          }
        />
      </div>
    );
  }
}

function mapStateToProps(store, props) {
  let selectedColumnKeys = null;
  if (store.settings.items && store.settings.items[`columnSelection${props.id}`]) {
    selectedColumnKeys = store.settings.items[`columnSelection${props.id}`]
  } else if (props.defaultSelectedColumnKeys) {
    selectedColumnKeys = props.defaultSelectedColumnKeys;
  }
  return {
    selectedColumnKeys,
    columnWidths: (store.settings.items && store.settings.items[`columnsWidths${props.id}`] ? store.settings.items[`columnsWidths${props.id}`] : {})
  }
}

function mapDispatchToProps(dispatch, props) {
  return {
    saveColumnsVisibilities: value => dispatch(actions.settings.saveSetting(`columnSelection${props.id}`, value)),
    saveColumnsWidths: value => dispatch(actions.settings.saveSetting(`columnsWidths${props.id}`, value)),
  }
}

JetpackTable.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(JetpackTable))
