import React, { Component } from 'react'
import { connect } from 'react-redux'
import DeckGL from '@deck.gl/react';
import { ScatterplotLayer, LineLayer } from '@deck.gl/layers';
import { TripsLayer } from '@deck.gl/geo-layers';
import { injectIntl } from 'react-intl';
import ReactMapGL from 'react-map-gl'
import { FlyToInterpolator } from 'react-map-gl'

import MapUtils from '../Utils/MapUtils'
import actions from '../../actions'

import './ebpmsmapflickeringfix.css'


function getColorGradientFunc(min, max, c1, c2) {
  return (value) => {
    const a = (value - min) / max
    const res = [
      c1[0] * (1 - a) + c2[0] * a,
      c1[1] * (1 - a) + c2[1] * a,
      c1[2] * (1 - a) + c2[2] * a,
    ]
    if (c1.length === 4 && c2.length === 4) {
      res.push(c1[3] * (1 - a) + c2[3] * a)
    }
    return res
  }
}


const telemetryMapFitBoundsOptions = {
  padding: {
    top: 50,
    bottom: 250,
    left: 50,
    right: 50,
  },
}

const { REACT_APP_MAPBOX_ACCESS_TOKEN, REACT_APP_MAXBOX_STYLE } = process.env

const defaultViewState = {
  longitude: 3.736,
  latitude: 47.136,
  zoom: 3,
  pitch: 0,
  bearing: 0,
  transitionDuration: 3000,
  transitionInterpolator: new FlyToInterpolator(),
}

class EbpmsMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewState: defaultViewState,
      telemetryLines: [],
      filteredBrakingEvents: [],
      maxWheelSpeed: 0,
    }
  }

  componentDidMount() {
    document.addEventListener('contextmenu', e => e.preventDefault());
    const { viewState } = this.state
    const { brakingEvents, done, trailers } = this.props;
    if (brakingEvents && done) {
      this.prepareBrakingEventData(brakingEvents);
      setTimeout(() => this.resetMap(brakingEvents, viewState), 1000)
    }
    if (trailers && trailers.length > 1) {
      const newViewState = trailers
        ? MapUtils.flyToBoundedItems(trailers, t => t.longitude, t => t.latitude, viewState, this.mapRef)
        : defaultViewState;
      this.changeViewState(newViewState)
    }
  }

  componentWillUpdate(nextProps) {
    const { brakingEvents, brakingEventsdeviceId, telemetryItems, selectedEvent, historyPosition, trailers, selectedTrailer } = nextProps;
    const {
      brakingEvents: prevBrakingEvents,
      telemetryItems: prevTelemetryItems,
      selectedEvent: prevSelectedEvent,
      historyPosition: prevHistoryPosition,
      selectedTrailer: prevSelectedTrailer,
      trailers: prevTrailers,
    } = this.props;

    const currentTime = (historyPosition ? historyPosition.time : null);
    const prevCurrentTime = (prevHistoryPosition ? prevHistoryPosition.time : null);

    const { viewState } = this.state
    if ((brakingEvents && !prevBrakingEvents)) {
      this.prepareBrakingEventData(brakingEvents);
      if (brakingEvents && !telemetryItems) {
        const newViewState = MapUtils
          .flyToBoundedItems(brakingEvents, t => t.startLongitude, t => t.startLatitude, viewState, this.mapRef)
        this.changeViewState(newViewState)
      }
    }
    if ((!brakingEvents && prevBrakingEvents)) {
      this.prepareBrakingEventData(null)
    }
    if (telemetryItems && !prevTelemetryItems) {
      this.prepareTelemetryData(telemetryItems);
      if (telemetryItems) {
        const newViewState = MapUtils
          .flyToBoundedItems(telemetryItems, t => t.lon, t => t.lat, viewState, this.mapRef, telemetryMapFitBoundsOptions)
        this.changeViewState(newViewState)
      }
    }
    if (telemetryItems && (currentTime && currentTime !== prevCurrentTime)) {
      this.computeSubTrailLayer(telemetryItems, historyPosition);
    } else if (currentTime !== prevCurrentTime && currentTime === 0) {
      this.setState({ subTrailLayer: null });
    }

    if (trailers && trailers.length > 1 && ((selectedTrailer === null && selectedTrailer !== prevSelectedTrailer) || JSON.stringify(trailers) !== JSON.stringify(prevTrailers))) {
      const newViewState = trailers
        ? MapUtils.flyToBoundedItems(trailers, t => t.longitude, t => t.latitude, viewState, this.mapRef)
        : defaultViewState;
      this.changeViewState(newViewState)
    }

    // viewstate fix when we are on detail page and braking event data is already correct
    if (selectedTrailer && brakingEvents && prevBrakingEvents
      && selectedTrailer.deviceId === brakingEventsdeviceId) {
      if (!prevSelectedTrailer) { // just arrived on detail page
        if (telemetryItems) {
          const newViewState = telemetryItems
            ? MapUtils.flyToBoundedItems(telemetryItems, t => t.lon, t => t.lat, viewState, this.mapRef, telemetryMapFitBoundsOptions)
            : defaultViewState;
          this.changeViewState(newViewState)
        } else {
          const newViewState = brakingEvents
            ? MapUtils.flyToBoundedItems(brakingEvents, t => t.startLongitude, t => t.startLatitude, viewState, this.mapRef)
            : defaultViewState;
          this.changeViewState(newViewState)
        }
      } else if (!selectedEvent && prevSelectedEvent) { // just deselected an event
        const newViewState = brakingEvents
          ? MapUtils.flyToBoundedItems(brakingEvents, t => t.startLongitude, t => t.startLatitude, viewState, this.mapRef)
          : defaultViewState;
        this.changeViewState(newViewState)
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('contextmenu', e => e.preventDefault())
    if (this.gl) {
      const extension = this.gl.getExtension('WEBGL_lose_context')
      if (extension) extension.loseContext()
    }
  }

  setGL(gl, self) {
    self.gl = gl
  }

  prepareBrakingEventData = (brakingEvents) => {
    this.setState({
      filteredBrakingEvents: (brakingEvents || [])
        .filter(e => e.startLongitude && e.startLatitude)
        .filter(e => e.isSelected),
    })
  }

  prepareTelemetryData = (telemetry) => {
    const maxWheelSpeed = Math.max(...(telemetry
      .map(el => el.wheel_speed)))
    const maxPressure = Math.max(...(telemetry
      .map(el => el.pcan)))
    const getColorBlue = getColorGradientFunc(0, maxWheelSpeed,
      [30, 144, 255, 0], [30, 144, 255, 255])
    const getColorOrange = getColorGradientFunc(0, maxPressure,
      [242, 147, 0, 0], [242, 147, 0, 255])
    const sortedTelemetry = (telemetry || [])
      .filter(el => el.lon && el.lat)
      .sort((a, b) => +a.datetime - b.datetime)
      .map(el => ({
        ...el,
        wheelSpeedColor: getColorBlue(el.wheel_speed),
        pressureColor: getColorOrange(el.pcan),
      }))
    const telemetryLines = sortedTelemetry
      .slice(1)
      .map((to, i) => ({ from: sortedTelemetry[i], to }))
    this.setState({ telemetryLines })
    return telemetryLines;
  }

  onEventSelection = ({ object: event }) => {
    const { selectEvent } = this.props
    selectEvent(event)
  }

  addEndPointToSubTrail = (route) => {
    return (Array.isArray(route) && route.length > 0 ? [...route, {
      startSpeed: route[route.length - 1].endSpeed,
      startAxleLoad: route[route.length - 1].endAxleLoad,
      startTime: route[route.length - 1].endTime,
      from: route[route.length - 1].to,
    }] : null);
  }

  computeSubTrailLayer = (route, historyPosition) => {
    if (route && historyPosition && route.length > 0) {
      let subTrailLayer = null;
      const latestPoint = historyPosition.datetime;
      const routeWithEndPos = route.filter(d => d.datetime < latestPoint)//this.addEndPointToSubTrail(route.filter(d => d.time < latestPoint));

      if (Array.isArray(routeWithEndPos) && routeWithEndPos.length > 0) {
        const startTime = Math.round(Math.min(...routeWithEndPos.map(t => t.datetime)) / 1000);
        const currentTimeInSec = Math.round(historyPosition.datetime / 1000);
        const duration = currentTimeInSec - startTime;
        const timeStep = Math.max(1, duration / 1000);

        subTrailLayer = new TripsLayer({
          id: 'sub-trips-layer',
          data: [routeWithEndPos],
          getPath: (r) => {
            return r.map(d => [d.lon, d.lat])
          },
          getTimestamps: (r) => {
            return r.map(d => Math.round(((d.datetime / 1000) - startTime) / timeStep))
          },
          getColor: d => [233, 30, 99],
          opacity: 0.9,
          widthMinPixels: 6,
          rounded: true,
          parameters: {
            depthTest: false, // allow layers array position to determine which layer is above which (akin to Z-Index)
          },
          trailLength: Math.round((currentTimeInSec - startTime) / timeStep),
          currentTime: Math.round((currentTimeInSec - startTime) / timeStep),
        });
      }

      this.setState({ subTrailLayer });
    } else {
      this.setState({ subTrailLayer: null });
    }
  }

  changeViewState(viewState) {
    this.setState({ viewState })
  }

  render() {
    const {
      viewState,
      telemetryLines,
      filteredBrakingEvents,
      subTrailLayer,
    } = this.state;

    const { telemetryItems, selectedTrailer, trailers } = this.props

    const layers = [
      new LineLayer({
        id: 'trail',
        visible: !!telemetryItems && !!selectedTrailer,
        data: telemetryLines,
        autoHighlight: false,
        rounded: true,
        getWidth: 5,
        getSourcePosition: d => [d.from.lon, d.from.lat],
        getTargetPosition: d => [d.to.lon, d.to.lat],
        parameters: {
          depthTest: false, // allow layers array position to determine which layer is above which (akin to Z-Index)
        },
        getColor: [30, 144, 255],//d.from.wheelSpeedColor,
      }),
      new ScatterplotLayer({
        id: 'braking-event-layer',
        visible: !telemetryItems && !!selectedTrailer,
        data: filteredBrakingEvents,
        pickable: true,
        opacity: 0.6,
        radiusMinPixels: 6,
        radiusMaxPixels: 6,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 1,
        getPosition: d => [d.startLongitude, d.startLatitude],
        getFillColor: [30, 144, 255],
        getLineColor: [255, 255, 255],
        onClick: this.onEventSelection,
        autoHighlight: true,
      }),
      new ScatterplotLayer({
        id: 'trailers-layer',
        visible: !selectedTrailer,
        data: trailers,
        opacity: 1,
        radiusMinPixels: 4,
        radiusMaxPixels: 4,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 2,
        getPosition: d => [d.longitude, d.latitude],
        getFillColor: [254, 206, 0],
        getLineColor: [255, 255, 255],
      }),
      subTrailLayer,
    ]

    return (
      <div
        className="ebpmsmap"
        style={{ position: 'relative', width: '100%', height: '100%' }}
      >
        <DeckGL
          viewState={viewState}
          layers={layers}
          onViewStateChange={({ viewState: vs }) => this.changeViewState(vs)}
          onClick={this.onLayerClick}
          controller
          onWebGLInitialized={gl => this.setGL(gl, this)}
        >
          <ReactMapGL
            reuseMaps
            mapboxApiAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
            preventStyleDiffing
            mapStyle={REACT_APP_MAXBOX_STYLE}
            ref={(map) => { this.mapRef = map }}
          />
        </DeckGL>
      </div>
    )
  }
}

function mapStateToProps(store) {
  return {
    processing: store.brakingEvents.processing,
    error: store.brakingEvents.error,
    brakingEvents: store.brakingEvents.items,
    brakingEventsdeviceId: store.brakingEvents.deviceId,
    telemetryItems: store.brakingEvents.selected.telemetry,
    selectedEvent: store.brakingEvents.selected.event,
    selectedTrailer: store.trailers.selected,
    trailers: store.trailers.items,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    selectEvent: event => dispatch(actions.brakingEvents.selectEvent(event)),
  }
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EbpmsMap))
