import actionTypes from './actionTypes'

function getSettingsRequest() {
  return {
    type: actionTypes.GET_SETTINGS_REQUEST,
  }
}

function getSettingsSuccess(settings) {
  return {
    type: actionTypes.GET_SETTINGS_SUCCESS,
    settings,
  }
}

/*
TODO ADD THIS WHEN PROPER FETCHING OF THE SETTINGS IS DONE
function getSettingsFailure(error) {
  return {
    type: actionTypes.GET_SETTINGS_FAILURE,
    error,
  }
}*/

function saveSettingRequest(settingName, value) {
  return {
    type: actionTypes.SAVE_SETTINGS_REQUEST,
    settingName,
    value,
  }
}

function saveSettingSuccess(settings) {
  return {
    type: actionTypes.SAVE_SETTINGS_SUCCESS,
    settings,
  }
}

/*
TODO ADD THIS WHEN PROPER FETCHING OF THE SETTINGS IS DONE
function saveSettingFailure(error) {
  return {
    type: actionTypes.SAVE_SETTINGS_FAILURE,
    error,
  }
}*/


const defaultSettings = {
  maxErrorMargin: 0.1,
  windowSize: 90,
}

function getSettings() {
  return (dispatch) => {
    dispatch(getSettingsRequest())
    const localSettings = JSON.parse(localStorage.getItem('txEbpms')) || {}
    const settings = { ...defaultSettings, ...localSettings }
    return dispatch(getSettingsSuccess(settings))
  }
}

function saveSetting(settingName, value) {
  return (dispatch) => {
    dispatch(saveSettingRequest(settingName, value))
    const localSettings = JSON.parse(localStorage.getItem('txEbpms')) || {}
    const settings = { ...defaultSettings, ...localSettings }
    settings[settingName] = value
    localStorage.setItem('txEbpms', JSON.stringify(settings))
    return dispatch(saveSettingSuccess(settings))
  }
}

export default {
  getSettings,
  saveSetting,
}