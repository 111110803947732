import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import LineChart from './LineChart';

function getChartState(props) {
  return {
    data: props.data,
    areaColor: props.areaColor,
    lineColor: props.lineColor,
    xValueAccessor: props.xValueAccessor,
    yValueAccessor: props.yValueAccessor,
    yBottomValueAccessor: props.yBottomValueAccessor,
    yTopValueAccessor: props.yTopValueAccessor,
    xLabel: props.xLabel,
    yLabel: props.yLabel,
    thresholdLines: props.thresholdLines,
    scaleTime: props.scaleTime,
    onClick: props.onClick || (() => {}),
    xRange: props.xRange,
    maxErrorMargin: props.maxErrorMargin,
    timeWindow: props.timeWindow,
  };
}

class LineChartComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);

    this.createChart = this.createChart.bind(this);
  }

  getInitialState(props) {
    const state = {
      chart: null,
    }
    if (props.width) {
      state.width = props.width
    }
    return state
  }

  componentDidMount() {
    // First render of the D3 chart.
    this.createChart();
    // Re-render from scratch on each resize.
    window.addEventListener('resize', this.createChart);
    window.addEventListener('ebpmsLeftPanelResize', this.createChart);
  }

  componentWillReceiveProps(nextProps) {
    const currentState = getChartState(nextProps);
    if (nextProps.data && this.state.chart) {
      this.state.chart.update(currentState);
    }
  }

  // Tear down the chart and remove the listener.
  componentWillUnmount() {
    window.removeEventListener('resize', this.createChart);
    window.removeEventListener('ebpmsLeftPanelResize', this.createChart);
  }

  createChart() {
    const el = ReactDOM.findDOMNode(this.refs.chart);
    const { chart } = this.state
    if (chart) {
      chart.destroy()
    }

    const margin = {
      top: 10,
      right: 30,
      bottom: 50,
      left: 20,
    };

    const elWidth = this.props.width || el.offsetWidth;
    const elHeight = this.props.height || el.offsetHeight;

    const props = {
      margin,
      width: elWidth - margin.left - margin.right,
      height: elHeight - margin.top - margin.bottom,
    };

    // Initialise the chart, then render it without transitions.
    this.setState({
      chart: new LineChart(el, props),
    }, function callback() {
      this.state.chart.create();
      this.state.chart.update(getChartState(this.props));
      this.state.chart.preventTransitions();
    });
  }

  render() {
    return (
      <div
        ref='chart'
        position="relative"
      />
    );
  }
}

export default LineChartComponent
