import React from 'react'
import { Button, Icon, Row, Table } from 'antd'
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux'

import actions from '../../actions';
import GetReportButton from '../Ebpms/GetReportButton'
import getReportTitle from '../../helpers/getReportTitle'
import getColumnsSearchProps from '../../helpers/getColumnSearchProps'

const history = [
  {
    trailer: {
      tipNum: 'N/A12',
      customerFleetNum: 'MGL2371',
      deviceId: '359315079338918',
    },
    reportdate: moment(),
    user: 'Karl',
    creationdate: moment(),
    windowSize: 45,
    report: getReportTitle('N/A12', moment(), 45),
  },
  {
    trailer: {
      tipNum: 'N/A7',
      customerFleetNum: 'MGL2902',
      deviceId: '358014096620439',
    },
    reportdate: moment().startOf('month'),
    user: 'Benoit',
    creationdate: moment().startOf('month'),
    windowSize: 90,
    report: getReportTitle('N/A7', moment().startOf('month'), 90),
  },
]
const columns = [
  {
    title: 'Report',
    dataIndex: 'report',
    ...getColumnsSearchProps('report'),
    render: (_, record) => <GetReportButton trailer={record.trailer} date={record.reportdate} windowSize={record.windowSize} />,
  },
  {
    title: 'Report date',
    dataIndex: 'reportdate',
    key: 'reportdate',
    render: m => m.format('L'),
    sorter: (a, b) => a.reportdate - b.reportdate,
    defaultSortOrder: 'descend',
  },
  {
    title: 'TIP num',
    dataIndex: 'trailer.tipNum',
    ...getColumnsSearchProps('trailer.tipNum', { placeholder: 'Search TIP Num' }),
  },
  {
    title: 'Customer Num',
    dataIndex: 'trailer.customerFleetNum',
    ...getColumnsSearchProps('trailer.customerFleetNum', { placeholder: 'Search Customer Num' }),
  },
  {
    title: 'Window Size',
    dataIndex: 'windowSize',
    filters: [{ text: '45 days', value: 45 }, { text: '90 days', value: 90 }],
    onFilter: (value, record) => record.windowSize === value,
    render: value => `${value} days`,
  },
  {
    title: 'Created by',
    dataIndex: 'user',
    ...getColumnsSearchProps('user'),
  },
  {
    title: 'Created on',
    dataIndex: 'creationdate',
    render: m => m.format('L'),
    sorter: (a, b) => a.creationdate - b.creationdate,
  },
];

class ReportHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
    }
  }

  onCreate() {
    const { selectedRows } = this.state
    const { getReport } = this.props
    selectedRows.forEach((row) => {
      const { trailer, reportdate, windowSize } = row
      if (trailer && reportdate) {
        getReport(trailer, { date: reportdate, windowSize })
      }
    })
  }

  onSelectRow(selectedRowKeys, selectedRows) {
    this.setState({ selectedRowKeys, selectedRows })
  }

  render() {
    const { selectedRowKeys } = this.state
    return (
      <div>
        <Row type="flex" align="middle" style={{ margin: 8 }}>
          <Button
            type="link"
            onClick={() => this.onCreate()}
          >
            <Icon type="download" />
            {`Download selection (${selectedRowKeys.length})`}
          </Button>
        </Row>
        <Table
          columns={columns}
          dataSource={history}
          rowKey="report"
          rowSelection={{
            selectedRowKeys,
            onChange: (keys, rows) => this.onSelectRow(keys, rows),
          }}
          pagination={{ hideOnSinglePage: true }}
          size="small"
        />
      </div>
    )
  }
}


function mapDispatchToProps(dispatch) {
  return {
    getReport: (trailer, date) => dispatch(actions.trailers.getReport(trailer, date)),
  };
}

export default injectIntl(connect(null, mapDispatchToProps)(ReportHistory))
