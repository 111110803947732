import React from 'react'
import { Tag, Tooltip, Icon } from 'antd'

const InfoTooltip = ({ subject }) => {
  if (!subject) {
    return null
  }
  let info;
  if (subject === 'status') {
    info = (
      <span>
        Last known status of the trailer.<br/>
        <Tag style={{ lineHeight: '1em', padding: '1px 2px' }} color="#87d068">PASS</Tag>  means 45% or above<br/>
        <Tag style={{ lineHeight: '1em', padding: '1px 2px' }} color="tomato">FAIL</Tag> means below 45%<br/>
      </span>
    )
  } else if (subject === 'performance') {
    info = (
      <span>
        Predicted BPV (Brake Value Performance) based on valid brake events
        within last 90 or 45 days.
        <br />
        Value is
        <span style={{ opacity: 0.6 }}> greyed </span>
        if last known
        value if older than yesterday
      </span>
    );
  }
  return (
    <Tooltip title={info}>
      <Icon type="info-circle" style={{ color: 'grey', marginLeft: 8 }} />
    </Tooltip>
  )
}

export default InfoTooltip;
