import API from '@aws-amplify/api'
import moment from 'moment';
import fileDownload from 'js-file-download';
import getReportTitle from '../helpers/getReportTitle';

import actionTypes from './actionTypes'
import { addErrorMessage } from './messages'


function getAllTrailersRequest() {
  return {
    type: actionTypes.GET_TRAILERS_REQUEST,
  }
}

function getAllTrailersSuccess(trailers, settings, final) {
  return {
    type: actionTypes.GET_TRAILERS_SUCCESS,
    trailers,
    settings,
    final,
  }
}

function getAllTrailersFailure(error) {
  return {
    type: actionTypes.GET_TRAILERS_FAILURE,
    error,
  }
}

function getAllTrailers() {
  return (dispatch, getState) => {
    const { items: trailers, processing } = getState().trailers
    if ((!trailers || trailers.length === 0) && !processing) {
      dispatch(getAllTrailersRequest())
      API.get('EBPMSBackend', 'trailers')
        .then((res) => {
          if (!res || !Array.isArray(res) || res.length === 0) {
            dispatch(addErrorMessage('Unable to fetch data.'))
            throw new Error('No trailer data')
          }
          const { items: settings } = getState().settings
          return dispatch(getAllTrailersSuccess(res, settings, true))
        })
        .catch(err => dispatch(getAllTrailersFailure(err)))
    }
  }
}

function getTrailerDetailRequest(deviceId) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_REQUEST,
    deviceId,
  }
}

function getTrailerDetailSuccess(deviceId, trailer) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_SUCCESS,
    deviceId,
    trailer,
  }
}

function getTrailerDetailFailure(deviceId, error) {
  return {
    type: actionTypes.GET_TRAILER_DETAIL_FAILURE,
    deviceId,
    error,
  }
}

function getTrailerDetail(deviceId) {
  return (dispatch) => {
    dispatch(getTrailerDetailRequest(deviceId))
    API.get('EBPMSBackend', `trailers/${deviceId}`)
      .then((res) => {
        if (!res || !res.device_id || res.device_id !== deviceId) {
          throw new Error('wrong behaviour from API')
        }
        const { performanceHistory } = res
        if (!performanceHistory || !Array.isArray(performanceHistory)
          || performanceHistory.length === 0) {
          // dispatch(addErrorMessage(`Sorry, trailer ${deviceId} has no performance history.`))
          throw new Error(`${deviceId} has no performance history.`)
        }
        return dispatch(getTrailerDetailSuccess(deviceId, res))
      })
      .catch(err => dispatch(getTrailerDetailFailure(deviceId, err)))
  }
}


function selectTrailer(deviceId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SELECT_TRAILER,
      deviceId,
    })
    if (deviceId) {
      dispatch(getTrailerDetail(deviceId))
    }
  }
}

function getReportRequest(filename) {
  return {
    type: actionTypes.GET_REPORT_REQUEST,
    filename,
  }
}

function getReportSuccess(filename) {
  return {
    type: actionTypes.GET_REPORT_SUCCESS,
    filename,
  }
}

function getReportFailure(filename) {
  return {
    type: actionTypes.GET_REPORT_FAILURE,
    filename,
  }
}

function getReport(trailer, options) {
  return (dispatch, getState) => {
    const store = getState()
    const { windowSize: settingsWidowSize, maxErrorMargin } = store.settings.items
    const { deviceId, tipNum } = trailer
    const {
      windowSize = settingsWidowSize,
      date = moment(),
      errorMargin = maxErrorMargin,
    } = options
    const params = {
      queryStringParameters: {
        windowSize,
        errorMargin,
        endTime: +(date.endOf('day')),
      },
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'arraybuffer',
    };
    const filename = getReportTitle(tipNum, date, windowSize)
    dispatch(getReportRequest(filename))
    API.get('EBPMSBackend', `report/${deviceId}`, params)
      .then((res) => {
        fileDownload(res, filename);
        dispatch(getReportSuccess(filename))
      })
      .catch((err) => {
        dispatch(addErrorMessage(`Sorry, could not download ${filename}.`))
        dispatch(getReportFailure(filename, err))
      })
  }
}

export default {
  getAllTrailers,
  selectTrailer,
  getReport,
}
