import React, { Component } from 'react'
import { Avatar, Popover } from 'antd'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'

import paths from '../../paths'
import actions from '../../actions'
import UserDropdown from './UserDropdown';
import './UserMenu.css'

class UserMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  toggleMenuOpen = () => {
    const { open } = this.state
    return this.setState({ open: !open })
  }

  onLogout = () => {
    const { logout } = this.props
    this.setState({ open: false })
    return logout()
  }

  onSettingsClick = () => {
    const { goToSettings } = this.props
    this.setState({ open: false })
    return goToSettings()
  }

  render() {
    const { user, formatMessage } = this.props
    const { open } = this.state
    if (!user) {
      return null
    }
    const name = (user.name && user.name.givenName)
      || user.displayName
      || user.username
    return (
      <Popover
        trigger="click"
        placement="bottomRight"
        content={(
          <UserDropdown
            userName={name}
            close={this.toggleMenuOpen}
            logOut={this.onLogout}
            settingsClick={this.onSettingsClick}
            formatMessage={formatMessage}
          />
        )}
        visible={open}
        onVisibleChange={this.toggleMenuOpen}
      >
        <Avatar
          style={{ marginLeft: 16 }}
          icon="user"
          size="large"
        />
      </Popover>
    )
  }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(actions.auth.logout()),
    goToSettings: () => dispatch(push(paths.settings)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
