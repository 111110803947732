import actionTypes from './actionTypes'

export function addErrorMessage(message) {
  return {
    type: actionTypes.ADD_MESSAGE,
    content: message,
    messageType: 'error',
  }
}

export function addWarningMessage(message) {
  return {
    type: actionTypes.ADD_MESSAGE,
    content: message,
    messageType: 'warning',
  }
}

export function addInfoMessage(message) {
  return {
    type: actionTypes.ADD_MESSAGE,
    content: message,
    messageType: 'info',
  }
}

export function addSuccessMessage(message) {
  return {
    type: actionTypes.ADD_MESSAGE,
    content: message,
    messageType: 'success',
  }
}

export default {
  addErrorMessage,
  addWarningMessage,
  addInfoMessage,
  addSuccessMessage,
}
