import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Row, Icon, Col, Select, Tag, Button, Table } from 'antd';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import actions from '../../actions';
import WindowSizeSwitch from '../Ebpms/WindowSizeSwitch';
import InfoTooltip from '../Ebpms/InfoTooltip';
import formatScore from '../../helpers/formatScore';

class CreateReportForm extends Component {
  constructor() {
    super()
    this.state = {
      date: moment(),
      selectedTrailers: [],
    }
  }

  componentDidMount() {
    const { settings, getSettings } = this.props
    if (!settings) {
      getSettings();
    }
    const { trailers, getAllTrailers } = this.props
    if (!trailers) {
      getAllTrailers();
    }
  }

  onCreate() {
    const { selectedTrailers, date } = this.state
    const { trailers, getReport } = this.props
    selectedTrailers.forEach((id) => {
      const trailer = trailers.find(t => t.deviceId === id)
      if (trailers) {
        getReport(trailer, { date })
      }
    })
  }

  getColumns() {
    const { date } = this.state
    const isTooOld = datetime => !moment(datetime).isSame(date, 'day')
    return [
      {
        title: 'TIP Ref',
        dataIndex: 'tipNum',
        sorter: (a, b) => (a.tipNum > b.tipNum ? 1 : -1),
      },
      {
        title: 'Fleet No.',
        dataIndex: 'customerFleetNum',
        sorter: (a, b) => (a.customerFleetNum > b.customerFleetNum ? 1 : -1),
      },
      {
        dataIndex: 'status',
        title: <span>Status<InfoTooltip subject="status"/></span>,
        render: (status, record) => (
          <div style={{ opacity: isTooOld(record.datetime) ? 0.5 : 1 }}>
            <Tag color={status.color}>
              {status.name}
            </Tag>
          </div>
        ),
      },
      {
        title: <span>Brake Performance<InfoTooltip subject="performance"/></span>,
        dataIndex: 'performance',
        render: (performance, record) => (
          <div style={{ opacity: isTooOld(record.datetime) ? 0.5 : 1 }}>
            {performance && formatScore(performance)}
            {!!record.datetime && isTooOld(record.datetime) && ` (on ${moment(record.datetime).format('L')})`}
          </div>
        ),
      },
    ]
  }

  toggleSelectAll() {
    const { trailers } = this.props
    const { selectedTrailers } = this.state
    const hasSelection = selectedTrailers
      && selectedTrailers.length > 0
    if (hasSelection) {
      this.selectTrailers([])
    } else {
      const allTrailers = trailers && trailers.map(t => t.deviceId)
      this.selectTrailers(allTrailers)
    }
  }

  addTrailer(trailerId) {
    const { selectedTrailers } = this.state
    if (trailerId && !selectedTrailers.includes(trailerId)) {
      this.selectTrailers([...selectedTrailers, trailerId])
    }
  }

  selectDate(date) { this.setState({ date }) }

  selectTrailers(selectedTrailers) { this.setState({ selectedTrailers }) }

  render() {
    const { date, selectedTrailers } = this.state
    const { trailers, processingTrailers, processingReport } = this.props
    const hasSelection = selectedTrailers && selectedTrailers.length > 0
    return (
      <Row type="flex" gutter={[48, 16]} align="top">
        <Col>
          <Select
            placeholder="Search Trailer"
            style={{ minWidth: 278 }}
            onChange={v => this.addTrailer(v)}
            showSearch
            filterOption
            optionFilterProp="children"
            allowClear
          >
            {trailers && trailers.map(trailer => (
              <Select.Option key={trailer.deviceId}>
                {`${trailer.tipNum}  (${trailer.customerFleetNum})`}
              </Select.Option>
            ))}
          </Select>
          <Row type="flex" justify="space-between" align="middle" style={{ marginTop: 16 }}>
            <Col>Or select in the table :</Col>
            <Col>
              <Button
                type="link"
                onClick={() => this.toggleSelectAll()}
              >
                {hasSelection ? `Deselect All (${selectedTrailers.length})` : 'Select All'}
              </Button>
            </Col>
          </Row>
          <Table
            size="small"
            columns={this.getColumns()}
            dataSource={trailers}
            rowKey="deviceId"
            rowSelection={{
              columnTitle: ' ',
              selectedRowKeys: selectedTrailers,
              onChange: s => this.selectTrailers(s),
            }}
            onRow={record => ({ style: { cursor: 'pointer' }, onClick: () => this.addTrailer(record.deviceId) })}
            pagination={{ hideOnSinglePage: true }}
            loading={processingTrailers}
          />
        </Col>
        <Col>
          <Button
            disabled={!hasSelection}
            type="primary"
            onClick={() => this.onCreate()}
          >
            <Icon type="download" />
            Create & Download
          </Button>
          {Object.keys(processingReport)
            .map(filename => <Button type="link" key={filename} loading style={{ display: 'block' }}>{filename}</Button>)}
          <br />
          <br />
          <p>Report date :</p>
          <DatePicker
            value={date}
            onChange={d => this.selectDate(d)}
            allowClear={false}
            disabledDate={d => d && d > moment().endOf('day')}
          />
          <br />
          <br />
          <p>Window Size :</p>
          <WindowSizeSwitch />
        </Col>
      </Row>
    );
  }
}

function mapStateToProps(store) {
  return {
    settings: store.settings.items,
    trailers: store.trailers.items,
    processingTrailers: store.trailers.processing,
    processingReport: store.trailers.processingReport,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSettings: () => dispatch(actions.settings.getSettings()),
    getAllTrailers: () => dispatch(actions.trailers.getAllTrailers()),
    getReport: (trailer, date) => dispatch(actions.trailers.getReport(trailer, date)),
  };
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CreateReportForm))
