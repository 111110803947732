import React from 'react'
import { Tabs } from 'antd'
import { injectIntl } from 'react-intl';

import CreateReportForm from './CreateReportForm'
import ReportHistory from './ReportHistory'

const { TabPane } = Tabs

const Reports = () => (
  <div
    style={{
      backgroundColor: 'white',
      height: 'calc(100vh - 64px)',
      zIndex: 100,
      overflow: 'scroll',
    }}
  >
    <Tabs defaultActiveKey="create" tabPosition="left" tabBarStyle={{ marginTop: 60 }}>
      <TabPane tab="Create New" key="create" style={{ padding: 24 }}>
        <h2 style={{ margin: '40px 0' }}>Create new PDF report</h2>
        <CreateReportForm />
      </TabPane>
      <TabPane tab="History" key="history" style={{ padding: 24 }}>
        <h2 style={{ margin: '40px 0' }}>Download from History</h2>
        <ReportHistory />
      </TabPane>
    </Tabs>
  </div>
)


export default injectIntl(Reports)
