import React, { Component } from 'react'
import { Provider } from 'react-redux'

import { configureAuth, configureAPI } from './configureAmplify'
import './App.css'
import configureStore from './configureStore'
import LocaleContainer from './LocaleContainer'

configureAuth()
configureAPI()

const store = configureStore

class App extends Component {
  constructor() {
    super();
    this.state = {
      ready: false
    };
  }

  async componentDidUpdate(prevProps) {
    const { authState: prevAuthState } = prevProps;
    const { authState } = this.props;
    if (authState === "signedIn" && prevAuthState !== "signedIn") {
      this.setState({ ready: true });
    }
  }

  render() {
    const { authState } = this.props;
    const { ready } = this.state;
    return (
      <Provider store={store}>
        <LocaleContainer authState={authState} ready={ready} />
      </Provider>
    );
  }
}

const AuthenticatedApp = () => (
    <App />
);

export default AuthenticatedApp
