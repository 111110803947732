import trailers from './trailers'
import auth from './auth'
import messages from './messages'
import settings from './settings'
import brakingEvents from './brakingEvents'

export default {
  trailers,
  auth,
  messages,
  settings,
  brakingEvents,
}
